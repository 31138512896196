import { React, useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
    Divider,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Tooltip,
    Switch,
    IconButton,
    Stack
} from "@mui/material"
import {
    Typography,
    Grid,
    Button,
    TextField,
    LinearProgress,
    CircularProgress,
    useTheme,
    useMediaQuery
} from "@material-ui/core"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers"
import dayjs, { Dayjs } from "dayjs"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined"

import useStyles from "./styles/VisitorStyles"
import Alert from "../main/Alert"
import {
    updateReminderList,
    addVisitReminders,
    getRemindersDate,
    setRefreshKey
} from "../../actions/visitorFeedActions"
import {
    getAllCommTypes,
    getAllFunctionTypes
} from "../../actions/adminActions"

/* Component that defines the Reminder interface (dialog popup window). */
function ReminderDialog({ visitObj, visitType, onCloseFxn }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const theme = useTheme()

    const refreshKey = useSelector((state) => state.visitFeed.refreshKey)

    const userFullName = useSelector((state) => state.auth.user.displayName)

    // constants
    const COMMUNICATION_TYPE_NAME = "Visitor Announcement"
    const COMMUNICATION_FUNCTION_NAME = "Visitor Notifications"

    // constants for conditional styling adjustments due to screen size (px)
    const isMediumScreen = useMediaQuery(theme.breakpoints.down("840"))
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("693"))
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down("659"))
    const isEvenSmallerScreen = useMediaQuery(theme.breakpoints.down("618"))
    const is600SmallScreen = useMediaQuery(theme.breakpoints.down("600"))
    const isPhoneLandscape = useMediaQuery(
        "(max-width:844px) and (orientation: landscape)"
    )

    // state repr. for loading data initially
    const [loadingData, setLoadingData] = useState(true)

    // state repr. for disabling toggle switches (sugg. reminders)
    const [isDisabledDay, setIsDisabledDay] = useState(false)
    const [isDisabledWeek, setIsDisabledWeek] = useState(false)

    // state for loading bar (SAVE button)
    const [loading, setLoading] = useState(false)

    // get communication type id
    const allCommTypes = useSelector((state) => state.admin.allCommTypes)
    const vaCommunicationType = allCommTypes.find(
        (type) => type.CommunicationTypeName === COMMUNICATION_TYPE_NAME
    )

    // get function type id
    const allFunctionTypes = useSelector(
        (state) => state.admin.allFunctionTypes
    )
    const vaNotifType = allFunctionTypes.find(
        (type) => type.CommunicationFunctionName === COMMUNICATION_FUNCTION_NAME
    )

    // error alert message states
    const [alertFlag, setAlertFlag] = useState(false)
    const [alertText, setAlertText] = useState("")

    // reminders that were previously added (for edits)
    const currentReminders = useSelector(
        (state) => state.visitFeed.currentReminders
    )

    // state repr. of (dynamic) list of reminder date/times on form
    const [reminderList, setReminderList] = useState(
        currentReminders && currentReminders.custom
            ? currentReminders.custom
            : []
    )

    // state repr. of inputs (text field, switches)
    const [reminderState, setReminderState] = useState(
        currentReminders
            ? {
                  dateTimeInput: null,
                  week: currentReminders.week,
                  day: currentReminders.day
              }
            : {
                  dateTimeInput: null,
                  week: false,
                  day: false
              }
    )

    // list of all reminders from store
    const allReminders = useSelector((state) => state.visitFeed.allReminders)

    /* Function that takes date string (UTC), returns equiv str repr.
        of true date in locale timezone. */
    const formatDates = (dateStr) => {
        const dateObj = new Date(dateStr)
        return dateObj.toLocaleString([], {
            dateStyle: "medium",
            timeStyle: "short"
        })
    }

    /* Handler for all changes in date-time picker fields */
    const onChangeDateInputs = (val) => {
        setReminderState({ ...reminderState, dateTimeInput: val })
    }

    /* Handler on toggle switch change */
    const onChangeSwitch = (event, toggleName) => {
        setReminderState({
            ...reminderState,
            [toggleName]: event.target.checked
        })
    }

    /* Handler to clear datetimepicker input box */
    const clearInput = () => {
        setReminderState({ ...reminderState, dateTimeInput: null })
    }

    /* Handler for alert message */
    const handleAlertDialog = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setAlertFlag(false)
        clearInput()
    }

    /* Handler function for "ADD" button in custom reminder box */
    const handleAddClick = async () => {
        const earliestReminder = new Date() // current date + time (2-hr window limit)
        earliestReminder.setHours(earliestReminder.getHours() + 2)

        const latestReminder = new Date(visitObj?.StartDateTime)

        if (reminderState.dateTimeInput) {
            // date validity
            if (isNaN(reminderState.dateTimeInput.getTime())) {
                setAlertFlag(true)
                setAlertText("Please enter a valid date and time.")
                return
                // check for duplicates
            } else if (
                reminderList.filter(
                    (elem) =>
                        elem.toString() ===
                        reminderState.dateTimeInput.toString()
                ).length > 0
            ) {
                setAlertFlag(true)
                setAlertText("It looks like this reminder time already exists.")
                return
                // reminder time must be after (now + 2 hrs)
            } else if (reminderState.dateTimeInput < earliestReminder) {
                setAlertFlag(true)
                setAlertText(
                    "Please select a date/time after the current date/time."
                )
                return
                // reminder time must be before visit starts
            } else if (reminderState.dateTimeInput > latestReminder) {
                setAlertFlag(true)
                setAlertText(
                    "Please select a date/time prior to the start of the visit."
                )
                return
            } else if (reminderState.dateTimeInput) {
                reminderList.push(reminderState.dateTimeInput)
                setReminderList(reminderList)
                await dispatch(updateReminderList(reminderList))
            }
        }
        clearInput()
    }

    /* Handler function for "x" deletion button for custom reminders. */
    const handleDelete = async (reminderDateTime) => {
        const filteredReminders = reminderList.filter(
            (datetime) => datetime !== reminderDateTime
        )
        setReminderList(filteredReminders)
        await dispatch(updateReminderList(filteredReminders))
    }

    /* Handler function for SAVE button. */
    const handleSaveReminder = async () => {
        setLoading(true)
        const auditObject = {
            action: "Schedule Visitor Announcement Reminder",
            visitName: visitObj.FirmName,
            actionBy: userFullName
        }

        const allIDs = {
            visitId: visitObj?.VisitId,
            commTypeId: vaCommunicationType.CommunicationTypeId,
            functionId: vaNotifType.CommunicationFunctionId
        }

        // combine suggested and custom reminders
        const combinedReminders = [...reminderList]

        let weekReminder, dayReminder
        if (reminderState.week) {
            weekReminder = new Date(visitObj?.StartDateTime)
            weekReminder.setDate(weekReminder.getDate() - 7)
            combinedReminders.push(weekReminder)
        }
        if (reminderState.day) {
            dayReminder = new Date(visitObj?.StartDateTime)
            dayReminder.setDate(dayReminder.getDate() - 1)
            combinedReminders.push(dayReminder)
        }
        // remove any possible duplicates btwn suggested and custom
        const combinedRemindersNoDups = combinedReminders.filter(
            (date, index, self) =>
                index === self.findIndex((d) => d.getTime() === date.getTime())
        )
        //console.log(combinedRemindersNoDups)
        await dispatch(
            addVisitReminders(allIDs, combinedRemindersNoDups, auditObject)
        )
        await dispatch(updateReminderList([]))
        await dispatch(getRemindersDate(visitObj.VisitId))
        await dispatch(setRefreshKey(refreshKey))
        onCloseFxn()
        setLoading(false)
        // console.log(reminderList)
        // console.log(reminderState)
    }

    /* React hook; obtain data to initialize and manipulate to match form */
    useEffect(() => {
        // one-day from visit start
        const dayFromStart = new Date(visitObj?.StartDateTime)
        dayFromStart.setDate(dayFromStart.getDate() - 1)
        if (dayFromStart <= new Date()) {
            setIsDisabledDay(true)
        }

        // one-week from visit start
        const weekFromStart = new Date(visitObj?.StartDateTime)
        weekFromStart.setDate(weekFromStart.getDate() - 7)
        if (weekFromStart <= new Date()) {
            setIsDisabledWeek(true)
        }

        async function runUseEffectFunctions() {
            await dispatch(getAllCommTypes())
            await dispatch(getAllFunctionTypes())
            if (currentReminders && currentReminders.custom?.length > 0) {
                await dispatch(updateReminderList(currentReminders.custom))
            }
            setLoadingData(false)
        }
        runUseEffectFunctions()
    }, [dispatch])

    return (
        <>
            <DialogTitle>
                <Grid
                    container
                    direction="row"
                    align="center"
                    justifyContent="center"
                    className={
                        isPhoneLandscape ? classes.ReminderDialogLandscape : ""
                    }
                >
                    <Typography className={classes.ReminderTitle}>
                        SET VISITOR ANNOUNCEMENT REMINDER
                    </Typography>
                </Grid>
                <Divider className={classes.ReminderDivider} />
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {/* Basic (top-level) visit details */}
                    <Grid
                        container
                        justifyContent="flex-start"
                        align="center"
                        className={classes.ReminderVisitDetails}
                    >
                        <Grid item xs={12} sm={isSmallScreen ? 12 : 6}>
                            {/* Visiting firm name */}
                            <Typography>
                                <b>VISITING FIRM: </b>
                                {"  " + visitObj?.FirmName}
                            </Typography>
                        </Grid>
                        {isSmallScreen && (
                            <Grid item xs={12}>
                                <br />
                            </Grid>
                        )}
                        <Grid item xs={12} sm={isSmallScreen ? 12 : 6}>
                            {/* Visiting start date/time */}
                            <Typography>
                                <b>VISIT START:</b>
                                {" " + formatDates(visitObj?.StartDateTime)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                        </Grid>
                        <Grid item xs={12} sm={isSmallScreen ? 12 : 6}>
                            {/* Visiting type */}
                            <Typography>
                                <b>VISIT TYPE:</b>
                                {" " + visitType}
                            </Typography>
                        </Grid>
                        {isSmallScreen && (
                            <Grid item xs={12}>
                                <br />
                            </Grid>
                        )}
                        <Grid item xs={12} sm={isSmallScreen ? 12 : 6}>
                            {/* Visiting end date/time */}
                            <Typography>
                                <b>VISIT END:</b>
                                {" " + formatDates(visitObj?.EndDateTime)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                        </Grid>
                    </Grid>
                    {is600SmallScreen && (
                        <Grid
                            item
                            xs={8}
                            sm={4}
                            md={4}
                            container
                            justifyContent="center"
                            className={
                                is600SmallScreen
                                    ? classes.ReminderToggleBox600Small
                                    : classes.ReminderToggleBox
                            }
                        >
                            {/* Suggested reminders form area */}
                            <Grid item xs={12}>
                                <label className={classes.ReminderLabel}>
                                    {isEvenSmallerScreen
                                        ? "SUGG. REMINDERS"
                                        : "SUGGESTED REMINDERS"}
                                </label>
                                {!isSmallScreen ||
                                    (is600SmallScreen && (
                                        <Tooltip
                                            placement="top"
                                            title="Suggested times are with respect to the start of the visit."
                                        >
                                            <InfoOutlinedIcon
                                                fontSize="small"
                                                color="warning"
                                                className={
                                                    classes.ReminderInfoSugg
                                                }
                                            />
                                        </Tooltip>
                                    ))}
                            </Grid>
                            <Grid container align="center">
                                {/* 1-week prior toggle switch */}
                                <Grid
                                    item
                                    xs={7}
                                    sm={7}
                                    md={7}
                                    className={
                                        isMediumScreen
                                            ? isSmallerScreen
                                                ? classes.ReminderSwitchLabelSmall
                                                : classes.ReminderSwitchLabelMed
                                            : classes.ReminderSwitchLabel
                                    }
                                >
                                    1 WEEK PRIOR
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    className={classes.ReminderSwitch}
                                >
                                    <Switch
                                        size="medium"
                                        color="warning"
                                        checked={reminderState.week}
                                        disabled={isDisabledWeek}
                                        onChange={(event) =>
                                            onChangeSwitch(event, "week")
                                        }
                                    />
                                </Grid>
                                {/* 1-day prior toggle switch */}
                                <Grid
                                    item
                                    xs={7}
                                    sm={7}
                                    md={7}
                                    className={
                                        isMediumScreen
                                            ? isSmallerScreen
                                                ? classes.ReminderSwitchLabelSmall
                                                : classes.ReminderSwitchLabelMed
                                            : classes.ReminderSwitchLabel
                                    }
                                >
                                    1 DAY PRIOR
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    className={classes.ReminderSwitch}
                                >
                                    <Switch
                                        size="medium"
                                        color="warning"
                                        checked={reminderState.day}
                                        disabled={isDisabledDay}
                                        onChange={(event) =>
                                            onChangeSwitch(event, "day")
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid
                            item
                            xs={12}
                            sm={7}
                            md={7}
                            container
                            className={classes.ReminderBox}
                        >
                            {/* Custom reminders form area */}
                            <Grid item xs={12}>
                                <label className={classes.ReminderLabel}>
                                    CUSTOM REMINDERS
                                </label>
                                <Tooltip
                                    placement="right"
                                    title="Custom reminders must be set and saved at least 2 hours in advance of the reminder time."
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        color="warning"
                                        className={classes.ReminderInfoCustom}
                                    />
                                </Tooltip>
                            </Grid>
                            {/* Case on number of custom reminder date/time entries, to display */}
                            <Grid item container>
                                {loadingData ? (
                                    <Grid item xs={12} align="center">
                                        <CircularProgress />
                                    </Grid>
                                ) : allReminders.length !== 0 ? (
                                    allReminders?.map((entry, index) => {
                                        return (
                                            <Grid item container key={index}>
                                                <Grid
                                                    item
                                                    xs={9}
                                                    sm={10}
                                                    md={10}
                                                    align="center"
                                                    className={
                                                        classes.ReminderEntry
                                                    }
                                                >
                                                    {formatDates(entry)}
                                                </Grid>
                                                <Grid item xs={2} sm={1} md={1}>
                                                    <IconButton
                                                        onClick={(e) =>
                                                            handleDelete(entry)
                                                        }
                                                    >
                                                        <HighlightOffIcon fontSize="small" />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                ) : (
                                    <Grid item xs={12}>
                                        <Typography
                                            align="center"
                                            className={classes.NoReminders}
                                        >
                                            No custom reminders added.
                                        </Typography>
                                    </Grid>
                                )}

                                {/* Alerts pop-up area */}
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    alignItems="center"
                                    justify="flex-end"
                                >
                                    <Grid item>
                                        {alertFlag && (
                                            <Alert
                                                onClose={handleAlertDialog}
                                                severity="error"
                                            >
                                                {alertText}
                                            </Alert>
                                        )}
                                    </Grid>
                                </Grid>

                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <Grid
                                        item
                                        xs={8}
                                        sm={10}
                                        md={10}
                                        className={classes.ReminderDatePicker}
                                    >
                                        {/* Custom reminder date/time picker input component */}
                                        <DateTimePicker
                                            value={dayjs(
                                                reminderState.dateTimeInput
                                            )}
                                            label="REMINDER TIME"
                                            onChange={(val) =>
                                                onChangeDateInputs(val?.$d)
                                            }
                                            minDate={dayjs(new Date())}
                                            maxDate={dayjs(
                                                new Date(visitObj?.EndDateTime)
                                            )}
                                            slotProps={{
                                                textField: {
                                                    variant: "standard",
                                                    error: false
                                                }
                                            }}
                                            className={
                                                classes.ReminderDateInput
                                            }
                                        />
                                    </Grid>
                                </LocalizationProvider>
                                <Grid
                                    item
                                    xs={3}
                                    sm={1}
                                    md={1}
                                    align="center"
                                    className={classes.ReminderAddButton}
                                >
                                    {/* "ADD" button for custom reminder form area */}
                                    {isMediumScreen ? (
                                        <IconButton
                                            color="warning"
                                            onClick={handleAddClick}
                                        >
                                            <AddBoxOutlinedIcon fontSize="medium" />
                                        </IconButton>
                                    ) : (
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            onClick={handleAddClick}
                                        >
                                            ADD
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        {!is600SmallScreen && (
                            <Grid
                                item
                                xs={12}
                                sm={4}
                                md={4}
                                container
                                className={classes.ReminderToggleBox}
                            >
                                {/* Suggested reminders form area */}
                                <Grid item xs={12}>
                                    <label className={classes.ReminderLabel}>
                                        {isEvenSmallerScreen
                                            ? "SUGG. REMINDERS"
                                            : "SUGGESTED REMINDERS"}
                                    </label>
                                    {!isSmallScreen && (
                                        <Tooltip
                                            placement="top"
                                            title="Suggested times are with respect to the start of the visit."
                                        >
                                            <InfoOutlinedIcon
                                                fontSize="small"
                                                color="warning"
                                                className={
                                                    classes.ReminderInfoSugg
                                                }
                                            />
                                        </Tooltip>
                                    )}
                                </Grid>
                                <Grid container align="center">
                                    {/* 1-week prior toggle switch */}
                                    <Grid
                                        item
                                        sm={7}
                                        md={7}
                                        className={
                                            isMediumScreen
                                                ? isSmallerScreen
                                                    ? classes.ReminderSwitchLabelSmall
                                                    : classes.ReminderSwitchLabelMed
                                                : classes.ReminderSwitchLabel
                                        }
                                    >
                                        1 WEEK PRIOR
                                    </Grid>
                                    <Grid
                                        item
                                        sm={4}
                                        md={4}
                                        className={classes.ReminderSwitch}
                                    >
                                        <Switch
                                            size="medium"
                                            color="warning"
                                            checked={reminderState.week}
                                            disabled={isDisabledWeek}
                                            onChange={(event) =>
                                                onChangeSwitch(event, "week")
                                            }
                                        />
                                    </Grid>
                                    {/* 1-day prior toggle switch */}
                                    <Grid
                                        item
                                        sm={7}
                                        md={7}
                                        className={
                                            isMediumScreen
                                                ? isSmallerScreen
                                                    ? classes.ReminderSwitchLabelSmall
                                                    : classes.ReminderSwitchLabelMed
                                                : classes.ReminderSwitchLabel
                                        }
                                    >
                                        1 DAY PRIOR
                                    </Grid>
                                    <Grid
                                        item
                                        sm={4}
                                        md={4}
                                        className={classes.ReminderSwitch}
                                    >
                                        <Switch
                                            size="medium"
                                            color="warning"
                                            checked={reminderState.day}
                                            disabled={isDisabledDay}
                                            onChange={(event) =>
                                                onChangeSwitch(event, "day")
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <br />
                        </Grid>
                    </Grid>

                    {/* SAVE button to save reminder data on form to database */}
                    <Grid container align="center" justifyContent="center">
                        <Button
                            variant="outlined"
                            size="large"
                            color="secondary"
                            onClick={handleSaveReminder}
                            className={classes.SaveReminderButton}
                        >
                            <Stack>
                                <Typography>SAVE</Typography>
                                {loading && <LinearProgress />}
                            </Stack>
                        </Button>

                        {/* Tooltip giving important info about effects in pressing SAVE button */}
                        <Tooltip
                            placement="top"
                            title="Please be aware company-wide emails will be sent at the set reminder times."
                        >
                            <InfoOutlinedIcon
                                fontSize="small"
                                className={classes.ReminderInfo}
                            />
                        </Tooltip>
                    </Grid>
                </DialogContentText>
            </DialogContent>
        </>
    )
}

export default ReminderDialog
