import { React, useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import {
    Grid,
    TextField,
    Typography,
    Button,
    LinearProgress,
    useTheme,
    useMediaQuery
} from "@material-ui/core"
import dayjs, { Dayjs } from "dayjs"
import { Divider, Autocomplete, Stack, Tooltip, Dialog } from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers"
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { useSelector, useDispatch } from "react-redux"
import { v4 as uuidv4 } from "uuid"

import LoginPrompt from "../login/LoginPrompt"
import resetHeaders from "../../functions/resetHeaders"
import useStyles from "./styles/VisitorStyles"
import VisitorBox from "./VisitorBox"
import FunctionsBox from "./FunctionsBox"
import HostBox from "./HostBox"
import ConferenceRoomBox from "./ConferenceRoomBox"
import ConfirmationDialog from "./ConfirmationDialog"
import Alert from "../main/Alert"
import { getAllRecipients } from "../../actions/adminActions"
import {
    addVisitInfo,
    sendVisitorEmail,
    sendFunctionEmail,
    updateHostList,
    updateVisitorList,
    updateConfRoomList
} from "../../actions/visitorFormActions"
import {
    getAllStoredVisits,
    setRefreshKey,
    setVisitStart,
    setVisitEnd
} from "../../actions/visitorFeedActions"

/* Component that defines the (CREATE or EDIT) visitor announcement form. 
            [Campus Tour === Factory Tour] */
function VisitorForm({ mode }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const theme = useTheme()

    // refresh key to force reload/re-renders
    const refreshKey = useSelector((state) => state.visitFeed.refreshKey)

    // constants for conditional styling adjustments due to screen size (px)
    const isMediumScreen = useMediaQuery(theme.breakpoints.down("1001"))
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("900"))
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down("800"))
    const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("680"))
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("601"))
    const isSmallPhone = useMediaQuery(theme.breakpoints.down("400"))
    const isPhoneLandscape = useMediaQuery(
        "(max-width:844px) and (orientation: landscape)"
    )

    // boolean state repr. for loading bar (SAVE button)
    const [loading, setLoading] = useState(false)

    // boolean state repr. for confirmation dialog
    const [open, setOpen] = useState(false)

    // authentication store variable
    const loggedIn = useSelector(
        (state) => state.auth.user && state.auth.user.auth
    )

    const authenticated = useSelector((state) => state.auth.authenticated)
    const userFullName = useSelector((state) => state.auth.user.displayName)

    // get contents from store for form (boxes) data; data currently in form
    const allVisitors = useSelector((state) => state.visitor.allVisitors)
    const allHosts = useSelector((state) => state.visitor.allHosts)
    const allConfRooms = useSelector((state) => state.visitor.allConfRooms)

    // get store contents (from db) data prior to editing (EDIT mode)
    const currentVisitorList = useSelector(
        (state) => state.visitFeed.currentVisitVisitors
    )
    const currentHostList = useSelector(
        (state) => state.visitFeed.currentVisitHosts
    )
    const currentConfRoomList = useSelector(
        (state) => state.visitFeed.currentVisitConfRooms
    )

    // get content from store (database) for all firm types
    const dbFirmTypes = useSelector((state) => state.visitor.firmTypes)

    // reformat firm types for Autocomplete dropdown array
    const allFirmTypes = dbFirmTypes.map((type) => {
        if (type.FirmType === "Customer") {
            return { id: type.FirmTypeId, type: type.FirmType + "/Prospect" }
        } else if (type.FirmType === "Vendor") {
            return { id: type.FirmTypeId, type: type.FirmType + "/Supplier" }
        } else {
            return { id: type.FirmTypeId, type: type.FirmType }
        }
    })
    allFirmTypes.splice(0, 0, { id: "", type: "" })

    /* Function that returns name of firm type for its given ID */
    const getFirmType = (id) => {
        const firmTypeObject = allFirmTypes.find((obj) => obj.id === id)
        return firmTypeObject ? firmTypeObject.type : ""
    }

    // obtain visit user has currently selected (stored visitId from Redux store)
    const selectedVisitId = useSelector(
        (state) => state.visitFeed.selectedVisitId
    )
    // obtain visit object for currently selected visit (stored Visit record from Redux store)
    const currVisitList = useSelector(
        (state) => state.visitFeed.currentVisitObj
    )
    // temporary object to hold form data (from database) when in EDIT mode
    const formatCurrVisit = {
        start: null,
        end: null,
        firmname: "",
        typeId: -1,
        factoryTour: false,
        breakfast: false,
        lunch: false,
        welcomeBoard: false
    }
    if (mode === "edit" && selectedVisitId !== "") {
        if (currVisitList.length > 0) {
            const currVisitInfo = currVisitList[0]
            formatCurrVisit.start = new Date(currVisitInfo.StartDateTime)
            formatCurrVisit.end = new Date(currVisitInfo.EndDateTime)
            formatCurrVisit.firmname = currVisitInfo.FirmName
            formatCurrVisit.typeId = currVisitInfo.FirmTypeId
            formatCurrVisit.factoryTour = currVisitInfo.FactoryTour
            formatCurrVisit.breakfast = currVisitInfo.Breakfast
            formatCurrVisit.lunch = currVisitInfo.Lunch
            formatCurrVisit.welcomeBoard = currVisitInfo.WelcomeBoard
        }
    }

    // state object for form inputs (based on CREATE or EDIT mode)
    const [visitInfoObj, setVisitInfoObj] = useState(
        mode === "create"
            ? {
                  visitType: { type: "", id: "" },
                  visitFirm: "",
                  fromDateTime: null,
                  toDateTime: null,
                  functionStatus: {
                      factory: false,
                      breakfast: false,
                      lunch: false,
                      welcome: false
                  }
              }
            : //   EDIT mode
              {
                  visitType: {
                      type: getFirmType(formatCurrVisit.typeId),
                      id: formatCurrVisit.typeId
                  },
                  visitFirm: formatCurrVisit.firmname,
                  fromDateTime: formatCurrVisit.start,
                  toDateTime: formatCurrVisit.end,
                  functionStatus: {
                      factory: formatCurrVisit.factoryTour,
                      breakfast: formatCurrVisit.breakfast,
                      lunch: formatCurrVisit.lunch,
                      welcome: formatCurrVisit.welcomeBoard
                  }
              }
    )

    // error alert message
    const [alertFlag, setAlertFlag] = useState(false)
    const [alertText, setAlertText] = useState("")

    // get all email recipients from store (database)
    const allEmailRecipients = useSelector((state) => state.admin.allRecipients)

    // obtain only "Visitor Notifications" email recipients
    const visitorNotificationsEmailList = allEmailRecipients
        .filter(
            (elem) => elem.CommunicationFunctionName === "Visitor Notifications"
        )
        .map((elem) => {
            return elem.RecipientEmail
        })

    // obtain only "Welcome Board" email recipients
    const welcomeBoardEmailList = allEmailRecipients
        .filter((elem) => elem.CommunicationFunctionName === "Welcome Board")
        .map((elem) => {
            return elem.RecipientEmail
        })

    // obtain only "Breakfast" email recipients
    const breakfastEmailList = allEmailRecipients
        .filter((elem) => elem.CommunicationFunctionName === "Breakfast")
        .map((elem) => {
            return elem.RecipientEmail
        })

    // obtain only "Lunch" email recipients
    const lunchEmailList = allEmailRecipients
        .filter((elem) => elem.CommunicationFunctionName === "Lunch")
        .map((elem) => {
            return elem.RecipientEmail
        })

    // obtain only "Factory Tour" email recipients
    const factoryTourEmailList = allEmailRecipients
        .filter((elem) => elem.CommunicationFunctionName === "Campus Tour")
        .map((elem) => {
            return elem.RecipientEmail
        })

    /* React hook; updates state variables to initialize data from database */
    useEffect(() => {
        if (loggedIn) {
            resetHeaders()
            async function runUseEffectFunctions() {
                await dispatch(getAllRecipients()) // all email recipients for all function types
            }
            runUseEffectFunctions()
        }
    }, [dispatch, loggedIn, allEmailRecipients])

    useEffect(() => {
        if (loggedIn) {
            resetHeaders()
            async function runUseEffectFunctions() {
                await dispatch(setVisitStart(visitInfoObj.fromDateTime))
                await dispatch(setVisitEnd(visitInfoObj.toDateTime))
            }
            runUseEffectFunctions()
        }
    }, [dispatch, loggedIn, visitInfoObj.fromDateTime, visitInfoObj.toDateTime])

    /* Function that compares (top level) visit info before editing and after 
        (true = diff, false = same) */
    const diffVisitInfo = (before, after) => {
        const start = new Date(before.StartDateTime)
        const end = new Date(before.EndDateTime)
        if (start.toString() === after.fromDateTime.toString()) {
            if (end.toString() === after.toDateTime.toString()) {
                return !(
                    before.FirmTypeId === after.visitType.id &&
                    before.FirmName === after.visitFirm &&
                    before.FactoryTour === after.functionStatus.factory &&
                    before.Breakfast === after.functionStatus.breakfast &&
                    before.Lunch === after.functionStatus.lunch &&
                    before.WelcomeBoard === after.functionStatus.welcome
                )
            }
            return true
        }
        return true
    }

    /* Function that compares visitor list before editing and after 
        (true = diff, false = same) */
    const diffVisitorInfo = (before, after) => {
        if (before.length !== after.length) {
            return true
        }
        for (let i = 0; i < after.length; i++) {
            const afterElem = after[i]
            const beforeElem = before[i]
            if (afterElem.title !== beforeElem.VisitorTitle) {
                return true
            }
            const fullName = afterElem.firstName + " " + afterElem.lastName
            if (fullName !== beforeElem.VisitorName) {
                return true
            }
        }
        return false
    }

    /* Function that compares host list before editing and after 
        (true = diff, false = same) */
    const diffHostInfo = (before, after) => {
        if (before.length !== after.length) {
            return true
        }
        for (let i = 0; i < after.length; i++) {
            const afterElem = after[i]
            const beforeElem = before[i]
            if (afterElem.id !== beforeElem.id) {
                return true
            }
        }
        return false
    }

    /* Function that compares conference room list before editing and after 
        (true = diff, false = same) */
    const diffConfRoomInfo = (before, after) => {
        if (before.length !== after.length) {
            return true
        }
        for (let i = 0; i < after.length; i++) {
            const afterElem = after[i]
            const beforeElem = before[i]
            if (afterElem.id !== beforeElem.ConferenceRoomId) {
                return true
            }
            const start = new Date(beforeElem.StartDateTime)
            const end = new Date(beforeElem.EndDateTime)
            if (afterElem.fromDateTime.toString() !== start.toString()) {
                return true
            }
            if (afterElem.toDateTime.toString() !== end.toString()) {
                return true
            }
        }
        return false
    }

    /* Handler for top-left button to return to main feed (visitor announcement list view);
        BACK button, when Create mode; DISCARD CHANGES, when Edit mode */
    const handleBackClick = async () => {
        await dispatch(updateHostList([])) // clear host list box area

        await dispatch(updateVisitorList([])) // clear visitor info list box area

        await dispatch(updateConfRoomList([])) // clear conference room info list box area

        navigate("/visitor")
    }

    /* Handles the action onClose for confirmation dialog popup window */
    const handleClose = () => {
        setOpen(false)
    }

    /* Handles the action of clicking the "SAVE" button to send all form input data to 
        save in database */
    const saveStoreContents = async () => {
        let confRoomErrorFlag = false

        // error handling
        if (visitInfoObj.visitType.type === "") {
            setAlertFlag(true)
            setAlertText("Please select a category for the type of visit.")
            return
        } else if (visitInfoObj.visitFirm === "") {
            setAlertFlag(true)
            setAlertText("Please provide a firm name for the visit.")
            return
        } else if (
            !visitInfoObj.fromDateTime ||
            !visitInfoObj.toDateTime ||
            isNaN(visitInfoObj.toDateTime.getTime()) ||
            isNaN(visitInfoObj.fromDateTime.getTime())
        ) {
            setAlertFlag(true)
            setAlertText("Please provide valid date and time representations.")
            return
        } else if (visitInfoObj.toDateTime <= visitInfoObj.fromDateTime) {
            setAlertFlag(true)
            setAlertText(
                "Please provide a valid date and time interval for the visit."
            )
            return
        } else if (allVisitors.length === 0) {
            setAlertFlag(true)
            setAlertText("Please add at least 1 visitor for the visit.")
            return
        } else if (allHosts.length === 0) {
            setAlertFlag(true)
            setAlertText("Please add at least 1 host for the visit.")
            return
        }
        allConfRooms.forEach((elem) => {
            if (elem) {
                if (
                    !(
                        visitInfoObj.fromDateTime <= elem.fromDateTime &&
                        elem.fromDateTime <= visitInfoObj.toDateTime &&
                        visitInfoObj.fromDateTime <= elem.toDateTime &&
                        elem.toDateTime <= visitInfoObj.toDateTime
                    )
                ) {
                    confRoomErrorFlag = true
                    setAlertFlag(true)
                    setAlertText(
                        "Please provide date/time intervals for conference room entries within the visit range."
                    )
                    return
                }
            }
        })
        if (!confRoomErrorFlag) {
            // open confirmation dialog
            setOpen(true)
            return
        }
    }

    /* Handler function for user clicking "CONFIRM" on confirmation dialog box (create or edit) */
    const onConfirm = async () => {
        setLoading(true)
        const visitorFormInfo = {
            type: visitInfoObj.visitType,
            firm: visitInfoObj.visitFirm,
            from: visitInfoObj.fromDateTime,
            to: visitInfoObj.toDateTime,
            functions: visitInfoObj.functionStatus,
            visitors: allVisitors,
            hosts: allHosts,
            confRooms: allConfRooms,
            update: {
                visit: false,
                function: {
                    factory: false,
                    breakfast: false,
                    lunch: false,
                    welcome: false
                },
                host: false,
                visitor: false,
                conf: false,
                visitStart: false
            }
        }
        console.log(visitorFormInfo)
        handleClose()

        let visitId = ""
        // CREATE mode
        if (mode === "create") {
            visitId = uuidv4()

            const auditObject = {
                action: "Create Visitor Announcement",
                visitName: visitorFormInfo.firm,
                actionBy: userFullName
            }
            visitorFormInfo.auditObj = auditObject
            // add all visit information to database
            await dispatch(addVisitInfo(visitId, visitorFormInfo, "create"))

            // send email to all "Visitor Notifications" recipients
            await dispatch(
                sendVisitorEmail(
                    visitorNotificationsEmailList,
                    visitorFormInfo,
                    "create"
                )
            )

            // send email to relevant function recipients
            await sendFunctionEmailSetup(visitorFormInfo, "create")
            // EDIT mode
        } else if (mode === "edit") {
            // use saved visitId
            visitId = selectedVisitId

            // check for differences between BEFORE && AFTER editing
            if (currVisitList.length > 0) {
                const oldVisitDetails = currVisitList[0]
                // Visit top level
                visitorFormInfo.update.visit = diffVisitInfo(
                    oldVisitDetails,
                    visitInfoObj
                )
                // check if Visit Start changed
                visitorFormInfo.update.visitStart = !(
                    new Date(oldVisitDetails.StartDateTime).toString() ===
                    visitInfoObj.fromDateTime.toString()
                )
                // function setup statuses
                visitorFormInfo.update.function.factory =
                    !oldVisitDetails.FactoryTour &&
                    visitInfoObj.functionStatus.factory

                visitorFormInfo.update.function.breakfast =
                    !oldVisitDetails.Breakfast &&
                    visitInfoObj.functionStatus.breakfast

                visitorFormInfo.update.function.lunch =
                    !oldVisitDetails.Lunch && visitInfoObj.functionStatus.lunch

                visitorFormInfo.update.function.welcome =
                    !oldVisitDetails.WelcomeBoard &&
                    visitInfoObj.functionStatus.welcome
            }
            // list of visitors
            visitorFormInfo.update.visitor = diffVisitorInfo(
                currentVisitorList,
                allVisitors
            )
            // list of hosts
            visitorFormInfo.update.host = diffHostInfo(
                currentHostList,
                allHosts
            )
            // list of conf rooms
            visitorFormInfo.update.conf = diffConfRoomInfo(
                currentConfRoomList,
                allConfRooms
            )
            // info for audit log
            const auditObject = {
                action: "Edit Visitor Announcement",
                visitName: visitorFormInfo.firm,
                actionBy: userFullName
            }
            visitorFormInfo.auditObj = auditObject

            // update database with (any new) visit information
            await dispatch(addVisitInfo(visitId, visitorFormInfo, "edit"))

            // send email to all "Visitor Notifications" recipients w subject UPDATED
            await dispatch(
                sendVisitorEmail(
                    visitorNotificationsEmailList,
                    visitorFormInfo,
                    "edit"
                )
            )

            // send setup required emails to recipients (true now, false prior to edit)
            await sendFunctionEmailSetup(visitorFormInfo, "edit")
        }
        await dispatch(getAllStoredVisits())
        await dispatch(setRefreshKey(refreshKey))

        // visit start time changed
        if (visitorFormInfo.update.visitStart) {
            navigate(`/submit/${visitId}?change=${true}`)
        } else {
            navigate(`/submit/${visitId}`)
        }
        setLoading(false)
    }

    /* Determines the relevant function (& their respective recipients) emails to send */
    const sendFunctionEmailSetup = async (visitorFormObj, mode) => {
        const functionObj = visitorFormObj.functions

        const visitInfoToSend = {
            firm: visitorFormObj.firm,
            type: visitorFormObj.type.type,
            start: visitorFormObj.from,
            end: visitorFormObj.to,
            functionType: ""
        }

        if (
            (functionObj.factory && mode === "create") ||
            (mode === "edit" && visitorFormObj.update.function.factory)
        ) {
            visitInfoToSend.functionType = "Campus Tour"
            console.log("Sending factory tour emails...")
            await dispatch(
                sendFunctionEmail(factoryTourEmailList, visitInfoToSend)
            )
        }
        if (
            (functionObj.breakfast && mode === "create") ||
            (mode === "edit" && visitorFormObj.update.function.breakfast)
        ) {
            visitInfoToSend.functionType = "Breakfast"
            console.log("Sending breakfast emails...")
            await dispatch(
                sendFunctionEmail(breakfastEmailList, visitInfoToSend)
            )
        }
        if (
            (functionObj.lunch && mode === "create") ||
            (mode === "edit" && visitorFormObj.update.function.lunch)
        ) {
            visitInfoToSend.functionType = "Lunch"
            console.log("Sending lunch emails...")
            await dispatch(sendFunctionEmail(lunchEmailList, visitInfoToSend))
        }
        if (
            (functionObj.welcome && mode === "create") ||
            (mode === "edit" && visitorFormObj.update.function.welcome)
        ) {
            visitInfoToSend.functionType = "Welcome Board"
            console.log("Sending welcome board emails...")
            await dispatch(
                sendFunctionEmail(welcomeBoardEmailList, visitInfoToSend)
            )
        }
        return
    }

    /* Handler for alert message */
    const handleAlertDialog = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setAlertFlag(false)
    }

    /* Handler for visiting firm type Autocomplete selection */
    const onChangeFirmType = (event, newVal, reason) => {
        if (reason === "clear") {
            setVisitInfoObj({
                ...visitInfoObj,
                visitType: { type: "", id: "" }
            })
        } else {
            setVisitInfoObj({
                ...visitInfoObj,
                visitType: { type: newVal.type, id: newVal.id }
            })
        }
    }

    /* Handler for visiting firm name text field */
    const handleOnChangeFirmInput = (event, inputName) => {
        setVisitInfoObj({ ...visitInfoObj, [inputName]: event.target.value })
    }

    /* Handler for date/time input */
    const handleOnChangeDateInputs = (newValue, inputName) => {
        console.log(newValue)
        if (newValue) {
            setVisitInfoObj({ ...visitInfoObj, [inputName]: newValue })
        }
    }

    // const handleBlur = (event) => {
    //     console.log(visitInfoObj.fromDateTime)
    //     setVisitStart(visitInfoObj.fromDateTime)
    // }

    return (
        <>
            {authenticated ? (
                <>
                    <Grid
                        container
                        direction="row"
                        align="center"
                        justifyContent="center"
                        className={
                            isPhoneLandscape
                                ? classes.VisitorFormTopLandscape
                                : ""
                        }
                    >
                        <Button
                            variant={!isPhoneScreen ? "outlined" : "text"}
                            color="inherit"
                            size="small"
                            className={
                                isSmallerScreen
                                    ? isPhoneScreen
                                        ? classes.BackButtonPhone
                                        : classes.BackButtonSmaller
                                    : classes.BackButton
                            }
                            onClick={handleBackClick}
                        >
                            {mode === "create"
                                ? "BACK"
                                : isPhoneScreen
                                ? "BACK"
                                : "DISCARD CHANGES"}
                        </Button>
                        <Typography className={classes.CreateVAPageTitle}>
                            {mode === "create" ? "CREATE " : "EDIT "}
                            VISITOR ANNOUNCEMENT
                        </Typography>
                    </Grid>
                    <Divider />
                    <Grid container direction="row" justifyContent="center">
                        <Grid
                            container
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            justifyContent="center"
                            className={classes.VisitorForm}
                        >
                            <Grid
                                item
                                xs={11}
                                sm={6}
                                md={7}
                                container
                                className={
                                    isPhoneScreen
                                        ? classes.VisitorFormTopPhone
                                        : ""
                                }
                            >
                                {/* Visiting Type input dropdown component */}
                                <Grid
                                    item
                                    xs={!isSmallPhone ? 6 : 9}
                                    sm={7}
                                    md={6}
                                >
                                    <Autocomplete
                                        disablePortal
                                        options={allFirmTypes}
                                        getOptionLabel={(firmTypeObj) =>
                                            firmTypeObj.type
                                        }
                                        value={visitInfoObj.visitType}
                                        isOptionEqualToValue={(option, value) =>
                                            option.id === value.id
                                        }
                                        onChange={onChangeFirmType}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Visiting Type"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>

                                {/* Visiting Firm Name input text field component */}
                                <Grid
                                    item
                                    xs={11}
                                    sm={8}
                                    md={9}
                                    className={
                                        isPhoneScreen
                                            ? classes.DatePhoneBetween
                                            : ""
                                    }
                                >
                                    <TextField
                                        label="Visiting Firm"
                                        value={visitInfoObj.visitFirm}
                                        variant="standard"
                                        fullWidth
                                        onChange={(e) =>
                                            handleOnChangeFirmInput(
                                                e,
                                                "visitFirm"
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                xs={11}
                                sm={4}
                                md={4}
                                container
                                justifyContent="center"
                                className={
                                    isSmallScreen
                                        ? isSmallerScreen
                                            ? isExtraSmallScreen
                                                ? isPhoneScreen
                                                    ? classes.DatesPhone
                                                    : classes.DatesExtraSmall
                                                : ""
                                            : classes.DatesSmall
                                        : classes.Dates
                                }
                            >
                                {/* Visiting Date/Time (Begin) input selector component */}
                                <Grid item xs={3} sm={3} md={3}>
                                    <Typography>FROM</Typography>
                                </Grid>
                                <Grid item xs={9} sm={9} md={9}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <DateTimePicker
                                            value={dayjs(
                                                visitInfoObj.fromDateTime
                                            )}
                                            onChange={(val) =>
                                                handleOnChangeDateInputs(
                                                    val?.$d,
                                                    "fromDateTime"
                                                )
                                            }
                                            slots={{
                                                textField: TextField,
                                                openPickerIcon:
                                                    InsertInvitationIcon
                                            }}
                                            slotProps={{
                                                textField: {
                                                    variant: "standard",
                                                    error: false,
                                                    className:
                                                        classes.CalendarIconColor
                                                },
                                                openPickerIcon: {
                                                    className: classes.TextColor
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Grid>

                                {/* Visiting Date/Time (End) input selector component */}
                                <Grid
                                    item
                                    xs={3}
                                    sm={3}
                                    md={3}
                                    className={
                                        isPhoneScreen
                                            ? classes.DatePhoneBetween
                                            : ""
                                    }
                                >
                                    <Typography>TO</Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={9}
                                    sm={9}
                                    md={9}
                                    className={
                                        isPhoneScreen
                                            ? classes.DatePhoneBetween
                                            : ""
                                    }
                                >
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <DateTimePicker
                                            value={dayjs(
                                                visitInfoObj.toDateTime
                                            )}
                                            onChange={(val) =>
                                                handleOnChangeDateInputs(
                                                    val?.$d,
                                                    "toDateTime"
                                                )
                                            }
                                            slots={{
                                                textField: TextField,
                                                openPickerIcon:
                                                    InsertInvitationIcon
                                            }}
                                            slotProps={{
                                                textField: {
                                                    variant: "standard",
                                                    error: false,
                                                    className:
                                                        classes.CalendarIconColor
                                                },
                                                openPickerIcon: {
                                                    className: classes.TextColor
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            sm={12}
                            md={12}
                            justifyContent="center"
                            alignItems="flex-end"
                            className={classes.VisitorFormB}
                        >
                            {isPhoneScreen && (
                                <Grid
                                    item
                                    xs={!isSmallPhone ? 8 : 11}
                                    sm={isExtraSmallScreen ? 5 : 4}
                                    md={3}
                                    container
                                    className={
                                        isMediumScreen
                                            ? isExtraSmallScreen
                                                ? isPhoneScreen
                                                    ? classes.FunctionBoxPhone
                                                    : classes.FunctionBoxExtraSmall
                                                : classes.FunctionBoxMed
                                            : classes.FunctionBox
                                    }
                                >
                                    {/* Functions toggle selector area */}
                                    <FunctionsBox
                                        visitInfoObj={visitInfoObj}
                                        setVisitInfoObj={setVisitInfoObj}
                                    />
                                </Grid>
                            )}
                            {isExtraSmallScreen && (
                                <Grid
                                    item
                                    xs={11}
                                    sm={5}
                                    md={4}
                                    container
                                    className={classes.HostBox}
                                >
                                    {/* Host Information area */}
                                    <Grid
                                        item
                                        md={12}
                                        className={classes.VisitorLabel}
                                    >
                                        <label className={classes.BoxLabel}>
                                            HOSTS
                                        </label>
                                    </Grid>
                                    <HostBox mode={mode} />
                                </Grid>
                            )}
                            {!isExtraSmallScreen && (
                                <Grid
                                    item
                                    xs={12}
                                    sm={7}
                                    md={7}
                                    container
                                    className={
                                        isMediumScreen
                                            ? isSmallerScreen
                                                ? isExtraSmallScreen
                                                    ? classes.VisitorBoxExtraSmall
                                                    : classes.VisitorBoxSmaller
                                                : classes.VisitorBoxMed
                                            : classes.VisitorBox
                                    }
                                >
                                    {/* Visitor Information area */}
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.VisitorLabel}
                                    >
                                        <label className={classes.BoxLabel}>
                                            VISITORS
                                        </label>
                                    </Grid>
                                    <VisitorBox mode={mode} />
                                </Grid>
                            )}

                            {!isPhoneScreen && (
                                <Grid
                                    item
                                    xs={10}
                                    sm={isExtraSmallScreen ? 5 : 4}
                                    md={3}
                                    container
                                    className={
                                        isMediumScreen
                                            ? isExtraSmallScreen
                                                ? isPhoneScreen
                                                    ? classes.FunctionBoxPhone
                                                    : classes.FunctionBoxExtraSmall
                                                : classes.FunctionBoxMed
                                            : classes.FunctionBox
                                    }
                                >
                                    {/* Functions toggle selector area */}
                                    <FunctionsBox
                                        visitInfoObj={visitInfoObj}
                                        setVisitInfoObj={setVisitInfoObj}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <Grid
                            container
                            item
                            sm={12}
                            md={12}
                            justifyContent="center"
                            alignItems="flex-end"
                            className={
                                isExtraSmallScreen
                                    ? classes.VisitorFormBExtraSmall
                                    : classes.VisitorFormB
                            }
                        >
                            {!isExtraSmallScreen && (
                                <Grid
                                    item
                                    sm={4}
                                    md={4}
                                    container
                                    className={classes.HostBox}
                                >
                                    {/* Host Information area */}
                                    <Grid
                                        item
                                        md={12}
                                        className={classes.VisitorLabel}
                                    >
                                        <label className={classes.BoxLabel}>
                                            HOSTS
                                        </label>
                                    </Grid>
                                    <HostBox mode={mode} />
                                </Grid>
                            )}
                            {isExtraSmallScreen && (
                                <Grid
                                    item
                                    xs={11}
                                    sm={isExtraSmallScreen ? 11 : 7}
                                    md={7}
                                    container
                                    className={
                                        isMediumScreen
                                            ? isSmallerScreen
                                                ? isPhoneScreen
                                                    ? classes.VisitorBoxPhone
                                                    : classes.VisitorBoxSmaller
                                                : classes.VisitorBoxMed
                                            : classes.VisitorBox
                                    }
                                >
                                    {/* Visitor Information area */}
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.VisitorLabel}
                                    >
                                        <label className={classes.BoxLabel}>
                                            VISITORS
                                        </label>
                                    </Grid>
                                    <VisitorBox mode={mode} />
                                </Grid>
                            )}

                            <Grid
                                item
                                xs={11}
                                sm={isExtraSmallScreen ? 11 : 7}
                                md={7}
                                container
                                className={
                                    isExtraSmallScreen
                                        ? isPhoneScreen
                                            ? classes.ConfRoomBoxSmall
                                            : classes.ConfRoomBoxExtraSmall
                                        : classes.ConfRoomBox
                                }
                            >
                                {/* Conference Room Information area */}
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.VisitorLabel}
                                >
                                    <label className={classes.BoxLabel}>
                                        CONFERENCE ROOMS
                                    </label>
                                    <Tooltip
                                        placement="top-start"
                                        title="Conference room entries are not yet linked to Outlook calendar bookings."
                                    >
                                        <InfoOutlinedIcon
                                            fontSize="small"
                                            color="inherit"
                                            className={
                                                classes.ConferenceRoomInfo
                                            }
                                        />
                                    </Tooltip>
                                </Grid>
                                <ConferenceRoomBox
                                    visitStart={visitInfoObj.fromDateTime}
                                    visitEnd={visitInfoObj.toDateTime}
                                    mode={mode}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item md={12} justifyContent="center">
                            {/* Alerts pop-up area */}
                            <Grid item>
                                <br />
                                {alertFlag && (
                                    <Alert
                                        onClose={handleAlertDialog}
                                        severity="error"
                                    >
                                        {alertText}
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            md={12}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {/* SAVE button for form submission */}
                            <Button
                                variant="outlined"
                                color="secondary"
                                size="large"
                                className={classes.SaveButton}
                                onClick={saveStoreContents}
                                disabled={loading}
                            >
                                <Stack>
                                    <Typography>SAVE AND EMAIL</Typography>
                                    {loading && <LinearProgress />}
                                </Stack>
                            </Button>

                            {/* More info tooltip */}
                            <Tooltip
                                placement="top"
                                title="Please be aware company-wide emails will be sent"
                            >
                                <InfoOutlinedIcon
                                    color="inherit"
                                    fontSize="small"
                                    className={classes.SaveInfoTip}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                            <br />
                        </Grid>
                    </Grid>

                    {/* Confirmation dialog component to ensure intentional form save */}
                    <Dialog
                        open={open}
                        maxWidth="sm"
                        fullWidth={true}
                        onClose={handleClose}
                    >
                        <ConfirmationDialog
                            messageConfirm={"Are you sure you want to proceed?"}
                            messageEmail={
                                "Please verify all information supplied is accurate. Saving this form will send a company-wide announcement email."
                            }
                            messageEmail2={visitorNotificationsEmailList.join(
                                ", "
                            )}
                            title={"SAVE AND EMAIL ?"}
                            onConfirm={onConfirm}
                            onCancel={handleClose}
                        />
                    </Dialog>
                </>
            ) : (
                <LoginPrompt />
            )}
        </>
    )
}

export default VisitorForm
