import { React } from "react"
import { Typography, Button } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"

import { setSelectedFunction } from "../../actions/adminActions"
import useStyles from "./styles/AdminSettingStyles"

/* Component that represents the button for each function */
function FunctionButton({ label }) {
    const classes = useStyles()
    const dispatch = useDispatch()

    // obtain which function button user clicked (from Redux store)
    const selectedFunction = useSelector(
        (state) => state.admin.selectedFunction
    )

    /* Handles action when function button clicked. */
    const handleClick = async () => {
        dispatch(setSelectedFunction(label))
    }

    return (
        <div>
            <Button
                variant="outlined"
                className={classes.FunctionButton}
                onClick={handleClick}
                color={selectedFunction === label ? "secondary" : "primary"}
            >
                <Typography>{label}</Typography>
            </Button>
        </div>
    )
}

export default FunctionButton
