import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    BoxLabelVA: {
        position: "relative",
        marginRight: "auto",
        backgroundColor:
            theme.palette.type === "dark"
                ? "rgb(48, 48, 48)"
                : "rgb(250, 250, 250)",
        fontWeight: "1000",
        fontStyle: "italic",
        fontSize: "16px",
        fontFamily: ["Century !important"].join(",")
    },
    BoxVA: {
        padding: "10px",
        margin: "25px",
        border: "1px solid"
    },
    FunctionButton: {
        display: "block",
        width: "140px",
        height: "60px",
        marginTop: "10px",
        marginBottom: "10px"
    },
    SelectText: {
        padding: "10px"
    },
    root: {
        [theme.breakpoints.between("sm", 1000)]: {
            paddingLeft: "5%",
            paddingRight: "5%"
        },
        [theme.breakpoints.between(1000, 1400)]: {
            paddingLeft: "8%",
            paddingRight: "8%"
        },
        [theme.breakpoints.between(1400, 1800)]: {
            paddingLeft: "12%",
            paddingRight: "12%"
        },
        [theme.breakpoints.between(1800, 2000)]: {
            paddingLeft: "15%",
            paddingRight: "15%"
        },
        [theme.breakpoints.up(2000)]: {
            paddingLeft: "20%",
            paddingRight: "20%"
        }
    },
    RecipientBox: {
        marginTop: "20px",
        marginBottom: "20px"
    },
    EmailVal: {
        paddingTop: "7px",
        fontSize: "15px",
        overflow: "hidden"
    },
    RecipientLabel: {
        paddingTop: "7px",
        fontSize: "15px",
        fontWeight: "600"
    },
    NoneEmails: {
        paddingTop: "7px",
        fontSize: "15px",
        fontStyle: "italic"
    },
    AddEmail: {
        paddingTop: "35px"
    },
    EmailTextField: {
        width: "80%"
    },
    DeleteButton: {
        paddingLeft: "5px"
    },
    notchedOutline: {
        borderWidth: "1px",
        borderColor: "grey !important"
    },
    floatingLabelFocusStyle: {
        color: "grey !important"
    }
}))

export default useStyles
