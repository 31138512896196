import React, { useEffect } from "react"
import clsx from "clsx"
import { useSelector, useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import {
    AppBar,
    Toolbar,
    Typography,
    Drawer,
    List,
    IconButton,
    ListItem,
    ListItemText,
    ListItemIcon,
    Divider,
    Tooltip,
    Chip
} from "@material-ui/core"
import MenuIcon from "@mui/icons-material/Menu"
import Brightness3Icon from "@mui/icons-material/Brightness3"
import WbSunnyIcon from "@mui/icons-material/WbSunny"
import HomeIcon from "@mui/icons-material/Home"
import LogoutIcon from "@mui/icons-material/Logout"
import SettingsIcon from "@mui/icons-material/Settings"
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople"
import DescriptionIcon from "@mui/icons-material/Description"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import NewReleasesIcon from "@mui/icons-material/NewReleases"
import CottageIcon from "@mui/icons-material/Cottage"

import logo from "../../images/precipartLogoSquare.png"
import useStyles from "./styles/NavigationStyles"
import { homeURL, environment } from "../../config"
import {
    getUserData,
    toggleDarkMode,
    logoutUser
} from "../../actions/authActions"
import {
    updateHostList,
    updateVisitorList,
    updateConfRoomList
} from "../../actions/visitorFormActions"
import resetHeaders from "../../functions/resetHeaders"
import firebaseAuth from "../../auth/firebaseAuth"

function Navigation() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const auth = useSelector((state) => state.auth)
    const permission = useSelector((state) => state.auth.permissions)
    const [open, setOpen] = React.useState(false)

    const navigationItems = [
        {
            text: "Home Page",
            path: "/home",
            visible: true,
            icon: <CottageIcon />
        },
        {
            text: "Admin Settings",
            path: "/admin",
            visible:
                permission.AdminVisitorAnnouncement ||
                permission.AdminISODocumentUpdate,
            icon: <SettingsIcon />
        },
        {
            text: "Visitor Announcements",
            path: "/visitor",
            visible: true,
            icon: <EmojiPeopleIcon />
        },
        {
            text: "ISO Regulatory Updates",
            path: "/iso",
            visible: true,
            icon: <DescriptionIcon />
        }
    ]

    const handleDarkModeToggle = async () => {
        await dispatch(toggleDarkMode(auth.userData))
        await dispatch(getUserData(auth.userData.GoogleID))
    }

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    const handleGoHome = async () => {
        setOpen(false)
        window.open(homeURL, "_self")
    }

    const handleHelp = async () => {
        setOpen(false)
        window.open(
            "https://precipart0.sharepoint.com/sites/PrecipartWebApplications/SitePages/Visitor.aspx",
            "_blank"
        )
    }

    const handleClick = async () => {
        // clear host list box area
        await dispatch(updateHostList([]))
        // clear visitor info list box area
        await dispatch(updateVisitorList([]))
        // clear conference room info list box area
        await dispatch(updateConfRoomList([]))

        handleDrawerClose()
    }

    const handleLogout = async () => {
        handleDrawerClose()
        dispatch(logoutUser())
        await firebaseAuth.signOut()
        navigate("/")
    }

    return (
        <div className={classes.Navigation}>
            <AppBar
                position="fixed"
                className={clsx(classes.AppBar, {
                    [classes.AppBarShift]: open
                })}
                // PaperProps={{ elevation: 15 }}
            >
                <Toolbar>
                    <img
                        alt="Precipart Logo"
                        src={logo}
                        className={classes.Logo}
                    />
                    <Typography
                        variant="h6"
                        className={classes.ApplicationName}
                        noWrap
                    >
                        Notification Station{" "}
                        {environment && environment !== "production" && (
                            <Chip
                                icon={<NewReleasesIcon />}
                                label={environment.toUpperCase()}
                                style={{ marginLeft: "5px" }}
                            />
                        )}
                    </Typography>
                    {auth.userData.AssociateOID && (
                        <Tooltip title={open ? "Close Menu" : "Open Menu"}>
                            <IconButton
                                color="inherit"
                                aria-label={
                                    open ? "close drawer" : "open drawer"
                                }
                                edge="end"
                                onClick={
                                    open ? handleDrawerClose : handleDrawerOpen
                                }
                            >
                                <MenuIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Toolbar>
            </AppBar>

            <div
                className={clsx(classes.Content, {
                    [classes.contentShift]: open
                })}
            >
                <div className={classes.DrawerHeader} />
            </div>
            <Drawer
                className={classes.Drawer}
                variant="persistent"
                anchor="right"
                open={open}
                PaperProps={{ elevation: 15 }}
            >
                <div className={classes.DrawerHeader}></div>
                <div className={classes.DrawerHeader}>
                    <Tooltip title="Return Home">
                        <IconButton>
                            <HomeIcon onClick={handleGoHome} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Logout">
                        <IconButton>
                            <LogoutIcon onClick={handleLogout} />
                        </IconButton>
                    </Tooltip>
                    {auth.userData && (
                        <Tooltip title="Toggle Darkmode">
                            <IconButton onClick={handleDarkModeToggle}>
                                {auth.userData.DarkMode === "dark" ? (
                                    <WbSunnyIcon />
                                ) : (
                                    <Brightness3Icon />
                                )}
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
                <Divider />
                <List>
                    {navigationItems
                        .filter((item) => item.visible)
                        .map((item) => (
                            <ListItem
                                button
                                key={item.text}
                                component={Link}
                                to={item.path}
                                onClick={handleClick}
                            >
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.text} />
                            </ListItem>
                        ))}
                    <Divider />
                    <ListItem button key="Help" onClick={handleHelp}>
                        <ListItemIcon>
                            <HelpOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary="Help" />
                    </ListItem>
                </List>
            </Drawer>
        </div>
    )
}

export default Navigation
