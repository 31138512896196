import { React, useState, useEffect } from "react"
import { Grid, Typography, Button } from "@material-ui/core"
import { Divider, Dialog } from "@mui/material"
import TaskAltIcon from "@mui/icons-material/TaskAlt"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams, useLocation } from "react-router-dom"

import ReminderDialog from "./ReminderDialog"
import LoginPrompt from "../login/LoginPrompt"
import resetHeaders from "../../functions/resetHeaders"
import useStyles from "./styles/VisitorStyles"
import {
    updateHostList,
    updateVisitorList,
    updateConfRoomList,
    getFirmTypes
} from "../../actions/visitorFormActions"
import {
    updateReminderList,
    setSelectedVisitId,
    getVisitReminders,
    getVisitById,
    getReplaceVisitReminders
} from "../../actions/visitorFeedActions"

/* Component that defines the rendered page after a user successfully submits the 
    Visitor Announcement form (CREATE or EDIT mode) */
function SubmittedPage() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // Get array of Firmtype mapping (to ID) and modify type names
    const dbFirmTypes = useSelector((state) => state.visitor.firmTypes)
    const getFirmType = (id) => {
        const firmType = dbFirmTypes.filter((elem) => elem.FirmTypeId === id)[0]
        if (firmType) {
            if (firmType.FirmType === "Customer") {
                return firmType.FirmType + "/Prospect"
            } else if (firmType.FirmType === "Vendor") {
                return firmType.FirmType + "/Supplier"
            } else {
                return firmType.FirmType
            }
        } else {
            return
        }
    }

    const oldVisitStart = useSelector((state) => state.visitFeed.oldVisitStart)

    // state repr. for reminders dialog popup
    const [openReminder, setOpenReminder] = useState(false)
    const reminderOnClose = () => {
        setStartChanged(false)
        setOpenReminder(false)
    }

    // current visitId
    const { visitId } = useParams()

    // state value traveled from form, repr. if Visit Start changed
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const [startChanged, setStartChanged] = useState(
        searchParams.get("change") === "true"
    )

    // authentication store variable
    const loggedIn = useSelector(
        (state) => state.auth.user && state.auth.user.auth
    )

    const authenticated = useSelector((state) => state.auth.authenticated)

    // current visit details object (top level)
    const currVisitList = useSelector(
        (state) => state.visitFeed.currentVisitObj
    )
    const currVisitObj =
        currVisitList && currVisitList.length > 0 ? currVisitList[0] : null

    // obtain reminders for current visit, if exists
    const currentReminders = useSelector(
        (state) => state.visitFeed.currentReminders
    )

    // handler for onClick to view all visitor announcements
    const handleBackClick = () => {
        navigate("/visitor")
    }

    // handler to open reminder dialog and set up relevant data
    const handleOpenReminder = async () => {
        // set up relevant data
        await dispatch(getFirmTypes())
        await dispatch(updateReminderList([]))
        await dispatch(getVisitById(visitId))
        await dispatch(
            getReplaceVisitReminders(
                visitId,
                oldVisitStart,
                currVisitObj?.StartDateTime
            )
        )
        await dispatch(setSelectedVisitId(visitId))
        await dispatch(getVisitReminders(visitId, currVisitObj?.StartDateTime))
        console.log(currVisitObj?.StartDateTime)
        console.log(oldVisitStart)

        // open reminder dialog
        setOpenReminder(true)
    }

    /* React hook; update store variables to clear out form data, after successful submission */
    useEffect(() => {
        if (loggedIn) {
            resetHeaders()
            async function runUseEffectFunctions() {
                // clear host list box area
                await dispatch(updateHostList([]))
                // clear visitor info list box area
                await dispatch(updateVisitorList([]))
                // clear conference room info list box area
                await dispatch(updateConfRoomList([]))

                // if relevant, get already existing reminders
                if (startChanged) {
                    await dispatch(getVisitById(visitId))
                    // replace reminders
                    await dispatch(
                        getReplaceVisitReminders(
                            visitId,
                            oldVisitStart,
                            currVisitObj?.StartDateTime
                        )
                    )
                    // get new reminders
                    await dispatch(
                        getVisitReminders(visitId, currVisitObj?.StartDateTime)
                    )
                }
            }
            runUseEffectFunctions()
        }
    }, [dispatch, loggedIn])

    return (
        <>
            {authenticated ? (
                <>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        className={classes.PageTitle}
                    >
                        VISITOR ANNOUNCEMENTS
                    </Grid>
                    <Divider />
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        className={classes.SubmitPageContainer}
                    >
                        {/* Short message stating successful submission */}
                        <Grid item xs={12}>
                            <Typography align="center">
                                <TaskAltIcon
                                    fontSize="large"
                                    sx={{ color: "#DF7A00" }}
                                />
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="h2"
                                align="center"
                                className={classes.ThankYou}
                            >
                                Thank you!
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                align="center"
                                className={classes.Success}
                            >
                                The form was submitted successfully.
                            </Typography>
                        </Grid>

                        {/* Dynamic rendering only if EDIT mode && visit start changed */}
                        {startChanged ? (
                            <>
                                <Grid item xs={12}>
                                    <br />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        align="center"
                                        className={classes.StartChanged}
                                    >
                                        Note, your edits have modified when the
                                        visit begins.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        align="center"
                                        className={classes.StartChanged}
                                    >
                                        Please adjust your reminder times
                                        accordingly.
                                    </Typography>
                                </Grid>
                            </>
                        ) : (
                            <></>
                        )}

                        {/* Navigate to open relevant Reminder dialog, with visit info */}
                        <Grid item xs={12} className={classes.BackToVAFeed}>
                            <Button
                                variant="outlined"
                                color="inherit"
                                size="small"
                                onClick={handleOpenReminder}
                            >
                                SET YOUR VISIT REMINDERS NOW
                            </Button>
                        </Grid>

                        {/* Navigate back to main (visitor announcement list view) feed */}
                        <Grid item xs={12} className={classes.BackToVAFeed}>
                            <Button
                                variant="outlined"
                                color="inherit"
                                size="small"
                                disabled={
                                    startChanged
                                        ? startChanged &&
                                          (currentReminders.week ||
                                              currentReminders.day ||
                                              (currentReminders.custom &&
                                                  currentReminders.custom
                                                      .length > 0))
                                        : false
                                }
                                onClick={handleBackClick}
                            >
                                VIEW ALL VISITOR ANNOUNCEMENTS
                            </Button>
                        </Grid>
                    </Grid>

                    {/* Reminder dialog popup window */}
                    <Dialog
                        open={openReminder}
                        onClose={reminderOnClose}
                        maxWidth="md"
                        fullWidth={true}
                    >
                        <ReminderDialog
                            visitObj={currVisitObj}
                            visitType={getFirmType(currVisitObj?.FirmTypeId)}
                            onCloseFxn={reminderOnClose}
                        />
                    </Dialog>
                </>
            ) : (
                <LoginPrompt />
            )}
        </>
    )
}

export default SubmittedPage
