import { React, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
    Button,
    Grid,
    Typography,
    TextField,
    FormControlLabel,
    useTheme,
    useMediaQuery,
    IconButton
} from "@material-ui/core"
import { Divider, InputAdornment, Switch, Tooltip } from "@mui/material"
import { Link } from "react-router-dom"
import SearchIcon from "@mui/icons-material/Search"
import ClearIcon from "@mui/icons-material/Clear"
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace"

import VisitorListItem from "./VisitorListItem"
import {
    getFirmTypes,
    getAllConferenceRooms,
    getAllActiveEmployees
} from "../../actions/visitorFormActions"
import { getAllStoredVisits } from "../../actions/visitorFeedActions"
import {
    getAllRecipients,
    getAllCommTypes,
    getAllFunctionTypes
} from "../../actions/adminActions"
import LoginPrompt from "../login/LoginPrompt"
import resetHeaders from "../../functions/resetHeaders"
import useStyles from "./styles/VisitorStyles"

/* Component that defines the landing page of application; the Visitor Announcements tab in menu
    (main feed; visitor announcement list view) */
function VisitorAnnouncement() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const theme = useTheme()
    const navigate = useNavigate()

    const refreshKey = useSelector((state) => state.visitFeed.refreshKey)

    // boolean flag for small screen size (mobile)
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

    // state repr. for text in search box
    const [searchText, setSearchText] = useState("")

    // state repr. for toggle switch
    const [pastToggleState, setPastToggleState] = useState(false)

    // Store Variables
    const loggedIn = useSelector(
        (state) => state.auth.user && state.auth.user.auth
    )
    const authenticated = useSelector((state) => state.auth.authenticated)
    const permission = useSelector((state) => state.auth.permissions)

    // Obtain list of all visits from store (database)
    const allStoredVisits = useSelector(
        (state) => state.visitor.allStoredVisits
    )

    // Filter all visits for current visits
    const allStoredVisitsCurrent = allStoredVisits.filter(
        (vis) =>
            (!searchText ||
                vis.FirmName.toLowerCase().includes(
                    searchText.toLowerCase()
                )) &&
            vis.Active &&
            new Date(vis.StartDateTime) <= new Date() &&
            new Date() <= new Date(vis.EndDateTime)
    )

    // Filter all visits for upcoming visits
    const allStoredVisitsUpcoming = allStoredVisits.filter(
        (vis) =>
            (!searchText ||
                vis.FirmName.toLowerCase().includes(
                    searchText.toLowerCase()
                )) &&
            vis.Active &&
            new Date() < new Date(vis.StartDateTime)
    )

    // Filter all visits for past visits
    const allStoredVisitsPast = allStoredVisits.filter(
        (vis) =>
            (!searchText ||
                vis.FirmName.toLowerCase().includes(
                    searchText.toLowerCase()
                )) &&
            vis.Active &&
            new Date(vis.EndDateTime) < new Date()
    )
    // sort past visits in descending order
    allStoredVisitsPast.sort(
        (a, b) =>
            new Date(b.StartDateTime).getTime() -
            new Date(a.StartDateTime).getTime()
    )

    // Handler function for search bar textfield
    const handleChangeSearch = (e) => {
        setSearchText(e.target.value)
    }

    // Handler function for clear option in search bar
    const handleClear = () => {
        setSearchText("")
    }

    // Handler function for toggle to view past VAs
    const handleToggleChange = (e) => {
        setPastToggleState(e.target.checked)
    }

    const handleBackClick = () => {
        navigate(`/home`)
    }

    /* React hook; updates state variables to initialize needed data from backend. */
    useEffect(() => {
        if (loggedIn) {
            resetHeaders()
            async function runUseEffectFunctions() {
                await dispatch(getAllStoredVisits()) // all visits saved (database)
                await dispatch(getFirmTypes()) // (static) all firm types, with id
                await dispatch(getAllConferenceRooms()) // (static) all active conf. rooms, with id
                await dispatch(getAllActiveEmployees()) // (app admin) all active employees, with id
                await dispatch(getAllRecipients()) // all email recipients (def. in admin)
                await dispatch(getAllCommTypes()) // (static) all communication types, with id
                await dispatch(getAllFunctionTypes()) // (static) all function setup types
            }
            runUseEffectFunctions()
        }
    }, [dispatch, loggedIn])

    return (
        <>
            {authenticated ? (
                <div key={refreshKey}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        className={classes.PageTitle}
                    >
                        {/* <IconButton
                            color="inherit"
                            size="small"
                            className={classes.BackButtonMain}
                            onClick={handleBackClick}
                        >
                            <KeyboardBackspaceIcon />
                        </IconButton>
                        <Typography className={classes.CreateVAPageTitle}> */}
                        VISITOR ANNOUNCEMENTS
                        {/* </Typography> */}
                    </Grid>
                    <Divider />
                    <Grid
                        container
                        direction="row"
                        align="center"
                        justifyContent="space-evenly"
                    >
                        {/* button component that links to Visitor Form creation */}
                        <Grid
                            item
                            xs={5}
                            md={2}
                            className={classes.ButtonGridWidth}
                        >
                            {permission.ManageVisitorAnnouncement ? (
                                <Link
                                    to="/visitor-form"
                                    style={{
                                        textDecoration: "none",
                                        color: "inherit"
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        color="inherit"
                                        className={classes.CreateNewButton}
                                    >
                                        Create New
                                    </Button>
                                </Link>
                            ) : (
                                ""
                            )}
                        </Grid>

                        {isSmallScreen && (
                            <Grid item xs={6} md={2}>
                                <TextField
                                    placeholder="Search..."
                                    value={searchText}
                                    className={classes.SearchBar}
                                    onChange={handleChangeSearch}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment
                                                disableTypography={true}
                                                position="start"
                                                style={{ color: "inherit" }}
                                            >
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <IconButton
                                                style={{ padding: "5px" }}
                                                onClick={handleClear}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        )
                                    }}
                                />
                            </Grid>
                        )}

                        {/* component that renders the feed of all saved visitor announcements */}
                        <Grid
                            container
                            item
                            xs={10}
                            md={8}
                            // direction="column"
                            align="center"
                            className={classes.VisitorAnnouncementList}
                        >
                            {/* toggle switch to display/hide past visits */}
                            <Grid item container justifyContent="flex-end">
                                <Tooltip
                                    title="Toggle to view or hide past visits"
                                    placement="left"
                                >
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={pastToggleState}
                                                color="warning"
                                                size="small"
                                                onChange={handleToggleChange}
                                            />
                                        }
                                        label="PAST"
                                        labelPlacement="start"
                                    />
                                </Tooltip>
                            </Grid>
                            {/* feed of all current visits */}
                            <Grid item xs={12}>
                                <Typography
                                    align="left"
                                    className={classes.PageTitle}
                                >
                                    CURRENT
                                </Typography>
                                <Divider
                                    style={{
                                        height: 5,
                                        marginBottom: "10px",
                                        color: "inherit"
                                    }}
                                />
                            </Grid>
                            {allStoredVisitsCurrent.length > 0 ? (
                                allStoredVisitsCurrent.map((vis) => (
                                    <VisitorListItem
                                        key={vis.VisitId}
                                        visitObj={vis}
                                        past={false}
                                    />
                                ))
                            ) : (
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.VAListItem}
                                >
                                    <Typography
                                        align="center"
                                        variant="h6"
                                        className={classes.PaperFirmName}
                                    >
                                        <i>No visits to show.</i>
                                    </Typography>
                                </Grid>
                            )}
                            <br />
                            {/* feed of all upcoming visits */}
                            <Grid item xs={12}>
                                <Typography
                                    align="left"
                                    className={classes.PageTitle}
                                >
                                    UPCOMING
                                </Typography>
                                <Divider
                                    style={{
                                        height: 5,
                                        marginBottom: "10px",
                                        color: "inherit"
                                    }}
                                />
                            </Grid>
                            {allStoredVisitsUpcoming.length > 0 ? (
                                allStoredVisitsUpcoming.map((vis) => (
                                    <VisitorListItem
                                        key={vis.VisitId}
                                        visitObj={vis}
                                        past={false}
                                    />
                                ))
                            ) : (
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.VAListItem}
                                >
                                    <Typography
                                        align="center"
                                        variant="h6"
                                        className={classes.PaperFirmName}
                                    >
                                        <i>No visits to show.</i>
                                    </Typography>
                                </Grid>
                            )}
                            <br />
                            {/* feed of all past visits */}
                            {pastToggleState ? (
                                <>
                                    <Grid item xs={12}>
                                        <Typography
                                            align="left"
                                            className={classes.PageTitle}
                                        >
                                            PAST
                                        </Typography>
                                        <Divider
                                            style={{
                                                height: 5,
                                                marginBottom: "10px",
                                                color: "inherit"
                                            }}
                                        />
                                    </Grid>
                                    {allStoredVisitsPast.length > 0 ? (
                                        allStoredVisitsPast.map((vis) => (
                                            <VisitorListItem
                                                key={vis.VisitId}
                                                visitObj={vis}
                                                past={true}
                                            />
                                        ))
                                    ) : (
                                        <Grid
                                            item
                                            xs={12}
                                            className={classes.VAListItem}
                                        >
                                            <Typography
                                                align="center"
                                                variant="h6"
                                                className={
                                                    classes.PaperFirmName
                                                }
                                            >
                                                <i>No visits to show.</i>
                                            </Typography>
                                        </Grid>
                                    )}
                                </>
                            ) : null}
                        </Grid>

                        {/* search bar */}
                        {!isSmallScreen && (
                            <Grid item xs={5} md={2}>
                                <TextField
                                    placeholder="Search..."
                                    value={searchText}
                                    className={classes.SearchBar}
                                    onChange={handleChangeSearch}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment
                                                disableTypography={true}
                                                position="start"
                                                style={{ color: "inherit" }}
                                            >
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <IconButton
                                                style={{ padding: "5px" }}
                                                onClick={handleClear}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        )
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                </div>
            ) : (
                <LoginPrompt />
            )}
        </>
    )
}

export default VisitorAnnouncement
