import { React, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Card, CardContent, TextField } from "@mui/material"
import { Grid, Typography, Button } from "@material-ui/core"

import useStyles from "./styles/AdminSettingStyles"
import RecipientRow from "./RecipientRow"
import Alert from "../main/Alert"
import { addRecipient, getAllRecipients } from "../../actions/adminActions"

/* Component that represents the area where current recipients displayed. */
function RecipientInfo({ allFunctionsMap, allRecipients }) {
    const classes = useStyles()
    const dispatch = useDispatch()

    // state repr. of text field for email
    const [emailInput, setEmailInput] = useState("")

    // user's full name
    const userFullName = useSelector((state) => state.auth.user.displayName)

    // error alert message
    const [alertFlag, setAlertFlag] = useState(false)
    const [alertText, setAlertText] = useState("")

    // store variable for selected function (button)
    const selectedFunction = useSelector(
        (state) => state.admin.selectedFunction
    )

    // obtains relevant recipient list from list of all recipients for all functions
    const filteredRecipientList = allRecipients.filter(
        (rec) => rec.CommunicationFunctionName === selectedFunction
    )

    /* Handles action when the text field value changes */
    const handleOnChangeEmail = (event) => {
        setEmailInput(event.target.value)
    }

    /* Function that obtains the function ID that corresponds to its name. */
    const getCommFunctionId = (funcName) => {
        for (let index = 0; index < allFunctionsMap.length; index++) {
            const element = allFunctionsMap[index]
            if (element.CommunicationFunctionName === funcName) {
                return element.CommunicationFunctionId
            }
        }
    }

    /* Handles action when the "ADD" button is clicked. */
    const handleAddClick = async () => {
        if (emailInput.endsWith("@precipart.com")) {
            let commFuncId = getCommFunctionId(selectedFunction)
            const auditObject = {
                action: "Add Email",
                visitName: selectedFunction,
                actionBy: userFullName,
                newValue: emailInput
            }

            await dispatch(addRecipient(commFuncId, emailInput, auditObject))
            await dispatch(getAllRecipients())
            // error handling
        } else {
            setAlertFlag(true)
            setAlertText("Please enter a valid Precipart domain email address.")
            return
        }
        // clear email input box
        setEmailInput("")
    }

    /* Handles action for alert messages. */
    const handleAlertDialog = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setAlertFlag(false)
        setEmailInput("")
    }

    return (
        <div>
            <Card elevation={12} className={classes.RecipientBox}>
                <CardContent>
                    <Grid container direction="row">
                        <Grid
                            container
                            direction="column"
                            item
                            xs={12}
                            sm={12}
                            md={5}
                        >
                            <Grid item className={classes.RecipientLabel}>
                                CURRENT RECIPIENTS :
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="column"
                            item
                            xs={12}
                            sm={12}
                            md={7}
                        >
                            {/* Case on number of email recipient entries, to display */}
                            {filteredRecipientList.length !== 0 ? (
                                filteredRecipientList?.map((recipient) => (
                                    <RecipientRow
                                        key={
                                            recipient.CommunicationFunctionId +
                                            ":::" +
                                            recipient.RecipientEmail
                                        }
                                        rec={recipient}
                                    />
                                ))
                            ) : (
                                <Typography className={classes.NoneEmails}>
                                    No emails found.
                                </Typography>
                            )}
                        </Grid>
                        {/* Alerts pop-up area */}
                        <Grid
                            item
                            container
                            xs={12}
                            alignItems="center"
                            justify="flex-end"
                        >
                            <Grid item>
                                {alertFlag && (
                                    <Alert
                                        onClose={handleAlertDialog}
                                        severity="error"
                                    >
                                        {alertText}
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        className={classes.AddEmail}
                    >
                        {/* Text field to enter email address */}
                        <Grid item xs={9}>
                            <TextField
                                label="Email"
                                variant="outlined"
                                size="small"
                                value={emailInput}
                                onChange={handleOnChangeEmail}
                                className={classes.EmailTextField}
                                InputProps={{
                                    classes: {
                                        notchedOutline: classes.notchedOutline
                                    }
                                }}
                                InputLabelProps={{
                                    className: classes.floatingLabelFocusStyle
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            {/* "ADD" button to add email recipient */}
                            <Button color="secondary" onClick={handleAddClick}>
                                ADD RECIPIENT
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    )
}

export default RecipientInfo
