import { React, useState, useEffect } from "react"
import { Button, Grid, LinearProgress, Typography } from "@material-ui/core"
import { Divider, Autocomplete, TextField, Stack } from "@mui/material"
import { useSelector, useDispatch } from "react-redux"
import dayjs, { Dayjs } from "dayjs"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"

import {
    addISOUpdate,
    setISORefreshKey,
    getAllISOUpdates,
    sendISOUpdateEmail,
    setShowConfirmation
} from "../../actions/isoActions"
import {
    documentTypesMap,
    documentLocationMap,
    initiatorStatementMap
} from "../../config"
import resetHeaders from "../../functions/resetHeaders"
import Alert from "../main/Alert"
import ConfirmationDialog from "../visitor/ConfirmationDialog"
import { getAllRecipients } from "../../actions/adminActions"
import useStyles from "./ISOStyles"

function ISOForm({ onSubmit }) {
    const classes = useStyles()
    const dispatch = useDispatch()

    // authentication store variable
    const loggedIn = useSelector(
        (state) => state.auth.user && state.auth.user.auth
    )

    // state repr for document update info
    const [documentInfo, setDocumentInfo] = useState({
        type: "",
        title: "",
        number: "",
        revision: "",
        effectiveDate: null,
        owner: { id: "", name: "" },
        newLocation: { id: -1, name: "" },
        statement: { id: -1, name: "" }
    })

    const allEmailRecipients = useSelector((state) => state.admin.allRecipients)

    const isoUpdateEmailList = allEmailRecipients
        .filter(
            (elem) => elem.CommunicationFunctionName === "Update Notifications"
        )
        .map((elem) => {
            return elem.RecipientEmail
        })

    // state repr for loading in button
    const [loading, setLoading] = useState(false)

    // confirmation dialog state var
    const [showConfirmation, setShowConfirmation] = useState(false)

    const isoRefreshKey = useSelector((state) => state.iso.isoRefreshKey)

    // error alert message
    const [alertFlag, setAlertFlag] = useState(false)
    const [alertText, setAlertText] = useState("")

    /* Handler for alert message */
    const handleAlertDialog = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setAlertFlag(false)
    }

    // store content of user
    const userFullName = useSelector((state) => state.auth.user.displayName)

    // handler for document type Autocomplete
    const onChangeType = (event, newVal, reason) => {
        if (reason === "clear") {
            setDocumentInfo({ ...documentInfo, type: "" })
        } else {
            setDocumentInfo({ ...documentInfo, type: newVal })
        }
    }

    const onChangeLocation = (event, newVal, reason) => {
        if (reason === "clear") {
            setDocumentInfo({
                ...documentInfo,
                newLocation: { id: -1, name: "" }
            })
        } else {
            setDocumentInfo({
                ...documentInfo,
                newLocation: { id: newVal.id, name: newVal.name }
            })
        }
    }

    const onChangeOwner = (event, newVal, reason) => {
        if (reason === "clear") {
            setDocumentInfo({ ...documentInfo, owner: { id: "", name: "" } })
        } else {
            setDocumentInfo({
                ...documentInfo,
                owner: { id: newVal.id, name: newVal.name }
            })
        }
    }

    const onChangeStatement = (event, newVal, reason) => {
        if (reason === "clear") {
            setDocumentInfo({
                ...documentInfo,
                statement: { id: -1, name: "" }
            })
        } else {
            setDocumentInfo({
                ...documentInfo,
                statement: { id: newVal.id, name: newVal.name }
            })
        }
    }

    // handler for document effective date
    const handleOnChangeDateInputs = (newValue) => {
        if (newValue) {
            setDocumentInfo({ ...documentInfo, effectiveDate: newValue })
        }
    }

    // handler for text fields
    const handleOnChangeText = (event, inputName) => {
        setDocumentInfo({ ...documentInfo, [inputName]: event.target.value })
    }

    const activeEmployees = useSelector(
        (state) => state.visitor.activeEmployees
    )
    const activeEmployeesDropdown = activeEmployees
        .filter(
            (emp) =>
                !emp.FirstName.includes("TEST") &&
                !emp.LastName.includes("TEST")
        )
        .map((emp) => {
            return {
                id: emp.AssociateOID,
                name: emp.FirstName + " " + emp.LastName
            }
        })
        .filter(
            (obj, index, self) =>
                self.map((obj) => obj.name).indexOf(obj.name) === index
        )

    const onClickSubmitButton = async () => {
        // error handling
        if (documentInfo.type === "") {
            setAlertFlag(true)
            setAlertText("Please select a document type.")
            return
        } else if (
            !documentInfo.effectiveDate ||
            isNaN(documentInfo.effectiveDate.getTime())
        ) {
            setAlertFlag(true)
            setAlertText("Please provide a valid date representation.")
            return
        } else if (documentInfo.number === "") {
            setAlertFlag(true)
            setAlertText("Please provide the document number.")
            return
        } else if (documentInfo.title === "") {
            setAlertFlag(true)
            setAlertText("Please provide the document title.")
            return
        } else if (documentInfo.revision === "") {
            setAlertFlag(true)
            setAlertText("Please provide the document revision.")
            return
        } else if (
            documentInfo.newLocation.id === "" ||
            documentInfo.newLocation.id === -1 ||
            documentInfo.newLocation.name === ""
        ) {
            setAlertFlag(true)
            setAlertText("Please select the location of the updated document.")
            return
        } else if (
            documentInfo.owner.id === "" ||
            documentInfo.owner.id === -1 ||
            documentInfo.owner.name === ""
        ) {
            setAlertFlag(true)
            setAlertText("Please select a document owner.")
            return
        } else if (
            documentInfo.statement.id === "" ||
            documentInfo.statement.id === -1 ||
            documentInfo.statement.name === ""
        ) {
            setAlertFlag(true)
            setAlertText("Please select an initiator statement.")
            return
        }
        setShowConfirmation(true)
    }

    const onClickCancelButton = () => {
        setShowConfirmation(false)
    }

    /* Action function for the Confirmation Dialog box, "Confirm" button.
            - adds info to database
            - send company wide email */
    const onClickConfirmButton = async () => {
        // compile and add info to database
        const isoInfoObj = {
            date: documentInfo.effectiveDate,
            type: documentInfo.type,
            number: documentInfo.number,
            title: documentInfo.title,
            revision: documentInfo.revision,
            ownerAOID: documentInfo.owner.id,
            location: documentInfo.newLocation.id,
            statement: documentInfo.statement.id
        }
        const auditInfo = {
            action: "Create ISO Document Update",
            documentDetails:
                documentInfo.number +
                "|" +
                documentInfo.title +
                "|" +
                documentInfo.revision,
            actionBy: userFullName
        }
        const isoObject = { isoInfoObj: isoInfoObj, auditInfo: auditInfo }
        await dispatch(addISOUpdate(isoObject))

        // close dialog
        onSubmit()

        // create object for email content
        const documentInfoObj = {}
        documentInfoObj.recipientList = isoUpdateEmailList

        const docInfo = {
            date: documentInfo.effectiveDate.toLocaleDateString("en-US", {
                timeZone: "America/New_York",
                dateStyle: "medium"
            }),
            type: documentInfo.type,
            number: documentInfo.number,
            title: documentInfo.title,
            revision: documentInfo.revision,
            ownerName: documentInfo.owner.name,
            releaseLocationText: documentInfo.newLocation.name,
            statementId: documentInfo.statement.id
        }
        documentInfoObj.documentInfo = docInfo
        await dispatch(sendISOUpdateEmail(documentInfoObj))

        console.log("added to database")
        await dispatch(getAllISOUpdates())
        await dispatch(setISORefreshKey(isoRefreshKey))
    }

    /* React hook; updates state variables to initialize data from database */
    useEffect(() => {
        if (loggedIn) {
            resetHeaders()
            async function runUseEffectFunctions() {
                await dispatch(getAllRecipients()) // all email recipients for all function types
            }
            runUseEffectFunctions()
        }
    }, [dispatch, loggedIn, allEmailRecipients])

    return (
        <>
            <Grid
                container
                direction="row"
                justify="center"
                className={classes.FormTitle}
            >
                NEW ISO DOCUMENT UPDATE
            </Grid>
            <Divider />

            {!showConfirmation ? (
                <Grid container className={classes.FormFields}>
                    <Grid item xs={7}>
                        <Autocomplete
                            disablePortal
                            options={documentTypesMap}
                            value={documentInfo.type}
                            isOptionEqualToValue={(option, value) =>
                                option === value
                            }
                            onChange={onChangeType}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Document Type"
                                    size="small"
                                    variant="standard"
                                    sx={{ maxWidth: "200px" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                disablePast
                                label="Effective Date"
                                value={dayjs(documentInfo.effectiveDate)}
                                onChange={(val, context) =>
                                    handleOnChangeDateInputs(val?.$d)
                                }
                                slotProps={{
                                    textField: {
                                        variant: "standard",
                                        error: false
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <br />
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            label="Document Number"
                            size="small"
                            variant="standard"
                            value={documentInfo.number}
                            sx={{ maxWidth: "150px" }}
                            onChange={(e) => handleOnChangeText(e, "number")}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            label="Document Title"
                            size="small"
                            variant="standard"
                            value={documentInfo.title}
                            sx={{ maxWidth: "250px" }}
                            onChange={(e) => handleOnChangeText(e, "title")}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label="Revision"
                            size="small"
                            variant="standard"
                            value={documentInfo.revision}
                            sx={{ maxWidth: "90px" }}
                            onChange={(e) => handleOnChangeText(e, "revision")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <br />
                        <br />
                    </Grid>
                    <Grid item xs={7}>
                        <Autocomplete
                            disablePortal
                            options={documentLocationMap}
                            getOptionLabel={(option) => option.name}
                            key={(option) => option.id}
                            value={documentInfo.newLocation}
                            isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                            }
                            onChange={onChangeLocation}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="New Document Location"
                                    size="small"
                                    variant="standard"
                                    sx={{ maxWidth: "230px" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Autocomplete
                            disablePortal
                            options={activeEmployeesDropdown}
                            getOptionLabel={(option) => option.name}
                            key={(option) => option.id}
                            value={documentInfo.owner}
                            isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                            }
                            onChange={onChangeOwner}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Document Owner"
                                    size="small"
                                    variant="standard"
                                    sx={{ maxWidth: "200px" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <br />
                        <br />
                    </Grid>
                    <Grid item xs={12}>
                        {/* statement autocomplete */}
                        <Autocomplete
                            disablePortal
                            options={initiatorStatementMap}
                            getOptionLabel={(opt) => opt.name}
                            key={(option) => option.id}
                            value={documentInfo.statement}
                            isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                            }
                            onChange={onChangeStatement}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Initiator Statement"
                                    size="small"
                                    variant="standard"
                                    sx={{ maxWidth: "500px" }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid container item md={12} justifyContent="center">
                        {/* Alerts pop-up area */}
                        <Grid item>
                            <br />
                            {alertFlag ? (
                                <Alert
                                    onClose={handleAlertDialog}
                                    severity="error"
                                >
                                    {alertText}
                                </Alert>
                            ) : (
                                <Grid item xs={12}>
                                    <br />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center">
                        {/* submit button */}
                        <Button
                            variant="outlined"
                            color="secondary"
                            size="medium"
                            className={classes.SaveButton}
                            disabled={loading}
                            onClick={onClickSubmitButton}
                        >
                            <Stack>
                                <Typography>SAVE AND EMAIL</Typography>
                                {loading && <LinearProgress />}
                            </Stack>
                        </Button>
                    </Grid>
                </Grid>
            ) : (
                <Grid className={classes.ConfirmationGrid}>
                    <ConfirmationDialog
                        messageConfirm={"Are you sure you want to proceed?"}
                        messageEmail={
                            "Please verify all information supplied is accurate. Saving this form will send a company-wide announcement email."
                        }
                        messageEmail2={isoUpdateEmailList.join(", ")}
                        title={"SAVE AND EMAIL ?"}
                        onConfirm={onClickConfirmButton}
                        onCancel={onClickCancelButton}
                    />
                </Grid>
            )}

            {/* Confirmation dialog component to ensure intentional form save  */}
            {/* <Dialog
                open={openConfirm}
                maxWidth="sm"
                fullWidth={true}
                onClose={handleClose}
            >
                <ConfirmationDialog
                    messageConfirm={"Are you sure you want to proceed?"}
                    messageEmail={
                        "Please verify all information supplied is accurate. Saving this form will send a company-wide announcement email."
                    }
                    messageEmail2={isoUpdateEmailList.join(", ")}
                    title={"SAVE AND EMAIL ?"}
                    onConfirm={onClickConfirmButton}
                    onCancel={handleClose}
                />
            </Dialog> */}
        </>
    )
}

export default ISOForm
