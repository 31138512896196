import axios from "../auth/axiosAuth"
import { backendURL, unprotectedToken } from "../config"
import {
    GET_ALL_STORED_VISITS,
    SET_SELECTED_VISIT_ID,
    GET_VISIT_HOST_BY_VISIT_ID,
    GET_VISIT_CONF_ROOM_BY_VISIT_ID,
    GET_VISIT_VISITORS_BY_VISIT_ID,
    UPDATE_VISIT_ACTIVE,
    GET_VISIT_BY_VISIT_ID,
    GET_REMINDERS_BY_VISIT_ID,
    UPDATE_REMINDER_LIST,
    ADD_VISIT_REMINDERS,
    DELETE_VISIT_REMINDERS,
    SET_OLD_VISIT_START,
    SET_REFRESH_KEY,
    GET_REMINDERS_DATE,
    SET_VISIT_START,
    SET_VISIT_END,
    ADD_IMM_REMINDER_AUDIT
} from "./types"

/* Action function that obtains an array of all saved visitor announcements 
    (top level; only Visit table) */
export const getAllStoredVisits = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/visit`)
    dispatch({ type: GET_ALL_STORED_VISITS, payload: res.data })
}

/* Action function that obtains the Visit record by visitId */
export const getVisitById = (visitId) => async (dispatch) => {
    const res = await axios.get(`${backendURL}/visit/${visitId}`)
    dispatch({ type: GET_VISIT_BY_VISIT_ID, payload: res.data })
}

/* Action function to set the "visitId" field stored in the Redux store.
    (currently viewing visit entry) */
export const setSelectedVisitId = (visitId) => (dispatch) => {
    dispatch({ type: SET_SELECTED_VISIT_ID, payload: visitId })
}

/* Action function to set the "oldVisitStart" field stored in the Redux store.
    (to detect if reminders need modifying) */
export const setOldVisitStart = (oldStartDateTime) => (dispatch) => {
    dispatch({ type: SET_OLD_VISIT_START, payload: oldStartDateTime })
}

/* Action function to set the "visitStartDateTime" field stored in the Redux store.
    (to copy over into conference room area of form) */
export const setVisitStart = (visitStartDateTime) => (dispatch) => {
    dispatch({ type: SET_VISIT_START, payload: visitStartDateTime })
}

/* Action function to set the "visitEndDateTime" field stored in the Redux store.
    (to copy over into conference room area of form) */
export const setVisitEnd = (visitEndDateTime) => (dispatch) => {
    dispatch({ type: SET_VISIT_END, payload: visitEndDateTime })
}

/* Action function to set the "refreshKey" field stored in the Redux store.
    (to force re-renders when data changes) */
export const setRefreshKey = (oldRefreshKey) => (dispatch) => {
    dispatch({ type: SET_REFRESH_KEY, payload: oldRefreshKey + 1 })
}

/* Action function that adds list of reminders (from dialog) to database. */
export const addVisitReminders =
    (allIds, allReminders, auditObject) => async (dispatch) => {
        const visitId = allIds.visitId
        const commTypeId = allIds.commTypeId
        const functionId = allIds.functionId

        // build array of record objects (fields = columns)
        const reminderRecords = allReminders.map((reminder) => {
            return {
                communicationTypeId: commTypeId,
                communicationId: visitId,
                sendDateTime: reminder,
                communicationFunctionId: functionId,
                sent: false
            }
        })
        //console.log(reminderRecords)
        const reminderObject = {
            visitId: visitId,
            reminderRecords: reminderRecords,
            auditObject: auditObject
        }
        await axios.post(`${backendURL}/reminder/visit`, reminderObject)
        dispatch({ type: ADD_VISIT_REMINDERS })
    }

/* Action function that delete all reminders, with matching visitId, in 
    database table. */
export const deleteVisitRemindersByVisitId = (visitId) => async (dispatch) => {
    await axios.delete(`${backendURL}/reminder/visit/${visitId}`)
    dispatch({ type: DELETE_VISIT_REMINDERS })
}

/* Action function that obtains the list of visit hosts (for given visitId). */
export const getVisitHosts = (visitId, activeEmployees) => async (dispatch) => {
    const res = await axios.get(`${backendURL}/host/visit/${visitId}`)
    const formattedData = res.data.map((host) => {
        const associateId = host.HostAssId
        const emp = activeEmployees.find(
            (employee) => employee.AssociateOID === associateId
        )
        return { id: associateId, name: emp?.FirstName + " " + emp?.LastName }
    })

    dispatch({ type: GET_VISIT_HOST_BY_VISIT_ID, payload: formattedData })
}

/* Action function that obtains the list of visit conference rooms 
    (for given visitId) */
export const getVisitConfRooms = (visitId) => async (dispatch) => {
    const res = await axios.get(
        `${backendURL}/visitconferenceroom/visit/${visitId}`
    )
    dispatch({ type: GET_VISIT_CONF_ROOM_BY_VISIT_ID, payload: res.data })
}

/* Action function that obtains the list of visit visitors (for given visitId) */
export const getVisitVisitors = (visitId) => async (dispatch) => {
    const res = await axios.get(`${backendURL}/visitor/visit/${visitId}`)
    dispatch({ type: GET_VISIT_VISITORS_BY_VISIT_ID, payload: res.data })
}

/* Action function that updates the "active" field in the record in Visit table, 
    with specified visitId, to specified boolean value (activity). */
export const updateVisitActive =
    (visitId, activity, auditObject) => async (dispatch) => {
        const visitInfoToSend = {
            visitId: visitId,
            active: activity,
            auditObj: auditObject
        }
        const res = await axios.put(`${backendURL}/visit`, visitInfoToSend)
        dispatch({ type: UPDATE_VISIT_ACTIVE, payload: res.data })
    }

/* Action function to add record into AuditLog database table when user
    sends immediate reminder email. */
export const addImmReminderAudit = (auditObject) => async (dispatch) => {
    const res = await axios.post(
        `${backendURL}/reminder/immediate-log`,
        auditObject
    )
    dispatch({ type: ADD_IMM_REMINDER_AUDIT, payload: res.data })
}

/* Action function to update reminder list in store, by replacing old list
    with new list. */
export const updateReminderList = (newReminderList) => (dispatch) => {
    dispatch({ type: UPDATE_REMINDER_LIST, payload: newReminderList })
}

/* Action function that obtains the list of reminders that are stored 
    (for the given visitId) */
export const getVisitReminders = (visitId, visitStart) => async (dispatch) => {
    const res = await axios.get(`${backendURL}/reminder/visit/${visitId}`)

    const reminderState = { week: false, day: false, custom: [] }

    // one-week from visit start
    const weekFrom = new Date(visitStart)
    weekFrom.setDate(weekFrom.getDate() - 7)

    // one-day from visit start
    const dayFrom = new Date(visitStart)
    dayFrom.setDate(dayFrom.getDate() - 1)

    if (res && res.data && res.data.length > 0) {
        res.data.forEach((elem) => {
            const dateElem = new Date(elem.SendDateTime)
            if (dateElem.toString() === weekFrom.toString()) {
                reminderState.week = true
            } else if (dateElem.toString() === dayFrom.toString()) {
                reminderState.day = true
            } else {
                reminderState.custom.push(dateElem)
            }
        })
    }

    Promise.resolve(reminderState) // attempt to merge async + sync operations

    dispatch({ type: GET_REMINDERS_BY_VISIT_ID, payload: reminderState })
}

/* Action function to get the Reminder records, without altering or modifying format. */
export const getRemindersDate = (visitId) => async (dispatch) => {
    const res = await axios.get(`${backendURL}/reminder/visit/${visitId}`)

    dispatch({ type: GET_REMINDERS_DATE, payload: res.data })
}

/* Action function to modify any suggested reminders, in the case where visit start changes. */
export const getReplaceVisitReminders =
    (visitId, oldStartDateTime, newStartDateTime) => async (dispatch) => {
        const res = await axios.get(`${backendURL}/reminder/visit/${visitId}`)

        // toDelete : array of ReminderId's ; toAdd : array of records (obj)
        const replaceObject = { visitId: visitId, toAdd: [], toDelete: [] }

        // old one-week toggle to date str
        const oldWeekFrom = new Date(oldStartDateTime)
        oldWeekFrom.setDate(oldWeekFrom.getDate() - 7)

        // old one-day toggle to date str
        const oldDayFrom = new Date(oldStartDateTime)
        oldDayFrom.setDate(oldDayFrom.getDate() - 1)

        if (res && res.data && res.data.length > 0) {
            res.data.forEach((elem) => {
                const dateElem = new Date(elem.SendDateTime)
                if (dateElem.toString() === oldWeekFrom.toString()) {
                    // add record id to toDelete
                    const weekReminderId = elem.ReminderId
                    replaceObject.toDelete.push(weekReminderId)

                    // build new one-week record, insert
                    const newWeekFrom = new Date(newStartDateTime)
                    newWeekFrom.setDate(newWeekFrom.getDate() - 7)

                    const newWeekRecord = {
                        CommunicationTypeId: elem.CommunicationTypeId,
                        CommunicationId: elem.CommunicationId,
                        SendDateTime: newWeekFrom,
                        CommunicationFunctionId: elem.CommunicationFunctionId,
                        Sent: elem.Sent
                    }
                    replaceObject.toAdd.push(newWeekRecord)
                } else if (dateElem.toString() === oldDayFrom.toString()) {
                    // add record id to toDelete
                    const dayReminderId = elem.ReminderId
                    replaceObject.toDelete.push(dayReminderId)

                    // build new one-day record, insert
                    const newDayFrom = new Date(newStartDateTime)
                    newDayFrom.setDate(newDayFrom.getDate() - 1)
                    //console.log(`new day is ${newDayFrom}`)

                    const newDayRecord = {
                        CommunicationTypeId: elem.CommunicationTypeId,
                        CommunicationId: elem.CommunicationId,
                        SendDateTime: newDayFrom,
                        CommunicationFunctionId: elem.CommunicationFunctionId,
                        Sent: elem.Sent
                    }
                    replaceObject.toAdd.push(newDayRecord)
                }
            })
        }
        await axios.post(`${backendURL}/reminder/replace`, replaceObject)
    }

/* Action function that sends the request to backend application to send a cancellation
    notification email to the designated list of recipients, building the HTML string repr. of the email. */
export const sendCancelEmail =
    (recipientList, visitInfoObj) => async (dispatch) => {
        const cancelInfoObj = {}
        cancelInfoObj.recipientList = recipientList
        cancelInfoObj.visitInfoObj = visitInfoObj
        cancelInfoObj.headers = { token: unprotectedToken }
        console.log(cancelInfoObj, unprotectedToken)

        await axios.post(`${backendURL}/send-email/cancel`, cancelInfoObj)
        return
    }
