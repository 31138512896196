import { React, useState } from "react"
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    useMediaQuery,
    LinearProgress
} from "@material-ui/core"
import { Stack } from "@mui/material"

import useStyles from "./styles/VisitorStyles"

/* Component that represents a confirmation popup dialog window, ensuring user actions
    were intentional. Message texts are supplied through props. */
function ConfirmationDialog({
    messageConfirm,
    messageEmail,
    messageEmail2,
    messageEmail3,
    title,
    onConfirm,
    onCancel,
    loading
}) {
    const classes = useStyles()

    // constant for conditional styling adjustments due to screen size (px)
    const isPhoneLandscape = useMediaQuery(
        "(max-width:844px) and (orientation: landscape)"
    )

    /* Placeholder handler function to be substituted for actual function on runtime. */
    const handleConfirm = () => {
        onConfirm()
    }

    /* Placeholder handler function to be substituted for actual function on runtime. */
    const handleCancel = () => {
        onCancel()
    }

    return (
        <>
            <DialogTitle
                className={
                    isPhoneLandscape ? classes.ConfirmationDialogLandscape : ""
                }
            >
                <Typography align="center" variant="h5">
                    <b>{title}</b>
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography align="center" className={classes.DeleteConfirmMsg}>
                    {messageConfirm}
                </Typography>
                <br />
                <Typography align="center" className={classes.DeleteEmailMsg}>
                    {messageEmail}
                </Typography>

                {messageEmail3 && messageEmail3.length > 0 ? (
                    <>
                        <Typography
                            align="center"
                            className={classes.DeleteEmailMsg}
                        >
                            {messageEmail3}
                        </Typography>
                    </>
                ) : (
                    ""
                )}
                {messageEmail2 && messageEmail2.length > 0 ? (
                    <>
                        <br />
                        <Typography
                            align="center"
                            className={classes.DeleteEmailDisplay}
                        >
                            Emails will be sent to <i>{messageEmail2}</i>.
                        </Typography>
                        <br />
                    </>
                ) : (
                    ""
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="inherit"
                    onClick={handleConfirm}
                    disabled={loading}
                    className={
                        loading ? classes.DisabledDeleteConfirmButton : ""
                    }
                >
                    <Stack>
                        <Typography>CONFIRM</Typography>
                        {loading && <LinearProgress />}
                    </Stack>
                </Button>
                <Button
                    variant="outlined"
                    color="inherit"
                    onClick={handleCancel}
                    disabled={loading}
                    className={
                        loading ? classes.DisabledDeleteConfirmButton : ""
                    }
                >
                    <Stack>
                        <Typography>CANCEL</Typography>
                    </Stack>
                </Button>
            </DialogActions>
        </>
    )
}

export default ConfirmationDialog
