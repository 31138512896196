import React from "react"

import useStyles from "./styles/VisitorStyles"

/* Component for displaying a JS date object as a calendar-day card. */
function CalendarIcon({ dateObj }) {
    const classes = useStyles()

    const dayOfMonth = dateObj.getDate()
    const month = dateObj.toLocaleString("default", { month: "short" })
    const year = dateObj.toLocaleString("default", { year: "numeric" })

    return (
        <div className={classes.CalendarBox}>
            <div className={classes.CalendarDate}>{dayOfMonth}</div>
            <div className={classes.CalendarMonth}>{month}</div>
            <div className={classes.CalendarYear}>{year}</div>
        </div>
    )
}

export default CalendarIcon
