import { React, useState, useEffect } from "react"
import {
    Grid,
    Button,
    Typography,
    TextField,
    useTheme,
    useMediaQuery,
    makeStyles
} from "@material-ui/core"
import { useSelector, useDispatch } from "react-redux"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import { Autocomplete, TextField as TF, IconButton } from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs, { Dayjs } from "dayjs"
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers"
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined"
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation"

import useStyles from "./styles/VisitorStyles"
import { updateConfRoomList } from "../../actions/visitorFormActions"
import Alert from "../main/Alert"

/* Component that defines the form area to input conference room details relating to the
    specific visit. */
function ConferenceRoomBox({ visitStart, visitEnd, mode }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const theme = useTheme()

    const visitStartDateTime = useSelector(
        (state) => state.visitFeed.visitStartDateTime
    )
    const visitEndDateTime = useSelector(
        (state) => state.visitFeed.visitEndDateTime
    )

    // constants for conditional styling adjustments due to screen size (px)
    const isMediumScreen = useMediaQuery(theme.breakpoints.down("1040"))
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"))
    const isSmallPhone = useMediaQuery(theme.breakpoints.down("400"))

    // list of all active conference rooms loaded from database (store)
    const dbConfRooms = useSelector((state) => state.visitor.activeConfRooms)

    // state representation of conference room information entry
    const [confRoomInfo, setConfRoomInfo] = useState({
        name: "",
        fromDateTime: null,
        toDateTime: null,
        id: ""
    })

    /* EDIT mode: get original conf room list and manipulate data to match state
        defined variables */
    const currentConfRoomList = useSelector(
        (state) => state.visitFeed.currentVisitConfRooms
    )
    let formatConfRoomList
    if (mode === "edit") {
        if (currentConfRoomList?.length > 0) {
            formatConfRoomList = currentConfRoomList.map((room) => {
                const start = new Date(room.StartDateTime)
                const end = new Date(room.EndDateTime)
                const id = room.ConferenceRoomId
                const roomObj = dbConfRooms.find(
                    (conf) => conf.ConferenceRoomId === id
                )

                return {
                    name: roomObj?.ConferenceRoomName,
                    fromDateTime: start,
                    toDateTime: end,
                    id: id
                }
            })
        } else {
            formatConfRoomList = []
        }
    }

    // state representation of (dynamic) list of conference room entries from form entry
    const [confRoomList, setConfRoomList] = useState(
        mode === "create" ? [] : formatConfRoomList
    )

    // list of all conference room entries from store
    const allConfRooms = useSelector((state) => state.visitor.allConfRooms)

    // error alert message
    const [alertFlag, setAlertFlag] = useState(false)
    const [alertText, setAlertText] = useState("")

    // array of active conference rooms formatted for Autocomplete dropdown ({id: ..., name: ...})
    const conferenceRoomDropDown = dbConfRooms.map((room) => {
        return {
            id: room.ConferenceRoomId,
            name:
                room.ConferenceRoomName +
                " [" +
                room.ConferenceRoomLocation +
                "]"
        }
    })
    conferenceRoomDropDown.splice(0, 0, { id: "", name: "" })

    /* Handler to clear state representation of conference room input boxes */
    const clearConfRoomInput = () => {
        setConfRoomInfo({
            name: "",
            fromDateTime: null,
            toDateTime: null,
            id: ""
        })
    }

    /* Handler for "ADD" button to add conference room entry to state representation of list,
        then updates conference room entries list in store */
    const handleClickAdd = async () => {
        // error checks relies on visit start and end times
        if (
            !visitStart ||
            !visitEnd ||
            isNaN(visitStart.getTime()) ||
            isNaN(visitEnd.getTime())
        ) {
            setAlertFlag(true)
            setAlertText(
                "Please first provide valid visit start and end date/time."
            )
            return
        }
        // check for valid date object
        if (
            !confRoomInfo.fromDateTime ||
            !confRoomInfo.toDateTime ||
            isNaN(confRoomInfo.fromDateTime.getTime()) ||
            isNaN(confRoomInfo.toDateTime.getTime())
        ) {
            setAlertFlag(true)
            setAlertText("Please enter a valid date and time.")
            return
        } // check for valid date inputs
        else if (confRoomInfo.fromDateTime >= confRoomInfo.toDateTime) {
            setAlertFlag(true)
            setAlertText("Please provide a valid date and time interval.")
            return
        } // time range must be between visit date and time range
        else if (
            !(
                visitStart <= confRoomInfo.fromDateTime &&
                confRoomInfo.fromDateTime <= visitEnd &&
                visitStart <= confRoomInfo.toDateTime &&
                confRoomInfo.toDateTime <= visitEnd
            )
        ) {
            setAlertFlag(true)
            setAlertText(
                "Please provide a date and time interval within the visit range."
            )
            return
        }
        // check for duplicates
        else if (
            filteredConfRoom(confRoomInfo).length !== confRoomList.length
        ) {
            setAlertFlag(true)
            setAlertText(
                "It looks like this conference room entry already exists."
            )
            return
        } // check for blank inputs
        else if (confRoomInfo.name !== "") {
            confRoomList.push(confRoomInfo)
            setConfRoomList(confRoomList)
            await dispatch(updateConfRoomList(confRoomList))
            setAlertFlag(false)
        }
        // clear input boxes
        clearConfRoomInput()
    }

    /* Function that filters an array of conference-room-detail objects, returning
        final array that does not include input parameter object (match data) */
    const filteredConfRoom = (roomObj) => {
        const filteredConf = confRoomList.filter(
            (elem) =>
                roomObj.id !== elem.id ||
                roomObj.fromDateTime.toLocaleString([], {
                    dateStyle: "medium",
                    timeStyle: "short"
                }) !==
                    elem.fromDateTime.toLocaleString([], {
                        dateStyle: "medium",
                        timeStyle: "short"
                    }) ||
                roomObj.toDateTime.toLocaleString([], {
                    dateStyle: "medium",
                    timeStyle: "short"
                }) !==
                    elem.toDateTime.toLocaleString([], {
                        dateStyle: "medium",
                        timeStyle: "short"
                    })
        )
        return filteredConf
    }

    /* Handler for "x" delete conference room entry button */
    const handleDelete = async (room) => {
        let removedConfRooms = filteredConfRoom(room)
        setConfRoomList(removedConfRooms)
        await dispatch(updateConfRoomList(removedConfRooms))
    }

    /* Handler for all changes in dropdown Autocomplete field */
    const onChangeConfRoom = (event, newVal, reason) => {
        if (reason === "clear") {
            clearConfRoomInput()
        } else {
            setConfRoomInfo({
                ...confRoomInfo,
                name: newVal.name,
                id: newVal.id
            })
        }
    }

    /* Handler for all changes in date-time picker fields */
    const onChangeDateInputs = (val, name) => {
        setConfRoomInfo({ ...confRoomInfo, [name]: val })
    }

    /* Handler for alert message */
    const handleAlertDialog = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setAlertFlag(false)
        //clearConfRoomInput()
    }

    /* React hook; updates state repr. with list from store */
    useEffect(() => {
        async function runUseEffectFunctions() {
            // on component render, set state variables to saved data
            if (mode === "edit" && formatConfRoomList) {
                await dispatch(updateConfRoomList(formatConfRoomList))
            }
        }
        runUseEffectFunctions()
    }, [dispatch])

    return (
        <>
            <Grid item container className={classes.HostInner}>
                {/* Case on number of Conference Room info entries, to display */}
                {allConfRooms.length !== 0 ? (
                    allConfRooms?.map((room, index) => {
                        return (
                            <Grid item container justify="center" key={index}>
                                <Grid
                                    item
                                    xs={3}
                                    sm={3}
                                    md={4}
                                    align="center"
                                    className={classes.ConfRoomEntry}
                                >
                                    {room.name}
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                    sm={3}
                                    md={3}
                                    align="center"
                                    className={classes.ConfRoomEntry}
                                >
                                    {isMediumScreen
                                        ? room.fromDateTime.toLocaleString([], {
                                              dateStyle: "short",
                                              timeStyle: "short"
                                          })
                                        : room.fromDateTime.toLocaleString([], {
                                              dateStyle: "medium",
                                              timeStyle: "short"
                                          })}
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                    sm={3}
                                    md={3}
                                    align="center"
                                    className={classes.ConfRoomEntry}
                                >
                                    {isMediumScreen
                                        ? room.toDateTime.toLocaleString([], {
                                              dateStyle: "short",
                                              timeStyle: "short"
                                          })
                                        : room.toDateTime.toLocaleString([], {
                                              dateStyle: "medium",
                                              timeStyle: "short"
                                          })}
                                </Grid>
                                <Grid item xs={2} sm={2} md={2} align="center">
                                    <IconButton
                                        color="inherit"
                                        onClick={(e) => handleDelete(room)}
                                    >
                                        <HighlightOffIcon fontSize="small" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        )
                    })
                ) : (
                    <Grid item xs={12}>
                        <Typography
                            align="center"
                            className={classes.NoVisitors}
                        >
                            No conference rooms added.
                        </Typography>
                    </Grid>
                )}

                {/* Alerts pop-up area */}
                <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    justify="flex-end"
                >
                    <Grid item>
                        {alertFlag && (
                            <Alert onClose={handleAlertDialog} severity="error">
                                {alertText}
                            </Alert>
                        )}
                    </Grid>
                </Grid>

                {/* Conference room selector dropdown component */}
                <Grid
                    item
                    xs={3}
                    sm={3}
                    md={4}
                    className={classes.VisitorBoxInputs}
                >
                    <Autocomplete
                        disablePortal
                        size="small"
                        options={conferenceRoomDropDown}
                        getOptionLabel={(roomObject) => roomObject.name}
                        value={confRoomInfo}
                        isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                        }
                        onChange={onChangeConfRoom}
                        renderInput={(params) => (
                            <TF
                                {...params}
                                label="Name"
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    classes: {
                                        notchedOutline: classes.notchedOutline
                                    },
                                    className: classes.TextColor
                                }}
                                InputLabelProps={{
                                    ...params.InputLabelProps,
                                    className: classes.floatingLabelFocusStyle
                                }}
                            />
                        )}
                    />
                </Grid>

                {/* Conference Room Date/Time (Begin) input selector component */}
                <Grid
                    item
                    xs={3}
                    sm={3}
                    md={3}
                    className={classes.VisitorBoxInputs}
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            value={dayjs(confRoomInfo.fromDateTime)}
                            label="FROM"
                            minDate={visitStart ? dayjs(visitStart) : null}
                            maxDate={visitEnd ? dayjs(visitEnd) : null}
                            onChange={(val) =>
                                onChangeDateInputs(val?.$d, "fromDateTime")
                            }
                            slots={{
                                textField: TextField,
                                openPickerIcon: InsertInvitationIcon
                            }}
                            slotProps={{
                                textField: {
                                    variant: "standard",
                                    error: false,
                                    className: classes.CalendarIconColor
                                },
                                openPickerIcon: {
                                    className: classes.TextColor
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>

                {/* Conference Room Date/Time (End) input selector component */}
                <Grid
                    item
                    xs={3}
                    sm={3}
                    md={3}
                    className={classes.VisitorBoxInputs}
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            value={dayjs(confRoomInfo.toDateTime)}
                            label="TO"
                            minDate={visitStart ? dayjs(visitStart) : null}
                            maxDate={visitEnd ? dayjs(visitEnd) : null}
                            onChange={(val) =>
                                onChangeDateInputs(val?.$d, "toDateTime")
                            }
                            slots={{
                                textField: TextField,
                                openPickerIcon: InsertInvitationIcon
                            }}
                            slotProps={{
                                textField: {
                                    variant: "standard",
                                    error: false,
                                    className: classes.CalendarIconColor
                                },
                                openPickerIcon: {
                                    className: classes.TextColor
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>

                {/* "ADD" button to add entered Conference Room info entry to list in area */}
                <Grid
                    item
                    xs={2}
                    sm={2}
                    md={1}
                    align="center"
                    className={
                        isSmallScreen
                            ? classes.AddConfButtonSmall
                            : classes.AddHostButton
                    }
                >
                    {isSmallPhone ? (
                        <IconButton color="warning" onClick={handleClickAdd}>
                            <AddBoxOutlinedIcon fontSize="medium" />
                        </IconButton>
                    ) : (
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleClickAdd}
                        >
                            ADD
                        </Button>
                    )}
                </Grid>
            </Grid>
        </>
    )
}

export default ConferenceRoomBox
