import { React, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Tooltip,
    TextField
} from "@mui/material"
import { Typography, Grid, Button } from "@material-ui/core"

import useStyles from "./ISOStyles"
import { documentLocationMap, initiatorStatementMap } from "../../config"

function DocumentItemDialog({ documentObj }) {
    const classes = useStyles()

    const allEmployees = useSelector((state) => state.iso.allEmployees)

    const newLocationObj = documentLocationMap.filter(
        (loc) => loc.id === documentObj.ReleaseLocation
    )[0]

    const documentOwnerObj = allEmployees.filter(
        (emp) => emp.AssociateOID === documentObj.DocumentOwnerAOID
    )[0]

    const initiatorStatementObj = initiatorStatementMap.filter(
        (statement) => statement.id === documentObj.InitiatorStatement
    )[0]

    return (
        <>
            <DialogTitle>
                <Typography
                    align="center"
                    variant="h6"
                    className={classes.DetailsTitle}
                >
                    ISO DOCUMENT DETAILS
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sm={3}
                        className={classes.DocumentDetailsTextfield}
                    >
                        <TextField
                            value={documentObj.DateImplemented.toString().substring(
                                0,
                                10
                            )}
                            variant="outlined"
                            label="Effective Date"
                            size="small"
                            sx={{ maxWidth: "120px" }}
                            inputProps={{
                                readOnly: true
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        className={classes.DocumentDetailsTextfield}
                    >
                        <TextField
                            value={documentObj.DocumentType}
                            variant="outlined"
                            label="Document Type"
                            size="small"
                            sx={{ maxWidth: "190px" }}
                            inputProps={{
                                readOnly: true
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        className={classes.DocumentDetailsTextfield}
                    >
                        <TextField
                            value={documentObj.DocumentNumber}
                            variant="outlined"
                            label="Document Number"
                            size="small"
                            sx={{
                                "& .MuiInputBase-input": {
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                },
                                width: "173px"
                            }}
                            inputProps={{
                                readOnly: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <br />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        className={classes.DocumentDetailsTextfield}
                    >
                        <TextField
                            value={documentObj.DocumentTitle}
                            variant="outlined"
                            label="Document Title"
                            sx={{
                                "& .MuiInputBase-input": {
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                },
                                width: "300px"
                            }}
                            size="small"
                            inputProps={{
                                readOnly: true
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={3}
                        className={classes.DocumentDetailsTextfield}
                    >
                        <TextField
                            value={documentObj.DocumentRevision}
                            variant="outlined"
                            label="Document Revision"
                            size="small"
                            sx={{ width: "140px" }}
                            inputProps={{
                                readOnly: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <br />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        className={classes.DocumentDetailsTextfield}
                    >
                        <TextField
                            value={newLocationObj.name}
                            variant="outlined"
                            label="New Document Location"
                            size="small"
                            sx={{ width: "250px" }}
                            multiline={true}
                            minRows={2}
                            inputProps={{
                                readOnly: true
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={5}
                        className={classes.DocumentDetailsTextfieldOwner}
                    >
                        <TextField
                            value={
                                documentOwnerObj.FirstName +
                                " " +
                                documentOwnerObj.LastName
                            }
                            variant="outlined"
                            label="Document Owner"
                            size="small"
                            sx={{
                                "& .MuiInputBase-input": {
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }
                            }}
                            inputProps={{
                                readOnly: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <br />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        className={classes.DocumentDetailsTextfield}
                    >
                        <TextField
                            value={initiatorStatementObj.name}
                            variant="outlined"
                            label="Initiator Statement"
                            size="small"
                            sx={{ width: "480px" }}
                            multiline={true}
                            minRows={2}
                            inputProps={{
                                readOnly: true
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </>
    )
}

export default DocumentItemDialog
