import axios from "../auth/axiosAuth"
import { backendURL } from "../config"
import {
    ADD_FUNCTION_RECIPIENT,
    DELETE_FUNCTION_RECIPIENT,
    GET_ALL_FUNCTION_RECIPIENTS,
    SET_SELECTED_FUNCTION,
    GET_ALL_FUNCTIONS,
    GET_ALL_COMM_TYPES
} from "./types"

/* Action function to set the "selectedFunction" field stored 
    in the Redux store. */
export const setSelectedFunction = (selectedFunction) => (dispatch) => {
    dispatch({ type: SET_SELECTED_FUNCTION, payload: selectedFunction })
}

/* Action function to query database for array of all communication types. */
export const getAllCommTypes = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/communicationtypes`)
    dispatch({ type: GET_ALL_COMM_TYPES, payload: res.data })
}

/* Action function to query database for array of all function types. */
export const getAllFunctionTypes = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/functions`)
    dispatch({ type: GET_ALL_FUNCTIONS, payload: res.data })
}

/* Action function to obtain all the email recipients for all functions. */
export const getAllRecipients = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/recipients`)
    dispatch({ type: GET_ALL_FUNCTION_RECIPIENTS, payload: res.data })
}

/* Action function that creates a new record for a function recipient and 
    inserts into table. */
export const addRecipient =
    (functionId, email, auditObject) => async (dispatch) => {
        const recipientObj = {
            "FunctionId": functionId,
            "RecipientEmail": email,
            "auditObject": auditObject
        }

        await axios.post(`${backendURL}/recipients`, recipientObj)
        dispatch({ type: ADD_FUNCTION_RECIPIENT })
    }

/* Action function that deletes a record for a function recipient. */
export const deleteRecipient =
    (functionId, email, auditObj) => async (dispatch) => {
        const recipientObj = { "recipientEmail": email, auditObject: auditObj }

        await axios.delete(`${backendURL}/recipients/${functionId}`, {
            data: recipientObj
        })
        dispatch({ type: DELETE_FUNCTION_RECIPIENT })
    }
