import {
    UPDATE_VISITOR_LIST,
    UPDATE_HOST_LIST,
    UPDATE_CONF_ROOM_LIST,
    GET_ALL_ACTIVE_EMPLOYEES,
    ADD_VISIT_INFO,
    GET_ALL_CONFERENCE_ROOMS,
    GET_ALL_FIRM_TYPES,
    GET_ALL_STORED_VISITS,
    UPDATE_VISIT_INFO
} from "../actions/types"

const initialState = {
    allVisitors: [],
    allHosts: [],
    allConfRooms: [],
    activeEmployees: [],
    activeConfRooms: [],
    firmTypes: [],
    allStoredVisits: []
}

const visitorFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_VISITOR_LIST:
            return { ...state, allVisitors: action.payload }
        case UPDATE_HOST_LIST:
            return { ...state, allHosts: action.payload }
        case UPDATE_CONF_ROOM_LIST:
            return { ...state, allConfRooms: action.payload }
        case GET_ALL_ACTIVE_EMPLOYEES:
            return { ...state, activeEmployees: action.payload }
        case ADD_VISIT_INFO:
            return { ...state }
        case UPDATE_VISIT_INFO:
            return { ...state }
        case GET_ALL_FIRM_TYPES:
            return { ...state, firmTypes: action.payload }
        case GET_ALL_CONFERENCE_ROOMS:
            return { ...state, activeConfRooms: action.payload }
        case GET_ALL_STORED_VISITS:
            return { ...state, allStoredVisits: action.payload }
        default:
            return state
    }
}

export default visitorFormReducer
