import dotenv from "dotenv"
dotenv.config()

let APPLICATION_ID
let backendURL
let appAdminBackendURL
let firebaseConfig
let loginURL
let emailServerURL
let unprotectedToken

// add environment variable that pulls from react app env
const environment = process.env.REACT_APP_ENV
const homeURL = "https://mingle-portal.inforcloudsuite.com/PRECIPART_PRD"

// constants for dropdown in "ISO Regulatory Update" module
const documentTypesMap = ["Procedure", "Work Instruction", "Form"]
const documentLocationMap = [
    { id: 0, name: "One Precipart ISO Book (Precipart Application Portal)" },
    { id: 1, name: "ADP Company Information/Company Policies" }
]
const initiatorStatementMap = [
    {
        id: 0,
        name: `A TRAINING RECORD is required for this update in accordance with 5202-101-00-QSP. 
Managers and Supervisors, please contact the document owner for training information.`
    },
    {
        id: 1,
        name: `A TRAINING RECORD is required for this update in accordance with 5202-101-00-QSP.
Training will be scheduled by the document owner.`
    },
    {
        id: 2,
        name: `This is a REGULATORY UPDATE. Employees are expected to review the document and provide acknowledgement in ADP.`
    },
    {
        id: 3,
        name: `GENERAL NOTIFICATION ONLY:  Training is NOT required for this update.
Managers and Supervisors, please notify your direct reports (without Precipart email addresses) of this update and confirm that the document is accessible.`
    }
]

if (process.env.REACT_APP_ENV === "development") {
    APPLICATION_ID = "16"
    backendURL = "http://localhost:5001"
    appAdminBackendURL = "http://localhost:5000"
    loginURL = "http://localhost:3000"
    emailServerURL = "http://localhost:5002"
    unprotectedToken = process.env.REACT_APP_UNPROTECTED_TOKEN

    firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
        appId: process.env.REACT_APP_FIREBASE_APPID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASURMENTID
    }
} else if (process.env.REACT_APP_ENV === "gcpdevelopment") {
    APPLICATION_ID = "16"
    backendURL = "https://notification-station-server-tdnsdphoqq-uk.a.run.app"
    appAdminBackendURL =
        "https://application-administration-server-tdnsdphoqq-uk.a.run.app"
    loginURL =
        "https://application-administration-client-tdnsdphoqq-uk.a.run.app"
    emailServerURL = "https://email-server-tdnsdphoqq-uk.a.run.app"
    unprotectedToken = process.env.REACT_APP_UNPROTECTED_TOKEN

    firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
        appId: process.env.REACT_APP_FIREBASE_APPID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASURMENTID
    }
} else if (process.env.REACT_APP_ENV === "test") {
    APPLICATION_ID = "6"
    backendURL = "https://notification-station-server-b34iv6u2nq-uk.a.run.app"
    emailServerURL = "https://email-server-b34iv6u2nq-uk.a.run.app"
    appAdminBackendURL =
        "https://application-administration-server-b34iv6u2nq-uk.a.run.app"
    loginURL =
        "https://application-administration-client-b34iv6u2nq-uk.a.run.app"
    unprotectedToken = process.env.REACT_APP_UNPROTECTED_TOKEN

    firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
        appId: process.env.REACT_APP_FIREBASE_APPID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASURMENTID
    }
} else if (process.env.REACT_APP_ENV === "production") {
    APPLICATION_ID = "6"
    backendURL = "https://notification-station-server-jaf747essq-uk.a.run.app"
    emailServerURL = "https://email-server-jaf747essq-uk.a.run.app"
    appAdminBackendURL =
        "https://application-administration-server-jaf747essq-uk.a.run.app"
    loginURL =
        "https://application-administration-client-jaf747essq-uk.a.run.app"
    unprotectedToken = process.env.REACT_APP_UNPROTECTED_TOKEN

    firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
        appId: process.env.REACT_APP_FIREBASE_APPID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASURMENTID
    }
}

export {
    APPLICATION_ID,
    backendURL,
    appAdminBackendURL,
    firebaseConfig,
    loginURL,
    homeURL,
    emailServerURL,
    environment,
    unprotectedToken,
    documentLocationMap,
    documentTypesMap,
    initiatorStatementMap
}
