import { React, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Grid, Typography } from "@material-ui/core"

import FunctionButton from "./FunctionButton"
import RecipientInfo from "./RecipientInfo"
import useStyles from "./styles/AdminSettingStyles"
import {
    getAllRecipients,
    getAllFunctionTypes,
    getAllCommTypes
} from "../../actions/adminActions"

/* Component that represents the outer container that pertains to Visitor Announcements. */
function VisitorAdmin() {
    const classes = useStyles()
    const dispatch = useDispatch()

    // authentication store variable
    const loggedIn = useSelector(
        (state) => state.auth.user && state.auth.user.auth
    )

    const authenticated = useSelector((state) => state.auth.authenticated)

    // store variable representing all email recipients for all functions
    const allRecipients = useSelector((state) => state.admin.allRecipients)
    const allCommTypes = useSelector((state) => state.admin.allCommTypes)
    const allFunctionTypes = useSelector(
        (state) => state.admin.allFunctionTypes
    )

    // store variable for selected function (button)
    const selectedFunction = useSelector(
        (state) => state.admin.selectedFunction
    )

    /* React hook; updates state variables to initialize needed data from backend. */
    useEffect(() => {
        if (loggedIn) {
            async function runUseEffectFunctions() {
                await dispatch(getAllRecipients()) // all email recipients for all function types
                await dispatch(getAllCommTypes()) // (static) all commmunication types
                await dispatch(getAllFunctionTypes()) // (static) all function types
            }
            runUseEffectFunctions()
        }
    }, [dispatch, loggedIn])

    /* Function that obtains the communication type ID that corresponds to its name. */
    const getCommTypeId = (type) => {
        for (let index = 0; index < allCommTypes.length; index++) {
            let elem = allCommTypes[index]
            if (elem.CommunicationTypeName === type) {
                return elem.CommunicationTypeId
            }
        }
        return -1
    }
    const commTypeIdVA = getCommTypeId("Visitor Announcement") // get ID for "VA"

    // filter for only function types that are related to "VA" communication type
    const allFunctionsVA = allFunctionTypes.filter(
        (elem) => elem.CommunicationTypeId === commTypeIdVA
    )
    // array of function type names (related to "VA")
    const allFunctions = allFunctionsVA.map((funcElem) => {
        return funcElem.CommunicationFunctionName
    })

    return (
        <div className={classes.root}>
            <Grid container alignContent="center" direction="column">
                <Grid
                    container
                    align="center"
                    justifyContent="space-evenly"
                    className={classes.BoxVA}
                >
                    <label className={classes.BoxLabelVA}>
                        VISITOR ANNOUNCEMENT
                    </label>
                    <Grid item xs={12}>
                        <Typography
                            variant="subtitle1"
                            gutterBottom
                            className={classes.SelectText}
                        >
                            Please select a function to view or edit recipients.
                        </Typography>
                    </Grid>
                    {/* all function buttons */}
                    {allFunctions.map((func) => (
                        <Grid item key={func}>
                            <FunctionButton label={func} />
                        </Grid>
                    ))}
                    {/* expand info related to function button that was clicked */}
                    <Grid item xs={12} md={7} xl={8}>
                        {(selectedFunction === "Welcome Board" ||
                            selectedFunction === "Lunch" ||
                            selectedFunction === "Breakfast" ||
                            selectedFunction === "Campus Tour" ||
                            selectedFunction === "Visitor Notifications") && (
                            <RecipientInfo
                                allFunctionsMap={allFunctionsVA}
                                allRecipients={allRecipients}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default VisitorAdmin
