import { React, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
    Grid,
    Typography,
    Button,
    Paper,
    Divider,
    Snackbar,
    SnackbarContent,
    useTheme,
    useMediaQuery
} from "@material-ui/core"
import { Dialog, DialogContent, Tooltip, IconButton } from "@mui/material"

import useStyles from "./ISOStyles"
import CalendarIcon from "../visitor/CalendarIcon"
import DocumentItemDialog from "./DocumentItemDialog"

function DocumentListItem({ documentObj }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const theme = useTheme()

    // boolean flag for small screen size (mobile)
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    const handleSeeDetails = async () => {
        // get info regarding document
        setOpen(true)
    }

    const dateCreator = () => {
        const dashDate = documentObj.DateImplemented.substring(0, 10)

        const yearStr = dashDate.substring(0, 4)
        const monthStr = dashDate.substring(5, 7)
        const dateStr = dashDate.substring(8, 10)

        const slashDate = monthStr + "/" + dateStr + "/" + yearStr

        return new Date(slashDate)
    }

    return (
        <>
            <Grid
                item
                container
                justifyContent="space-evenly"
                className={classes.DocumentItem}
            >
                <Grid item xs={1} className={classes.CalendarCell}>
                    <CalendarIcon dateObj={dateCreator()} />
                </Grid>
                <Grid item xs={9} md={10}>
                    <Paper className={classes.PaperItem} elevation={3}>
                        <Grid container direction="row" justifyContent="center">
                            {/* <Typography
                                align="center"
                                className={classes.PaperFirmName}
                            >
                                {`REV ${documentObj.DocumentRevision}`}
                            </Typography> */}
                            <Grid
                                item
                                xs={8}
                                md={9}
                                className={classes.DocumentTextGrid}
                            >
                                <Typography
                                    align="center"
                                    variant="h5"
                                    className={classes.PaperFirmName}
                                >
                                    {isSmallScreen
                                        ? `${documentObj.DocumentTitle?.slice(
                                              0,
                                              10
                                          )}...`
                                        : documentObj.DocumentTitle}
                                </Typography>
                                <Typography align="center">
                                    {isSmallScreen
                                        ? `${documentObj.DocumentNumber}`
                                        : `${documentObj.DocumentNumber} Rev ${documentObj.DocumentRevision}`}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={3}
                                className={classes.DetailButtonGrid}
                            >
                                {isSmallScreen ? (
                                    <Button
                                        size="small"
                                        variant="contained"
                                        className={classes.DetailButtonSmall}
                                        onClick={handleSeeDetails}
                                    >
                                        DETAILS
                                    </Button>
                                ) : (
                                    <Button
                                        size="small"
                                        variant="contained"
                                        className={classes.DetailButton}
                                        onClick={handleSeeDetails}
                                    >
                                        SEE DETAILS
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    maxWidth="sm"
                    fullWidth={true}
                >
                    <DialogContent style={{ height: "570px" }}>
                        <DocumentItemDialog documentObj={documentObj} />
                    </DialogContent>
                </Dialog>
            </Grid>
        </>
    )
}

export default DocumentListItem
