export const GET_USER_DATA = "GET_USER_DATA"
export const TOGGLE_DARKMODE = "TOGGLE_DARKMODE"
export const GET_AUTHORIZATION_FOR_APPLICATION =
    "GET_AUTHORIZATION_FOR_APPLICATION"
export const CREATE_PENDING_USER = "CREATE_PENDING_USER"
export const CREATE_AUDIT_TRAIL = "CREATE_AUDIT_TRAIL"
export const LOGOUT_USER = "LOGOUT_USER"
export const LOGIN_USER = "LOGIN_USER"

export const GET_FUNCTION_RECIPIENTS = "GET_FUNCTION_RECIPIENTS"
export const ADD_FUNCTION_RECIPIENT = "ADD_FUNCTION_RECIPIENT"
export const DELETE_FUNCTION_RECIPIENT = "DELETE_FUNCTION_RECIPIENT"
export const GET_ALL_FUNCTION_RECIPIENTS = "GET_ALL_FUNCTION_RECIPIENTS"

export const SET_SELECTED_FUNCTION = "SET_SELECTED_FUNCTION"
export const GET_ALL_FUNCTIONS = "GET_ALL_FUNCTIONS"
export const GET_ALL_COMM_TYPES = "GET_ALL_COMM_TYPES"

export const UPDATE_VISITOR_LIST = "UPDATE_VISITOR_LIST"
export const UPDATE_HOST_LIST = "UPDATE_HOST_LIST"
export const UPDATE_CONF_ROOM_LIST = "UPDATE_CONF_ROOM_LIST"

export const GET_ALL_EMPLOYEES = "GET_ALL_EMPLOYEES"
export const GET_ALL_ACTIVE_EMPLOYEES = "GET_ALL_ACTIVE_EMPLOYEES"
export const ADD_VISIT_INFO = "ADD_VISIT_INFO"
export const GET_ALL_CONFERENCE_ROOMS = "GET_ALL_CONFERENCE_ROOMS"
export const GET_ALL_FIRM_TYPES = "GET_ALL_FIRM_TYPES"

export const GET_ALL_STORED_VISITS = "GET_ALL_STORED_VISITS"
export const SET_SELECTED_VISIT_ID = "SET_SELECTED_VISIT_ID"
export const GET_VISIT_HOST_BY_VISIT_ID = "GET_VISIT_HOST_BY_VISIT_ID"
export const GET_VISIT_CONF_ROOM_BY_VISIT_ID = "GET_VISIT_CONF_ROOM_BY_VISIT_ID"
export const GET_VISIT_VISITORS_BY_VISIT_ID = "GET_VISIT_VISITORS_BY_VISIT_ID"

export const UPDATE_VISIT_ACTIVE = "UPDATE_VISIT_ACTIVE"
export const GET_VISIT_BY_VISIT_ID = "GET_VISIT_BY_VISIT_ID"
export const UPDATE_VISIT_INFO = "UPDATE_VISIT_INFO"

export const UPDATE_REMINDER_LIST = "UPDATE_REMINDER_LIST"
export const GET_REMINDERS_BY_VISIT_ID = "GET_REMINDERS_BY_VISIT_ID"
export const ADD_VISIT_REMINDERS = "ADD_VISIT_REMINDERS"
export const DELETE_VISIT_REMINDERS = "DELETE_VISIT_REMINDERS"
export const REPLACE_VISIT_REMINDERS = "REPLACE_VISIT_REMINDERS"
export const SET_OLD_VISIT_START = "SET_OLD_VISIT_START"

export const SET_REFRESH_KEY = "SET_REFRESH_KEY"
export const GET_REMINDERS_DATE = "GET_REMINDERS_DATE"
export const SET_VISIT_START = "SET_VISIT_START"
export const SET_VISIT_END = "SET_VISIT_END"
export const ADD_IMM_REMINDER_AUDIT = "ADD_IMM_REMINDER_AUDIT"

export const SET_ISO_REFRESH_KEY = "SET_ISO_REFRESH_KEY"
export const ADD_ISO_UPDATE = "ADD_ISO_UPDATE"
export const GET_ALL_ISO_UPDATES = "GET_ALL_ISO_UPDATES"
export const SEND_ISO_EMAIL = "SEND_ISO_EMAIL"
export const SET_SHOW_CONFIRMATION = "SET_SHOW_CONFIRMATION"
