import axios from "axios"

import firebaseAuth from "./firebaseAuth"

const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(async (config) => {
    const user = firebaseAuth.currentUser

    if (user) {
        const token = await user.getIdToken()
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${token}`,
            gid: user.uid
        }
    }
    return config
})

export default axiosInstance
