import React from "react"

import useStyles from "./styles/VisitorStyles"

function CalendarTimeIcon({ dateObj }) {
    const classes = useStyles()

    const dayOfMonth = dateObj.getDate()
    const month = dateObj
        .toLocaleString("default", { month: "short" })
        .toUpperCase()
    const time = dateObj.toLocaleTimeString([], {
        timeStyle: "short"
    })

    return (
        <div className={classes.CalendarTimeBox}>
            <div className={classes.CalendarTimeMonth}>
                {month + " " + dayOfMonth}
            </div>
            <div className={classes.CalendarTime}>{time}</div>
        </div>
    )
}

export default CalendarTimeIcon
