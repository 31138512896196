import {
    ADD_FUNCTION_RECIPIENT,
    DELETE_FUNCTION_RECIPIENT,
    GET_ALL_FUNCTION_RECIPIENTS,
    SET_SELECTED_FUNCTION,
    GET_ALL_FUNCTIONS,
    GET_ALL_COMM_TYPES
} from "../actions/types"

const initialState = {
    allRecipients: [],
    selectedFunction: "",
    allFunctionTypes: [],
    allCommTypes: []
}

const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_FUNCTION_RECIPIENT:
            return { ...state }
        case DELETE_FUNCTION_RECIPIENT:
            return { ...state }
        case GET_ALL_FUNCTION_RECIPIENTS:
            return { ...state, allRecipients: action.payload }
        case SET_SELECTED_FUNCTION:
            return { ...state, selectedFunction: action.payload }
        case GET_ALL_FUNCTIONS:
            return { ...state, allFunctionTypes: action.payload }
        case GET_ALL_COMM_TYPES:
            return { ...state, allCommTypes: action.payload }
        default:
            return state
    }
}

export default adminReducer
