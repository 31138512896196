import {
    GET_ALL_STORED_VISITS,
    SET_SELECTED_VISIT_ID,
    GET_VISIT_HOST_BY_VISIT_ID,
    GET_VISIT_CONF_ROOM_BY_VISIT_ID,
    GET_VISIT_VISITORS_BY_VISIT_ID,
    GET_VISIT_BY_VISIT_ID,
    GET_REMINDERS_BY_VISIT_ID,
    UPDATE_REMINDER_LIST,
    DELETE_VISIT_REMINDERS,
    REPLACE_VISIT_REMINDERS,
    SET_OLD_VISIT_START,
    SET_REFRESH_KEY,
    GET_REMINDERS_DATE,
    SET_VISIT_START,
    SET_VISIT_END,
    ADD_IMM_REMINDER_AUDIT
} from "../actions/types"

const initialState = {
    refreshKey: 0,
    allStoredVisits: [],
    currentVisitHosts: [],
    currentVisitConfRooms: [],
    currentVisitVisitors: [],
    selectedVisitId: "",
    oldVisitStart: null,
    currentVisitObj: [],
    currentReminders: [],
    allReminders: [],
    remindersDate: [],
    visitStartDateTime: null,
    visitEndDateTime: null
}

const visitorFeedReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_REFRESH_KEY:
            return { ...state, refreshKey: action.payload }
        case SET_VISIT_START:
            return { ...state, visitStartDateTime: action.payload }
        case SET_VISIT_END:
            return { ...state, visitEndDateTime: action.payload }
        case GET_REMINDERS_DATE:
            return { ...state, remindersDate: action.payload }
        case GET_ALL_STORED_VISITS:
            return { ...state, allStoredVisits: action.payload }
        case SET_SELECTED_VISIT_ID:
            return { ...state, selectedVisitId: action.payload }
        case GET_VISIT_HOST_BY_VISIT_ID:
            return { ...state, currentVisitHosts: action.payload }
        case GET_VISIT_CONF_ROOM_BY_VISIT_ID:
            return { ...state, currentVisitConfRooms: action.payload }
        case GET_VISIT_VISITORS_BY_VISIT_ID:
            return { ...state, currentVisitVisitors: action.payload }
        case GET_VISIT_BY_VISIT_ID:
            return { ...state, currentVisitObj: action.payload }
        case GET_REMINDERS_BY_VISIT_ID:
            return { ...state, currentReminders: action.payload }
        case UPDATE_REMINDER_LIST:
            return { ...state, allReminders: action.payload }
        case DELETE_VISIT_REMINDERS:
            return { ...state }
        case REPLACE_VISIT_REMINDERS:
            return { ...state }
        case ADD_IMM_REMINDER_AUDIT:
            return { ...state }
        case SET_OLD_VISIT_START:
            return { ...state, oldVisitStart: action.payload }
        default:
            return state
    }
}

export default visitorFeedReducer
