import { React, useState, useEffect } from "react"
import {
    Grid,
    Button,
    Typography,
    useTheme,
    useMediaQuery
} from "@material-ui/core"
import { useSelector, useDispatch } from "react-redux"
import { Autocomplete, TextField, IconButton } from "@mui/material"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined"

import { updateHostList } from "../../actions/visitorFormActions"
import useStyles from "./styles/VisitorStyles"
import Alert from "../main/Alert"

/* Component that defines the form area to input host information, relating to the
    specified visit. */
function HostBox({ mode }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const theme = useTheme()

    // constants for conditional styling adjustments due to screen size (px)
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"))
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("601"))
    const isSmallPhone = useMediaQuery(theme.breakpoints.down("400"))

    // all active employees {id, first, last} loaded from database
    const allActiveEmployees = useSelector(
        (state) => state.visitor.activeEmployees
    )

    // state representation of selected host (input box)
    const [hostValue, setHostValue] = useState({ id: "", name: "" })

    // EDIT mode: get original host list and initialize state variables with it
    const currentHostList = useSelector(
        (state) => state.visitFeed.currentVisitHosts
    )
    let formatHostList
    if (mode === "edit" && currentHostList?.length > 0) {
        formatHostList = currentHostList
    }

    // state representation of (dynamic) list of selected hosts on form
    const [hostList, setHostList] = useState(
        mode === "create" ? [] : [...formatHostList]
    )

    /* Manipulates array of active employees to format for Autocomplete dropdown 
        ({id: ..., name: ...}); unique array of employees (relative to name field) */
    const allEmployeesDropdown = allActiveEmployees
        .map((emp) => {
            return {
                id: emp.AssociateOID,
                name: emp.FirstName + " " + emp.LastName
            }
        })
        .filter(
            (obj, index, self) =>
                self.map((obj) => obj.name).indexOf(obj.name) === index
        )
    // insert empty object at beginning of array
    allEmployeesDropdown.splice(0, 0, { id: "", name: "" })

    // list of all selected hosts from store
    const allHosts = useSelector((state) => state.visitor.allHosts)

    // error alert message
    const [alertFlag, setAlertFlag] = useState(false)
    const [alertText, setAlertText] = useState("")

    /* Handler to clear state representation of host (input box) */
    const clearHostInput = () => {
        setHostValue({ id: "", name: "" })
    }

    /* Handler for "ADD" button to add selected host to state representation of list,
        then updates host list in store */
    const handleClick = async () => {
        // check for blank inputs
        if (hostValue && hostValue.id !== "") {
            // check for duplicates
            if (hostList.some((host) => host.id === hostValue.id)) {
                setAlertFlag(true)
                setAlertText("It looks like this host already exists.")
                return
            }
            hostList.push(hostValue)
            setHostList(hostList)
            await dispatch(updateHostList(hostList))
        }
        clearHostInput()
    }

    /* Handler for "x" delete host button */
    const handleDeleteHost = async (host) => {
        let filteredHostList = hostList.filter(
            (hostObj) => hostObj.id !== host.id
        )
        setHostList(filteredHostList)
        await dispatch(updateHostList(filteredHostList))
    }

    /* Handler for alert message */
    const handleAlertDialog = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setAlertFlag(false)
        clearHostInput()
    }

    /* Handler for all changes in dropdown Autocomplete field */
    const onChangeHost = (event, newVal) => {
        setHostValue({
            id: newVal ? newVal.id : "",
            name: newVal ? newVal.name : ""
        })
    }

    /* React hook; updates state variable to initialize (EDIT mode) */
    useEffect(() => {
        async function runUseEffectFunctions() {
            // on component render, set state variables to saved data
            if (mode === "edit" && formatHostList) {
                await dispatch(updateHostList(formatHostList))
            }
        }
        runUseEffectFunctions()
    }, [dispatch])

    return (
        <>
            <Grid item container className={classes.HostInner}>
                {/* Case on number of Host entries, to display */}
                {allHosts.length !== 0 ? (
                    allHosts?.map((host, index) => {
                        return (
                            <Grid item container key={index}>
                                <Grid
                                    item
                                    align="center"
                                    xs={9}
                                    sm={9}
                                    className={classes.VisitorEntry}
                                >
                                    {host.name}
                                </Grid>
                                <Grid item xs={2} sm={2} align="center">
                                    <IconButton
                                        color="inherit"
                                        onClick={(e) => handleDeleteHost(host)}
                                    >
                                        <HighlightOffIcon fontSize="small" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        )
                    })
                ) : (
                    <Grid item xs={12}>
                        <Typography
                            align="center"
                            className={classes.NoVisitors}
                        >
                            No hosts added.
                        </Typography>
                    </Grid>
                )}

                {/* Alerts pop-up area */}
                <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    justify="flex-end"
                >
                    <Grid item>
                        {alertFlag && (
                            <Alert onClose={handleAlertDialog} severity="error">
                                {alertText}
                            </Alert>
                        )}
                    </Grid>
                </Grid>

                {/* Host selector dropdown component */}
                <Grid
                    item
                    xs={9}
                    sm={8}
                    md={9}
                    className={classes.VisitorBoxInputs}
                >
                    <Autocomplete
                        disablePortal
                        size="small"
                        options={allEmployeesDropdown}
                        getOptionLabel={(option) => option.name}
                        key={(option) => option.id}
                        value={hostValue}
                        isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                        }
                        onChange={onChangeHost}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Employee Name"
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    classes: {
                                        notchedOutline: classes.notchedOutline
                                    },
                                    className: classes.TextColor
                                }}
                                InputLabelProps={{
                                    ...params.InputLabelProps,
                                    className: classes.floatingLabelFocusStyle
                                }}
                            />
                        )}
                    />
                </Grid>

                {/* "ADD" button to add entered Host entry to list in area */}
                <Grid
                    item
                    xs={2}
                    sm={3}
                    md={2}
                    align="center"
                    className={
                        isSmallScreen
                            ? isPhoneScreen
                                ? !isSmallPhone
                                    ? classes.AddHostButtonSmallPhone
                                    : classes.AddHostButtonPhone
                                : classes.AddHostButtonSmall
                            : classes.AddHostButton
                    }
                >
                    {isSmallPhone ? (
                        <IconButton color="warning" onClick={handleClick}>
                            <AddBoxOutlinedIcon />
                        </IconButton>
                    ) : (
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleClick}
                        >
                            ADD
                        </Button>
                    )}
                </Grid>
            </Grid>
        </>
    )
}

export default HostBox
