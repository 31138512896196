import { combineReducers } from "redux"

import authReducer from "./authReducer"
import adminReducer from "./adminReducer"
import visitorFormReducer from "./visitorFormReducer"
import visitorFeedReducer from "./visitorFeedReducer"
import isoReducer from "./isoReducer"

export default combineReducers({
    auth: authReducer,
    admin: adminReducer,
    visitor: visitorFormReducer,
    visitFeed: visitorFeedReducer,
    iso: isoReducer
})
