import { React, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
    Grid,
    Button,
    Typography,
    useTheme,
    useMediaQuery
} from "@material-ui/core"
import { Divider } from "@mui/material"

import LoginPrompt from "../login/LoginPrompt"
import resetHeaders from "../../functions/resetHeaders"
import useStyles from "./HomePageStyles"

function HomePage() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const theme = useTheme()

    const permission = useSelector((state) => state.auth.permissions)

    // boolean flag for small screen size (mobile)
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

    // Store Variables
    const authenticated = useSelector((state) => state.auth.authenticated)

    const handleOnClickVisitor = () => {
        navigate(`/visitor`)
    }

    const handleOnClickISO = () => {
        navigate(`/iso`)
    }

    return (
        <>
            {authenticated ? (
                <div>
                    <Grid
                        container
                        justify="center"
                        className={classes.PageTitle}
                    >
                        HOME PAGE
                    </Grid>
                    <Divider />
                    <Grid
                        container
                        direction="row"
                        align="center"
                        justifyContent="space-evenly"
                        className={classes.ButtonGrid}
                    >
                        {permission.ViewVisitorAnnouncement && (
                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant="outlined"
                                    color="inherit"
                                    onClick={handleOnClickVisitor}
                                    className={classes.ButtonStyles}
                                >
                                    <Typography
                                        variant="h5"
                                        className={classes.ButtonText}
                                    >
                                        VISITOR ANNOUNCEMENT
                                    </Typography>
                                </Button>
                            </Grid>
                        )}
                        {permission.ViewISODocumentUpdate && (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                className={
                                    isSmallScreen ? classes.ButtonGridSmall : ""
                                }
                            >
                                <Button
                                    variant="outlined"
                                    color="inherit"
                                    onClick={handleOnClickISO}
                                    className={classes.ButtonStyles}
                                >
                                    <Typography
                                        variant="h5"
                                        className={classes.ButtonText}
                                    >
                                        ISO REGULATORY UPDATE
                                    </Typography>
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </div>
            ) : (
                <LoginPrompt />
            )}
        </>
    )
}

export default HomePage
