import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    PageTitle: {
        fontWeight: "800",
        marginBottom: "5px",
        fontSize: "15px"
    },
    ButtonGrid: {
        marginTop: "100px"
    },
    ButtonStyles: {
        width: "250px",
        height: "100px",
        borderRadius: "16px"
    },
    ButtonText: {
        fontWeight: "bold"
    },
    ButtonGridSmall: {
        marginTop: "20px"
    }
}))

export default useStyles
