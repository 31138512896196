import { React, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
    Button,
    Grid,
    Typography,
    TextField,
    IconButton,
    useTheme,
    useMediaQuery
} from "@material-ui/core"
import { Dialog, DialogContent, Divider, InputAdornment } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import ClearIcon from "@mui/icons-material/Clear"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace"

import resetHeaders from "../../functions/resetHeaders"
import {
    getAllActiveEmployees,
    getAllEmployees,
    getAllISOUpdates
} from "../../actions/isoActions"
import useStyles from "./ISOStyles"
import LoginPrompt from "../login/LoginPrompt"
import ISOForm from "./ISOForm"
import DocumentListItem from "./DocumentListItem"

function ISODashboard() {
    const classes = useStyles()
    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const isoRefreshKey = useSelector((state) => state.iso.isoRefreshKey)
    const permission = useSelector((state) => state.auth.permissions)

    // list of all current ISO document updates
    const allDocumentUpdates = useSelector((state) => state.iso.allISOUpdates)

    // boolean flag for small screen size (mobile)
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

    // state repr. for text in search box
    const [searchText, setSearchText] = useState("")

    // state repr. for reverse sort
    const [reordered, setReordered] = useState(true)

    // display list of document updates (descending date)
    const allDocumentUpdatesList = allDocumentUpdates.filter(
        (doc) =>
            !searchText ||
            doc.DocumentNumber.toLowerCase().includes(
                searchText.toLowerCase()
            ) ||
            doc.DocumentTitle.toLowerCase().includes(searchText.toLowerCase())
    )

    const allDocumentUpdatesListOrdered = reordered
        ? allDocumentUpdatesList.sort(
              (a, b) =>
                  new Date(b.DateImplemented).getTime() -
                  new Date(a.DateImplemented).getTime()
          )
        : allDocumentUpdatesList.sort(
              (a, b) =>
                  new Date(a.DateImplemented).getTime() -
                  new Date(b.DateImplemented).getTime()
          )

    // state variable for form dialog
    const [openForm, setOpenForm] = useState(false)

    const openFormFxn = () => {
        setOpenForm(true)
    }

    const closeForm = () => {
        setOpenForm(false)
    }

    // Handler function for clear option in search bar
    const handleClear = () => {
        setSearchText("")
    }

    // Handler function for search bar textfield
    const handleChangeSearch = (e) => {
        setSearchText(e.target.value)
    }

    const handleOnClickReverseSort = () => {
        const flag = reordered
        setReordered(!flag)
    }

    const handleBackClick = () => {
        navigate(`/home`)
    }

    // Store Variables
    const loggedIn = useSelector(
        (state) => state.auth.user && state.auth.user.auth
    )
    const authenticated = useSelector((state) => state.auth.authenticated)

    useEffect(() => {
        if (loggedIn) {
            resetHeaders()
            async function runUseEffectFunctions() {
                await dispatch(getAllEmployees())
                await dispatch(getAllActiveEmployees()) // (app admin) all active employees, with id
                await dispatch(getAllISOUpdates())
            }
            runUseEffectFunctions()
        }
    }, [dispatch, loggedIn])

    return (
        <>
            {authenticated ? (
                <div key={isoRefreshKey}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        className={classes.PageTitle}
                    >
                        ISO REGULATORY UPDATES
                    </Grid>
                    <Divider />
                    <Grid
                        container
                        direction="row"
                        align="center"
                        justifyContent="space-evenly"
                    >
                        <Grid
                            item
                            xs={5}
                            md={2}
                            className={classes.ButtonGridWidth}
                        >
                            {permission.CreateISODocumentUpdate ? (
                                <Button
                                    variant="outlined"
                                    color="inherit"
                                    onClick={openFormFxn}
                                    className={classes.CreateNewButton}
                                >
                                    New ISO Update
                                </Button>
                            ) : (
                                ""
                            )}
                        </Grid>
                        <Grid
                            container
                            item
                            xs={10}
                            md={8}
                            align="center"
                            className={classes.DocumentUpdateList}
                        >
                            {allDocumentUpdatesListOrdered.length > 0 ? (
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.SwapSortIcon}
                                >
                                    <IconButton
                                        onClick={handleOnClickReverseSort}
                                    >
                                        {reordered ? (
                                            <ArrowDownwardIcon />
                                        ) : (
                                            <ArrowUpwardIcon />
                                        )}
                                        <Typography>SORT</Typography>
                                    </IconButton>
                                </Grid>
                            ) : (
                                ""
                            )}
                            {allDocumentUpdatesListOrdered.length > 0 ? (
                                allDocumentUpdatesListOrdered.map((doc) => (
                                    <DocumentListItem
                                        key={doc.DocumentUpdateId}
                                        documentObj={doc}
                                    />
                                ))
                            ) : (
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.DocumentListItem}
                                >
                                    <Typography
                                        align="center"
                                        variant="h6"
                                        className={classes.NoDocument}
                                    >
                                        <i>No document updates to show.</i>
                                    </Typography>
                                </Grid>
                            )}
                            {/* if len > 0 map thru list of ISO updates; else no ISO updates */}
                        </Grid>
                        {/* search bar */}
                        {!isSmallScreen && (
                            <Grid item xs={5} md={2}>
                                <TextField
                                    placeholder="Search..."
                                    value={searchText}
                                    className={classes.SearchBar}
                                    onChange={handleChangeSearch}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment
                                                disableTypography={true}
                                                position="start"
                                                style={{ color: "inherit" }}
                                            >
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <IconButton
                                                style={{ padding: "5px" }}
                                                onClick={handleClear}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        )
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Dialog
                        open={openForm}
                        onClose={closeForm}
                        maxWidth="sm"
                        fullWidth={true}
                    >
                        <DialogContent style={{ height: "570px" }}>
                            <ISOForm onSubmit={closeForm} />
                        </DialogContent>
                    </Dialog>
                </div>
            ) : (
                <LoginPrompt />
            )}
        </>
    )
}

export default ISODashboard
