import {
    SET_ISO_REFRESH_KEY,
    GET_ALL_ISO_UPDATES,
    GET_ALL_EMPLOYEES
} from "../actions/types"

const initialState = {
    isoRefreshKey: 0,
    allEmployees: [],
    allISOUpdates: []
}

const isoReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ISO_REFRESH_KEY:
            return { ...state, isoRefreshKey: action.payload }
        case GET_ALL_ISO_UPDATES:
            return { ...state, allISOUpdates: action.payload }
        case GET_ALL_EMPLOYEES:
            return { ...state, allEmployees: action.payload }
        default:
            return state
    }
}

export default isoReducer
