import { React, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Tooltip
} from "@mui/material"
import {
    Typography,
    Grid,
    Button,
    Snackbar,
    SnackbarContent,
    useTheme,
    useMediaQuery
} from "@material-ui/core"
import TaskAltIcon from "@mui/icons-material/TaskAlt"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"

import useStyles from "./styles/VisitorStyles"
import {
    updateVisitActive,
    setSelectedVisitId,
    getVisitById,
    sendCancelEmail,
    getVisitReminders,
    updateReminderList,
    deleteVisitRemindersByVisitId,
    setOldVisitStart,
    getAllStoredVisits,
    setRefreshKey,
    addImmReminderAudit
} from "../../actions/visitorFeedActions"
import { sendVisitorEmail } from "../../actions/visitorFormActions"
import ConfirmationDialog from "./ConfirmationDialog"
import ReminderDialog from "./ReminderDialog"
import CalendarTimeIcon from "./CalendarTimeIcon"

/* Component that defines the dialog popup window, for each visit, when user expands visit details. */
function VisitorListItemDialog({ visitObj, visitType, past }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const theme = useTheme()

    const permission = useSelector((state) => state.auth.permissions)
    const refreshKey = useSelector((state) => state.visitFeed.refreshKey)

    const userFullName = useSelector((state) => state.auth.user.displayName)

    const [loading, setLoading] = useState(false)

    const remindersDate = useSelector((state) => state.visitFeed.remindersDate)
    const currRemindersDate = remindersDate
        .filter((elemR) => elemR.Sent === false)
        .map((elem) => ({
            ...elem,
            SendDateTime: new Date(elem.SendDateTime)
        }))
        .sort((a, b) => a.SendDateTime.getTime() - b.SendDateTime.getTime())

    // constants for conditional styling adjustments due to screen size (px)
    const isMoreSmallerScreen = useMediaQuery(theme.breakpoints.down("490"))
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down("600"))
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("750"))
    const isPhoneLandscape = useMediaQuery(
        "(max-width:844px) and (orientation: landscape)"
    )

    // state repr. for email sent popup indication
    const [emailOpen, setEmailOpen] = useState(false)

    /* Handler for opening popup alert : "email successfully sent" */
    const handleEmailOpen = () => {
        setEmailOpen(true)
    }

    /* Handler for closing popup alert : "email successfully sent" */
    const handleEmailClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setEmailOpen(false)
    }

    // state repr for confirm delete dialog popup
    const [open, setOpen] = useState(false)

    /* Handler for dialog visibility; set boolean flag to enable  */
    const handleClickOpen = () => {
        setOpen(true)
    }

    /* Hander for dialog visibility; set boolean flag to close dialog */
    const handleClose = () => {
        setOpen(false)
    }

    // state repr. for reminders dialog popup
    const [openReminder, setOpenReminder] = useState(false)
    const reminderOnClose = () => {
        setOpenReminder(false)
    }

    // state repr. for sending reminder now confirmation dialog popup
    const [openSendReminderNow, setOpenSendReminderNow] = useState(false)

    /* Handler for closing send reminder now confirmation dialog */
    const sendReminderNowOnClose = () => {
        setOpenSendReminderNow(false)
    }

    // get all email recipients from store (database)
    const allEmailRecipients = useSelector((state) => state.admin.allRecipients)

    // obtain only "Visitor Notifications" email recipients
    const visitorNotificationsEmailList = allEmailRecipients
        .filter(
            (elem) => elem.CommunicationFunctionName === "Visitor Notifications"
        )
        .map((elem) => {
            return elem.RecipientEmail
        })

    /* Function that handles a click on the confirm delete button. */
    const onClickDeleteButton = async (e) => {
        setLoading(true)

        const auditObject = {
            action: "Cancel Visitor Announcement",
            visitName: visitObj.FirmName,
            actionBy: userFullName
        }
        // update visit record's Active flag
        await dispatch(updateVisitActive(visitObj.VisitId, false, auditObject))

        // delete all reminders w visitId
        await dispatch(deleteVisitRemindersByVisitId(visitObj.VisitId))

        // reorganize data to only be relevant
        const visitInfo = {
            start: new Date(visitObj.StartDateTime),
            end: new Date(visitObj.EndDateTime),
            name: visitObj.FirmName,
            type: visitType,
            visitId: visitObj.VisitId
        }

        // send company wide cancellation email
        await dispatch(
            sendCancelEmail(visitorNotificationsEmailList, visitInfo)
        )
        // get new array of visits
        await dispatch(getAllStoredVisits())

        // increase refresh key (force reload after delete)
        await dispatch(setRefreshKey(refreshKey))

        // open delete confirmation dialog
        setOpen(false)
        setLoading(false)
    }

    /* Function that handles onClick action on the Edit button. */
    const onClickEditButton = async () => {
        await dispatch(setSelectedVisitId(visitObj.VisitId))
        await dispatch(setOldVisitStart(visitObj.StartDateTime))
        await dispatch(getVisitById(visitObj.VisitId))

        // route to edit component filled in w state vals
        navigate("/edit-visitor-form")
    }

    /* Function that handles onClick action on the Set Reminder button. */
    const onClickReminderButton = async () => {
        await dispatch(updateReminderList([]))
        await dispatch(setSelectedVisitId(visitObj.VisitId))
        await dispatch(
            getVisitReminders(visitObj.VisitId, visitObj?.StartDateTime)
        )

        // open reminder dialog
        setOpenReminder(true)
    }

    /* Function that handles onClick action on the Send Reminder Now button. */
    const onClickReminderNowButton = async () => {
        // visit data already in store
        setOpenSendReminderNow(true)
    }

    // list of hosts for current visit (store data)
    const currVisitHosts = useSelector(
        (state) => state.visitFeed.currentVisitHosts
    )

    // list of conference rooms for current visit (store data)
    const currVisitConfRooms = useSelector(
        (state) => state.visitFeed.currentVisitConfRooms
    )

    // list of visitors for current visit (store data)
    const currVisitVisitors = useSelector(
        (state) => state.visitFeed.currentVisitVisitors
    )

    // list of all active conference rooms, loaded from database (store)
    const activeConfRooms = useSelector(
        (state) => state.visitor.activeConfRooms
    )

    /* Handler for onClick "confirm" button on confirmation dialog for sending reminder now */
    const onClickConfirmReminder = async () => {
        // close confirmation dialog
        sendReminderNowOnClose()

        // set open true
        handleEmailOpen()

        const auditObject = {
            action: "Send Visitor Announcement Reminder",
            visitName: visitObj.FirmName,
            actionBy: userFullName
        }

        // collect/format data for reminder email
        const visitInfoFormatted = {
            type: {
                type: visitType,
                id: visitObj.FirmTypeId
            },
            firm: visitObj.FirmName,
            from: new Date(visitObj.StartDateTime),
            to: new Date(visitObj.EndDateTime),
            functions: {
                factory: visitObj.FactoryTour,
                breakfast: visitObj.Breakfast,
                lunch: visitObj.Lunch,
                welcome: visitObj.WelcomeBoard
            },
            visitors: currVisitVisitors?.length
                ? currVisitVisitors.map((visitor) => {
                      const name = visitor.VisitorName
                      const first = name.split(" ")[0]
                      const last = name.split(" ")[1]
                      return {
                          firstName: first,
                          lastName: last,
                          title: visitor.VisitorTitle
                      }
                  })
                : [],
            hosts: currVisitHosts?.length ? currVisitHosts : [],
            confRooms: currVisitConfRooms?.length
                ? currVisitConfRooms.map((room) => {
                      const start = new Date(room.StartDateTime)
                      const end = new Date(room.EndDateTime)
                      const id = room.ConferenceRoomId
                      const roomObj = activeConfRooms.find(
                          (conf) => conf.ConferenceRoomId === id
                      )

                      return {
                          name: roomObj?.ConferenceRoomName,
                          fromDateTime: start,
                          toDateTime: end,
                          id: id
                      }
                  })
                : []
        }

        await dispatch(
            sendVisitorEmail(
                visitorNotificationsEmailList,
                visitInfoFormatted,
                "remind"
            )
        )

        await dispatch(addImmReminderAudit(auditObject))

        console.log("email sending")

        // set email open to false
        handleEmailClose()
    }

    /* Function that takes date string (UTC), returns equiv str repr.
        of true date in locale timezone. */
    const formatDates = (dateStr, isSmallScreen) => {
        const dateObj = new Date(dateStr)
        if (isSmallScreen) {
            return dateObj.toLocaleString([], {
                dateStyle: "short",
                timeStyle: "short"
            })
        } else {
            return dateObj.toLocaleString([], {
                dateStyle: "medium",
                timeStyle: "short"
            })
        }
    }

    /* Function that takes date string (UTC), returns only time portion str 
        repr. of input in locale timezone. */
    const formatEndTime = (dateStr) => {
        const dateObj = new Date(dateStr)
        return dateObj.toLocaleTimeString([], {
            timeStyle: "short"
        })
    }

    /* Function that formats the list of visitor information (from db) to a comma
        deliminated list */
    const formatVisitors = () => {
        const length = currVisitVisitors.length
        return currVisitVisitors.map((elem, index) => {
            let returnVal =
                elem.VisitorName + " (" + elem.VisitorTitle?.trim() + ")"
            if (index !== length - 1) {
                return returnVal + ", "
            } else {
                return returnVal
            }
        })
    }

    /* Function that formats the list of host information (from db) to a comma
        deliminated list */
    const formatHosts = () => {
        const length = currVisitHosts.length
        return currVisitHosts.map((elem, index) => {
            if (elem?.name) {
                if (index !== length - 1) {
                    return elem.name + ", "
                } else {
                    return elem.name
                }
            } else {
                return
            }
        })
    }

    /* Function that formats the list of conference room information (from db) to 
        a comma deliminated list */
    const formatConfRooms = () => {
        const length = currVisitConfRooms.length
        return currVisitConfRooms.map((elem, index) => {
            const roomObj = activeConfRooms.find(
                (obj) => obj.ConferenceRoomId === elem.ConferenceRoomId
            )
            if (roomObj) {
                let returnVal =
                    roomObj.ConferenceRoomLocation +
                    " " +
                    roomObj.ConferenceRoomName +
                    " [" +
                    formatDates(elem.StartDateTime, isSmallScreen) +
                    "—" +
                    formatEndTime(elem.EndDateTime) +
                    "]"
                if (index !== length - 1) {
                    return returnVal + ", "
                } else {
                    return returnVal
                }
            } else {
                return
            }
        })
    }

    return (
        <div key={refreshKey}>
            <DialogTitle>
                <Typography
                    align="center"
                    variant="h6"
                    className={
                        isSmallScreen
                            ? classes.VisitDetailsTitleSmall
                            : isPhoneLandscape
                            ? classes.VisitDetailsLandscape
                            : classes.VisitDetailsTitle
                    }
                >
                    VISIT DETAILS
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {isSmallScreen ? (
                        <Grid
                            container
                            alignContent="flex-start"
                            className={classes.VisitDetailsTopSmall}
                        >
                            <Grid item xs={6} align="center">
                                <b>
                                    <u>VISITING FIRM</u>
                                </b>
                            </Grid>
                            <Grid item xs={6} align="center">
                                {visitObj.FirmName}
                            </Grid>
                            <Grid item xs={12}>
                                <br />
                            </Grid>
                            <Grid item xs={6} align="center">
                                <b>
                                    <u>VISIT TYPE</u>
                                </b>
                            </Grid>
                            <Grid item xs={6} align="center">
                                {visitType}
                            </Grid>
                            <Grid item xs={12}>
                                <br />
                            </Grid>
                            <Grid item xs={6} align="center">
                                <b>
                                    <u>VISIT START</u>
                                </b>
                            </Grid>
                            <Grid item xs={6} align="center">
                                {formatDates(
                                    visitObj.StartDateTime,
                                    isSmallScreen
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <br />
                            </Grid>
                            <Grid item xs={6} align="center">
                                <b>
                                    <u>VISIT END</u>
                                </b>
                            </Grid>
                            <Grid item xs={6} align="center">
                                {formatDates(
                                    visitObj.EndDateTime,
                                    isSmallScreen
                                )}
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid
                            container
                            alignContent="flex-start"
                            className={classes.VisitDetailsTop}
                        >
                            <Grid item sm={6}>
                                {/* visit firm name */}
                                <Typography>
                                    <b>
                                        <u>VISITING FIRM</u>
                                    </b>
                                    {" " + visitObj.FirmName}
                                </Typography>

                                {/* visit firm type */}
                                <Typography>
                                    <b>
                                        <u>VISIT TYPE</u>
                                    </b>
                                    {" " + visitType}
                                </Typography>
                            </Grid>
                            <Grid item sm={6}>
                                {/* visit start date/time */}
                                <Typography>
                                    <b>
                                        <u>VISIT START</u>
                                    </b>
                                    {" " + formatDates(visitObj.StartDateTime)}
                                </Typography>
                                {/* visit end date/time */}
                                <Typography>
                                    <b>
                                        <u>VISIT END</u>
                                    </b>
                                    {" " + formatDates(visitObj.EndDateTime)}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    <br />
                    <Grid
                        container
                        align={isSmallScreen ? "flex-start" : "center"}
                        className={classes.VisitDetailsMid}
                    >
                        {isSmallScreen ? (
                            <>
                                <Grid item xs={12}>
                                    {/* list of visitors (name, title) for this visit */}
                                    <Typography align="center">
                                        <b>
                                            <u>VISITOR(S)</u>
                                        </b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography align="center">
                                        {formatVisitors()}
                                    </Typography>
                                </Grid>
                            </>
                        ) : (
                            <Grid item sm={12}>
                                {/* list of visitors (name, title) for this visit */}
                                <Typography>
                                    <b>
                                        <u>VISITOR(S)</u>
                                    </b>{" "}
                                    {formatVisitors()}
                                </Typography>
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <br />
                        </Grid>

                        {/* list of hosts (name) for this visit */}
                        {isSmallScreen ? (
                            <>
                                <Grid item xs={12}>
                                    <Typography align="center">
                                        <b>
                                            <u>HOST(S)</u>
                                        </b>{" "}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography align="center">
                                        {formatHosts()}
                                    </Typography>
                                </Grid>
                            </>
                        ) : (
                            <Grid item sm={12}>
                                <Typography>
                                    <b>
                                        <u>HOST(S)</u>
                                    </b>{" "}
                                    {formatHosts()}
                                </Typography>
                            </Grid>
                        )}

                        {/* dynamically display conf room list, for this visit, if exists */}
                        {currVisitConfRooms.length > 0 ? (
                            isSmallScreen ? (
                                <>
                                    <Grid item xs={12}>
                                        <br />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography align="center">
                                            <b>
                                                <u>CONF.ROOM(S)</u>
                                            </b>{" "}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography align="center">
                                            {formatConfRooms()}
                                        </Typography>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid item xs={12}>
                                        <br />
                                    </Grid>
                                    <Grid item sm={12}>
                                        <Typography>
                                            <b>
                                                <u>CONF.ROOM(S)</u>
                                            </b>{" "}
                                            {formatConfRooms()}
                                        </Typography>
                                    </Grid>
                                </>
                            )
                        ) : (
                            ""
                        )}
                    </Grid>

                    <Grid container align="center" justifyContent="center">
                        {currRemindersDate.length > 0 && (
                            <>
                                <Grid item xs={12}>
                                    <br />
                                    <br />
                                </Grid>
                                <Grid
                                    item
                                    xs={isSmallScreen ? 12 : 3}
                                    // className={
                                    //     currRemindersDate.length === 1
                                    //         ? classes.ReminderTitleCenter
                                    //         : ""
                                    // }
                                >
                                    <Typography>
                                        <b>
                                            <u>SCHEDULED REMINDERS</u>
                                        </b>
                                    </Typography>
                                </Grid>
                            </>
                        )}
                        <Grid
                            wrap="nowrap"
                            item
                            container
                            justifyContent={
                                isSmallScreen && currRemindersDate.length < 4
                                    ? "center"
                                    : "flex-start"
                            }
                            sm={9}
                            md={
                                currRemindersDate.length > 5
                                    ? 9
                                    : currRemindersDate.length + 4
                            }
                            className={classes.ReminderScroll}
                        >
                            {currRemindersDate.map((reminderRecord) => (
                                <Grid
                                    item
                                    xs={isMoreSmallerScreen ? 5 : 2}
                                    className={classes.ReminderCards}
                                >
                                    <CalendarTimeIcon
                                        dateObj={reminderRecord.SendDateTime}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                            <br />
                        </Grid>
                    </Grid>
                    {isSmallScreen ? (
                        <>
                            <Grid item xs={12}>
                                <Typography align="center">
                                    <b>
                                        <u> SETUP REQUIRED </u>
                                    </b>
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                align="center"
                                className={classes.FunctionDetailsSmall}
                            >
                                {/* breakfast setup */}
                                <Grid item xs={3}>
                                    {visitObj.Breakfast ? (
                                        <TaskAltIcon
                                            color="success"
                                            fontSize="medium"
                                        />
                                    ) : (
                                        <HighlightOffIcon
                                            color="error"
                                            fontSize="medium"
                                        />
                                    )}
                                    <Typography
                                        className={classes.FunctionNameSmall}
                                    >
                                        Breakfast
                                    </Typography>
                                </Grid>
                                {/* lunch setup */}
                                <Grid item xs={3}>
                                    {visitObj.Lunch ? (
                                        <TaskAltIcon
                                            color="success"
                                            fontSize="medium"
                                        />
                                    ) : (
                                        <HighlightOffIcon
                                            color="error"
                                            fontSize="medium"
                                        />
                                    )}
                                    <Typography
                                        className={classes.FunctionNameSmall}
                                    >
                                        Lunch
                                    </Typography>
                                </Grid>
                                {/* factory tour setup */}
                                <Grid item xs={3}>
                                    {visitObj.FactoryTour ? (
                                        <TaskAltIcon
                                            color="success"
                                            fontSize="medium"
                                        />
                                    ) : (
                                        <HighlightOffIcon
                                            color="error"
                                            fontSize="medium"
                                        />
                                    )}
                                    <Typography
                                        className={classes.FunctionNameSmall}
                                    >
                                        Campus Tour
                                    </Typography>
                                </Grid>
                                {/* welcome board setup */}
                                <Grid item xs={3}>
                                    {visitObj.WelcomeBoard ? (
                                        <TaskAltIcon
                                            color="success"
                                            fontSize="medium"
                                        />
                                    ) : (
                                        <HighlightOffIcon
                                            color="error"
                                            fontSize="medium"
                                        />
                                    )}
                                    <Typography
                                        className={classes.FunctionNameSmall}
                                    >
                                        Welcome Board
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <br />
                                    <br />
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <Grid
                            container
                            align="center"
                            justifyContent="space-between"
                            className={classes.VisitDetailsEnd}
                        >
                            {/* setup (functions) required for this visit */}
                            <Grid item sm={3}>
                                <b>
                                    <u> SETUP REQUIRED</u>
                                </b>
                            </Grid>
                            {/* breakfast setup */}
                            <Grid item sm={2}>
                                {visitObj.Breakfast ? (
                                    <TaskAltIcon
                                        color="success"
                                        fontSize="medium"
                                    />
                                ) : (
                                    <HighlightOffIcon
                                        color="error"
                                        fontSize="medium"
                                    />
                                )}
                                <Typography> Breakfast</Typography>
                            </Grid>
                            {/* lunch setup */}
                            <Grid item sm={2}>
                                {visitObj.Lunch ? (
                                    <TaskAltIcon
                                        color="success"
                                        fontSize="medium"
                                    />
                                ) : (
                                    <HighlightOffIcon
                                        color="error"
                                        fontSize="medium"
                                    />
                                )}
                                <Typography> Lunch</Typography>
                            </Grid>
                            {/* factory tour setup */}
                            <Grid item sm={2}>
                                {visitObj.FactoryTour ? (
                                    <TaskAltIcon
                                        color="success"
                                        fontSize="medium"
                                    />
                                ) : (
                                    <HighlightOffIcon
                                        color="error"
                                        fontSize="medium"
                                    />
                                )}
                                <Typography> Campus Tour</Typography>
                            </Grid>
                            {/* welcome board setup */}
                            <Grid item sm={3}>
                                {visitObj.WelcomeBoard ? (
                                    <TaskAltIcon
                                        color="success"
                                        fontSize="medium"
                                    />
                                ) : (
                                    <HighlightOffIcon
                                        color="error"
                                        fontSize="medium"
                                    />
                                )}
                                <Typography> Welcome Board</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <br />
                                <br />
                            </Grid>
                        </Grid>
                    )}

                    <Grid container align="center" justifyContent="center">
                        {/* edit, set reminder, delete buttons render dynamically */}
                        {!past && permission.ManageVisitorAnnouncement ? (
                            isSmallScreen ? (
                                <>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="outlined"
                                            color="inherit"
                                            className={
                                                classes.DetailsSpacingButtonSmall
                                            }
                                            onClick={onClickEditButton}
                                        >
                                            EDIT
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="outlined"
                                            color="inherit"
                                            className={
                                                classes.DetailsSpacingButtonSmall
                                            }
                                            onClick={onClickReminderButton}
                                        >
                                            SET REMINDERS
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="outlined"
                                            color="inherit"
                                            className={
                                                classes.DetailsSpacingButtonSmall
                                            }
                                            onClick={handleClickOpen}
                                        >
                                            DELETE
                                        </Button>
                                    </Grid>
                                    <Grid
                                        container
                                        align="center"
                                        justifyContent="center"
                                    >
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            className={
                                                classes.DetailsRemindNowButton
                                            }
                                            onClick={onClickReminderNowButton}
                                        >
                                            SEND REMINDER NOW
                                        </Button>
                                        <Tooltip
                                            placement="right"
                                            title="Please be aware company-wide emails will be sent"
                                        >
                                            <InfoOutlinedIcon
                                                color="inherit"
                                                fontSize="small"
                                                className={
                                                    classes.ReminderInfoTip
                                                }
                                            />
                                        </Tooltip>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid item md={12}>
                                        <Button
                                            variant="outlined"
                                            color="inherit"
                                            className={
                                                classes.DetailsSpacingButton
                                            }
                                            onClick={onClickEditButton}
                                        >
                                            EDIT
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="inherit"
                                            className={
                                                classes.DetailsSpacingButton
                                            }
                                            onClick={onClickReminderButton}
                                        >
                                            SET REMINDERS
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="inherit"
                                            className={
                                                classes.DetailsSpacingButton
                                            }
                                            onClick={handleClickOpen}
                                        >
                                            DELETE
                                        </Button>
                                    </Grid>
                                    <Grid
                                        container
                                        align="center"
                                        justifyContent="center"
                                    >
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            className={
                                                classes.DetailsRemindNowButton
                                            }
                                            onClick={onClickReminderNowButton}
                                        >
                                            SEND REMINDER NOW
                                        </Button>
                                        <Tooltip
                                            placement="right"
                                            title="Please be aware company-wide emails will be sent"
                                        >
                                            <InfoOutlinedIcon
                                                color="inherit"
                                                fontSize="small"
                                                className={
                                                    classes.ReminderInfoTip
                                                }
                                            />
                                        </Tooltip>
                                    </Grid>
                                </>
                            )
                        ) : (
                            ""
                        )}
                    </Grid>
                </DialogContentText>
            </DialogContent>

            {/* reminder dialog popup window */}
            <Dialog
                open={openReminder}
                onClose={reminderOnClose}
                maxWidth="md"
                fullWidth={true}
            >
                <ReminderDialog
                    visitObj={visitObj}
                    visitType={visitType}
                    onCloseFxn={reminderOnClose}
                />
            </Dialog>

            {/* confirm delete dialog popup window */}
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth={true}
            >
                <ConfirmationDialog
                    messageConfirm={
                        "Are you sure you want to delete this visitor announcement?"
                    }
                    messageEmail={`Please be aware that all reminders set for this visit will also be deleted.`}
                    messageEmail3={`This action is irreversible, and will send a company-wide cancellation email.`}
                    messageEmail2={visitorNotificationsEmailList.join(", ")}
                    title={"DELETE ?"}
                    onConfirm={onClickDeleteButton}
                    onCancel={handleClose}
                    loading={loading}
                />
            </Dialog>

            {/* confirm send reminder now dialog popup window */}
            <Dialog
                open={openSendReminderNow}
                onClose={sendReminderNowOnClose}
                maxWidth="sm"
                fullWidth={true}
            >
                <ConfirmationDialog
                    messageConfirm={
                        "Are you sure you want to send a reminder for this visit, right now?"
                    }
                    messageEmail3={`This action will send a company-wide reminder email immediately, with full details regarding the visit.`}
                    messageEmail2={visitorNotificationsEmailList.join(", ")}
                    title={"SEND REMINDER NOW ?"}
                    onConfirm={onClickConfirmReminder}
                    onCancel={sendReminderNowOnClose}
                />
            </Dialog>

            {/* UX/UI indication for reminder email sent */}
            <Snackbar
                open={emailOpen}
                autoHideDuration={2000}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "center"
                }}
                onClose={handleEmailClose}
            >
                <SnackbarContent
                    className={classes.SuccessReminderEmail}
                    message="EMAIL SUCCESSFULLY SENT!"
                />
            </Snackbar>
        </div>
    )
}

export default VisitorListItemDialog
