import { React } from "react"
import { Grid, useTheme, useMediaQuery } from "@material-ui/core"
import { Switch } from "@mui/material"

import useStyles from "./styles/VisitorStyles"

/* Component that defines the form area to (input) toggle switches for the relevant
    setup requirements for the specified visit. */
function FunctionsBox({ visitInfoObj, setVisitInfoObj }) {
    const classes = useStyles()
    const theme = useTheme()

    // constants for conditional styling adjustments due to screen size (px)
    const isMediumScreen = useMediaQuery(theme.breakpoints.down("960"))
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("601"))

    /* Handler, to update state, for changes to the toggle on switches */
    const handleOnChange = (event, functionName) => {
        setVisitInfoObj({
            ...visitInfoObj,
            functionStatus: {
                ...visitInfoObj.functionStatus,
                [functionName]: event.target.checked
            }
        })
    }

    return (
        <>
            {/* Factory Tour function toggle switch component */}
            <Grid
                item
                align="flex-start"
                xs={5}
                sm={9}
                md={9}
                className={
                    isMediumScreen
                        ? isPhoneScreen
                            ? classes.FunctionBoxInputsPhone
                            : classes.FunctionBoxInputsMed
                        : classes.FunctionBoxInputs
                }
            >
                CAMPUS TOUR
            </Grid>
            <Grid item xs={2} sm={1} md={2}>
                <Switch
                    size="small"
                    color="warning"
                    checked={visitInfoObj.functionStatus.factory}
                    onChange={(event) => handleOnChange(event, "factory")}
                />
            </Grid>
            {/* Breakfast function toggle switch component */}
            <Grid
                item
                align="flex-start"
                xs={3}
                sm={9}
                md={9}
                className={
                    isMediumScreen
                        ? isPhoneScreen
                            ? classes.FunctionBoxInputsPhone
                            : classes.FunctionBoxInputsMed
                        : classes.FunctionBoxInputs
                }
            >
                BREAKFAST
            </Grid>
            <Grid item xs={2} sm={1} md={2}>
                <Switch
                    size="small"
                    color="warning"
                    checked={visitInfoObj.functionStatus.breakfast}
                    onChange={(event) => handleOnChange(event, "breakfast")}
                />
            </Grid>
            {/* Welcome Board function toggle switch component */}
            {isPhoneScreen && (
                <>
                    <Grid
                        item
                        align="flex-start"
                        xs={5}
                        sm={9}
                        md={9}
                        className={
                            isMediumScreen
                                ? isPhoneScreen
                                    ? classes.FunctionBoxInputsPhone
                                    : classes.FunctionBoxInputsMed
                                : classes.FunctionBoxInputs
                        }
                    >
                        WELCOME BOARD
                    </Grid>
                    <Grid item xs={2} sm={1} md={2}>
                        <Switch
                            size="small"
                            color="warning"
                            checked={visitInfoObj.functionStatus.welcome}
                            onChange={(event) =>
                                handleOnChange(event, "welcome")
                            }
                        />
                    </Grid>
                </>
            )}
            {/* Lunch function toggle switch component */}
            {!isPhoneScreen && (
                <>
                    <Grid
                        item
                        align="flex-start"
                        sm={9}
                        md={9}
                        className={
                            isMediumScreen
                                ? classes.FunctionBoxInputsMed
                                : classes.FunctionBoxInputs
                        }
                    >
                        LUNCH
                    </Grid>

                    <Grid item sm={1} md={2}>
                        <Switch
                            size="small"
                            color="warning"
                            checked={visitInfoObj.functionStatus.lunch}
                            onChange={(event) => handleOnChange(event, "lunch")}
                        />
                    </Grid>
                </>
            )}
            {/* Welcome Board function toggle switch component */}
            {!isPhoneScreen && (
                <>
                    <Grid
                        item
                        align="flex-start"
                        sm={9}
                        md={9}
                        className={
                            isMediumScreen
                                ? classes.FunctionBoxInputsMed
                                : classes.FunctionBoxInputs
                        }
                    >
                        WELCOME BOARD
                    </Grid>
                    <Grid item xs={1} sm={1} md={2}>
                        <Switch
                            size="small"
                            color="warning"
                            checked={visitInfoObj.functionStatus.welcome}
                            onChange={(event) =>
                                handleOnChange(event, "welcome")
                            }
                        />
                    </Grid>
                </>
            )}
            {/* Lunch function toggle switch component */}
            {isPhoneScreen && (
                <>
                    <Grid
                        item
                        align="flex-start"
                        xs={3}
                        sm={9}
                        md={9}
                        className={
                            isMediumScreen
                                ? isPhoneScreen
                                    ? classes.FunctionBoxInputsPhone
                                    : classes.FunctionBoxInputsMed
                                : classes.FunctionBoxInputs
                        }
                    >
                        LUNCH
                    </Grid>
                    <Grid item xs={2} sm={1} md={2}>
                        <Switch
                            size="small"
                            color="warning"
                            checked={visitInfoObj.functionStatus.lunch}
                            onChange={(event) => handleOnChange(event, "lunch")}
                            className={classes.FunctionLunch}
                        />
                    </Grid>
                </>
            )}
        </>
    )
}

export default FunctionsBox
