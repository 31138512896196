import { React, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import LoginPrompt from "../login/LoginPrompt"
import VisitorAdmin from "./VisitorAdmin"
import ISOAdmin from "./ISOAdmin"
import resetHeaders from "../../functions/resetHeaders"

/* Component that represents the page for Admin Settings */
function AdminSettings() {
    const dispatch = useDispatch()

    const loggedIn = useSelector(
        (state) => state.auth.user && state.auth.user.auth
    )
    const authenticated = useSelector((state) => state.auth.authenticated)

    const permission = useSelector((state) => state.auth.permissions)

    useEffect(() => {
        if (loggedIn) {
            resetHeaders()
        }
    }, [dispatch, loggedIn])

    return (
        <>
            {authenticated ? (
                <div>
                    {permission.AdminVisitorAnnouncement && <VisitorAdmin />}
                    {permission.AdminISODocumentUpdate && <ISOAdmin />}
                </div>
            ) : (
                <LoginPrompt />
            )}
        </>
    )
}

export default AdminSettings
