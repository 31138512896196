import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import axios from "../../auth/axiosAuth"
import { Container, Typography, Grid } from "@material-ui/core"

import useStyles from "./styles/FooterStyles"
import { APPLICATION_ID, appAdminBackendURL } from "../../config"

function Footer() {
    const classes = useStyles()
    const loggedIn = useSelector(
        (state) => state.auth.user && state.auth.user.auth
    )
    const [version, setVersion] = useState("")

    useEffect(() => {
        if (loggedIn) {
            async function runUseEffectFunctions() {
                await getVersion(APPLICATION_ID)
            }
            runUseEffectFunctions()
        }
        return () => {}
    }, [loggedIn])

    const getVersion = async (applicationID) => {
        const res = await axios.get(
            `${appAdminBackendURL}/application/${applicationID}`
        )
        res.data[0]
            ? setVersion(
                  `v${res.data[0].MajorVersion}.${res.data[0].MinorVersion}.${res.data[0].FixVersion}`
              )
            : setVersion("v0.0.0")
    }

    return (
        <div className={classes.Footer}>
            <Container>
                <Grid container className={classes.FooterItems}>
                    <Grid item>
                        <Typography variant="h6">
                            Precipart Global Digital Solutions
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6">{version}</Typography>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Footer
