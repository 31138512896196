import axios from "../auth/axiosAuth"
import { backendURL, appAdminBackendURL, unprotectedToken } from "../config"
import {
    UPDATE_VISITOR_LIST,
    UPDATE_HOST_LIST,
    UPDATE_CONF_ROOM_LIST,
    GET_ALL_EMPLOYEES,
    GET_ALL_ACTIVE_EMPLOYEES,
    GET_ALL_CONFERENCE_ROOMS,
    GET_ALL_FIRM_TYPES,
    ADD_VISIT_INFO,
    UPDATE_VISIT_INFO
} from "./types"

/* Action function to update visitor info. list in store, by replacing old list with
    new list. */
export const updateVisitorList = (newVisitorList) => (dispatch) => {
    dispatch({ type: UPDATE_VISITOR_LIST, payload: newVisitorList })
}

/* Action function to update host list in store, by replacing old list with new list. */
export const updateHostList = (newHostList) => (dispatch) => {
    dispatch({ type: UPDATE_HOST_LIST, payload: newHostList })
}

/* Action function to update conference room info. list in store, by replacing old 
    list with new list. */
export const updateConfRoomList = (newConfRoomList) => (dispatch) => {
    dispatch({ type: UPDATE_CONF_ROOM_LIST, payload: newConfRoomList })
}

/* Action function to query database for array of all employees. */
export const getAllEmployees = () => async (dispatch) => {
    const res = await axios.get(`${appAdminBackendURL}/employee`)
    dispatch({ type: GET_ALL_EMPLOYEES, payload: res.data })
}

/* Action function to query database for array of all active employees. */
export const getAllActiveEmployees = () => async (dispatch) => {
    const res = await axios.get(`${appAdminBackendURL}/employee/active`)
    dispatch({ type: GET_ALL_ACTIVE_EMPLOYEES, payload: res.data })
}

/* Action function to query database for array of all visiting firm types. */
export const getFirmTypes = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/firmtype`)
    dispatch({ type: GET_ALL_FIRM_TYPES, payload: res.data })
}

/* Action function to query database for array of all active conference rooms. */
export const getAllConferenceRooms = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/conferenceroom`)
    dispatch({ type: GET_ALL_CONFERENCE_ROOMS, payload: res.data })
}

/* Adds all information about visit to relevant tables, by creating each 
    respective object for each database table. */
export const addVisitInfo = (visitId, visitObj, mode) => async (dispatch) => {
    const visitorFormInfoObject = {}

    visitorFormInfoObject.visit = {
        active: true,
        startDateTime: visitObj.from,
        endDateTime: visitObj.to,
        firmName: visitObj.firm,
        firmTypeId: visitObj.type.id,
        visitId: visitId,
        factory: visitObj.functions.factory,
        breakfast: visitObj.functions.breakfast,
        lunch: visitObj.functions.lunch,
        welcome: visitObj.functions.welcome
    }
    visitorFormInfoObject.host = visitObj.hosts.map((elem) => {
        return { visitId: visitId, hostAssId: elem.id }
    })
    visitorFormInfoObject.visitor = visitObj.visitors.map((elem) => {
        return {
            visitorName: elem.firstName + " " + elem.lastName,
            visitorTitle: elem.title,
            visitId: visitId
        }
    })
    visitorFormInfoObject.conf = visitObj.confRooms.map((elem) => {
        return {
            visitId: visitId,
            conferenceRoomId: elem.id,
            startDateTime: elem.fromDateTime,
            endDateTime: elem.toDateTime
        }
    })
    visitorFormInfoObject.auditObj = visitObj.auditObj
    // create mode
    if (mode === "create") {
        await axios.post(`${backendURL}/visit`, visitorFormInfoObject)
        console.log("added to database")
        dispatch({ type: ADD_VISIT_INFO })
        return
        // edit mode
    } else if (mode === "edit") {
        visitorFormInfoObject.update = visitObj.update
        await axios.post(`${backendURL}/visit/edit`, visitorFormInfoObject)
        console.log("added to database")
        dispatch({ type: UPDATE_VISIT_INFO })
        return
    }
}

/* Action function that sends the request to backend application to send a function responsibility
    email to the designated list of recipients. */
export const sendFunctionEmail =
    (recipientList, visitInfoObj) => async (dispatch) => {
        const functionInfoObj = {}
        functionInfoObj.recipientList = recipientList
        functionInfoObj.visitInfoObj = visitInfoObj
        functionInfoObj.headers = { token: unprotectedToken }

        await axios.post(`${backendURL}/send-email/function`, functionInfoObj)
        return
    }

/* Action function that sends the request to backend application to send an email to the designated
    list of recipients. */
export const sendVisitorEmail =
    (recipientList, visitorInfo, mode) => async (dispatch) => {
        const emailVisitorInfo = {}
        emailVisitorInfo.mode = mode
        emailVisitorInfo.recipientList = recipientList
        emailVisitorInfo.visitorInfo = visitorInfo
        emailVisitorInfo.headers = { token: unprotectedToken }

        await axios.post(`${backendURL}/send-email/visit`, emailVisitorInfo)
        console.log("... company-wide emails sent")
    }
