import {
    GET_AUTHORIZATION_FOR_APPLICATION,
    GET_USER_DATA,
    TOGGLE_DARKMODE,
    CREATE_AUDIT_TRAIL,
    CREATE_PENDING_USER,
    LOGIN_USER,
    LOGOUT_USER
} from "./types"

import axios from "../auth/axiosAuth"

import { appAdminBackendURL, APPLICATION_ID } from "../config"

export const getAuthorizationForCurrentApplication =
    (associateOID) => async (dispatch) => {
        const res = await axios.get(
            `${appAdminBackendURL}/authorization/user/application/${associateOID}/${APPLICATION_ID}`
        )

        dispatch({
            type: GET_AUTHORIZATION_FOR_APPLICATION,
            payload: {
                permissions: res.data,
                authenticated: res.data?.length > 0 && true
            }
        })
    }

export const getUserData = (googleID) => async (dispatch) => {
    const res = await axios.get(`${appAdminBackendURL}/user/google/${googleID}`)
    dispatch({ type: GET_USER_DATA, payload: res.data[0] })
}

export const toggleDarkMode = (userData) => async (dispatch) => {
    const newUserData = {
        ...userData,
        DarkMode: userData.DarkMode === "light" ? "dark" : "light"
    }

    await axios.put(`${appAdminBackendURL}/user`, newUserData)
    dispatch({ type: TOGGLE_DARKMODE })
}

export const createAuditTrail = (newAuditTrail) => async (dispatch) => {
    await axios.post(`${appAdminBackendURL}/audit-trail`, newAuditTrail)
    dispatch({ type: CREATE_AUDIT_TRAIL })
}

export const createPendingUser = (newPendingUser) => async (dispatch) => {
    await axios.post(`${appAdminBackendURL}/pending-user`, newPendingUser)
    dispatch({ type: CREATE_PENDING_USER })
}

export const loginUser = (user) => (dispatch) => {
    dispatch({ type: LOGIN_USER, payload: user })
}

export const logoutUser = () => (dispatch) => {
    dispatch({ type: LOGOUT_USER })
}
