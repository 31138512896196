import { React, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
    Grid,
    Typography,
    Button,
    Paper,
    Divider,
    Snackbar,
    SnackbarContent,
    useTheme,
    useMediaQuery
} from "@material-ui/core"
import { Dialog, Tooltip, IconButton } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone"
import DeleteIcon from "@mui/icons-material/Delete"
import SendIcon from "@mui/icons-material/Send"

import useStyles from "./styles/VisitorStyles"
import CalendarIcon from "./CalendarIcon"
import {
    setSelectedVisitId,
    getVisitHosts,
    getVisitConfRooms,
    getVisitVisitors,
    updateVisitActive,
    getVisitById,
    sendCancelEmail,
    getVisitReminders,
    updateReminderList,
    deleteVisitRemindersByVisitId,
    setOldVisitStart,
    getAllStoredVisits,
    setRefreshKey,
    getRemindersDate,
    addImmReminderAudit
} from "../../actions/visitorFeedActions"
import { sendVisitorEmail } from "../../actions/visitorFormActions"
import VisitorListItemDialog from "./VisitorListItemDialog"
import ConfirmationDialog from "./ConfirmationDialog"
import ReminderDialog from "./ReminderDialog"

/* Component that defines each visit's rendering/data, in the
    main (visitor announcement list view) feed. */
function VisitorListItem({ visitObj, past }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const theme = useTheme()

    const permission = useSelector((state) => state.auth.permissions)
    const refreshKey = useSelector((state) => state.visitFeed.refreshKey)

    // constants for conditional styling adjustments due to screen size (px)
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("750"))
    const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("660"))

    // loading state for delete confirmation
    const [loading, setLoading] = useState(false)

    // state repr. for visit details dialog popup window
    const [open, setOpen] = useState(false)

    // get user name for audit log
    const userFullName = useSelector((state) => state.auth.user.displayName)

    /* Handler for closing details dialog; reset store variable of visitId */
    const handleClose = async () => {
        setOpen(false)
        await dispatch(setSelectedVisitId(""))
    }

    // state repr. for confirm delete dialog popup window
    const [openDelete, setOpenDelete] = useState(false)

    // state repr. for reminders dialog popup
    const [openReminder, setOpenReminder] = useState(false)
    const reminderOnClose = () => {
        setOpenReminder(false)
    }

    /* Handler for closing delete dialog */
    const handleCloseDelete = () => {
        setOpenDelete(false)
    }

    /* Handler for opening delete dialog for onClick on icon button */
    const onClickDeleteIcon = () => {
        setOpenDelete(true)
    }

    // state repr. for email sent depiction (send reminder now)
    const [emailOpen, setEmailOpen] = useState(false)

    /* Handler for opening popup alert : "email successfully sent" */
    const handleEmailOpen = () => {
        setEmailOpen(true)
    }

    /* Handler for closing popup alert : "email successfully sent" */
    const handleEmailClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setEmailOpen(false)
    }

    // state repr. for sending reminder now confirmation dialog popup
    const [openSendReminderNow, setOpenSendReminderNow] = useState(false)

    /* Handler for closing send reminder now confirmation dialog */
    const sendReminderNowOnClose = () => {
        setOpenSendReminderNow(false)
    }

    // get store data (visitors, hosts, conf rooms) to send reminder now
    const currentVisitorList = useSelector(
        (state) => state.visitFeed.currentVisitVisitors
    )
    const currentHostList = useSelector(
        (state) => state.visitFeed.currentVisitHosts
    )
    const currentConfRoomList = useSelector(
        (state) => state.visitFeed.currentVisitConfRooms
    )

    // get all email recipients from store (database)
    const allEmailRecipients = useSelector((state) => state.admin.allRecipients)

    // obtain only "Visitor Notifications" email recipients
    const visitorNotificationsEmailList = allEmailRecipients
        .filter(
            (elem) => elem.CommunicationFunctionName === "Visitor Notifications"
        )
        .map((elem) => {
            return elem.RecipientEmail
        })

    /* Handler function for when user clicks "CONFIRM" on deletion confirmation */
    const onClickConfirm = async () => {
        setLoading(true)

        const auditObject = {
            action: "Cancel Visitor Announcement",
            visitName: visitObj.FirmName,
            actionBy: userFullName
        }

        // turn active flag to false
        await dispatch(updateVisitActive(visitObj.VisitId, false, auditObject))

        // delete all reminders w visitId
        await dispatch(deleteVisitRemindersByVisitId(visitObj.VisitId))

        // reorganize data to only be relevant fields for email
        const visitInfo = {
            start: new Date(visitObj.StartDateTime),
            end: new Date(visitObj.EndDateTime),
            name: visitObj.FirmName,
            type: getFirmType(visitObj.FirmTypeId),
            visitId: visitObj.VisitId
        }
        // send company wide cancellation email
        await dispatch(
            sendCancelEmail(visitorNotificationsEmailList, visitInfo)
        )
        await dispatch(getAllStoredVisits())
        await dispatch(setRefreshKey(refreshKey))
        setOpenDelete(false)
        setLoading(false)
    }

    // Get array of Firmtype mapping (to ID) and modify type names
    const dbFirmTypes = useSelector((state) => state.visitor.firmTypes)
    const getFirmType = (id) => {
        const firmType = dbFirmTypes.filter((elem) => elem.FirmTypeId === id)[0]
        if (firmType) {
            if (firmType.FirmType === "Customer") {
                return firmType.FirmType + "/Prospect"
            } else if (firmType.FirmType === "Vendor") {
                return firmType.FirmType + "/Supplier"
            } else {
                return firmType.FirmType
            }
        } else {
            return
        }
    }

    // list of all active conference rooms loaded from database (store)
    const dbConfRooms = useSelector((state) => state.visitor.activeConfRooms)

    /* Handler for onClick "confirm" button on confirmation dialog for sending reminder now */
    const onClickConfirmReminder = async () => {
        // close confirmation dialog
        sendReminderNowOnClose()

        // set open email true
        handleEmailOpen()

        const auditObject = {
            action: "Send Visitor Announcement Reminder",
            visitName: visitObj.FirmName,
            actionBy: userFullName
        }

        // collect/format visit data for email
        const visitInfoFormatted = {
            type: {
                type: getFirmType(visitObj.FirmTypeId),
                id: visitObj.FirmTypeId
            },
            firm: visitObj.FirmName,
            from: new Date(visitObj.StartDateTime),
            to: new Date(visitObj.EndDateTime),
            functions: {
                factory: visitObj.FactoryTour,
                breakfast: visitObj.Breakfast,
                lunch: visitObj.Lunch,
                welcome: visitObj.WelcomeBoard
            },
            visitors: currentVisitorList?.length
                ? currentVisitorList.map((visitor) => {
                      const name = visitor.VisitorName
                      const first = name.split(" ")[0]
                      const last = name.split(" ")[1]
                      return {
                          firstName: first,
                          lastName: last,
                          title: visitor.VisitorTitle
                      }
                  })
                : [],
            hosts: currentHostList?.length ? currentHostList : [],
            confRooms: currentConfRoomList?.length
                ? currentConfRoomList.map((room) => {
                      const start = new Date(room.StartDateTime)
                      const end = new Date(room.EndDateTime)
                      const id = room.ConferenceRoomId
                      const roomObj = dbConfRooms.find(
                          (conf) => conf.ConferenceRoomId === id
                      )

                      return {
                          name: roomObj?.ConferenceRoomName,
                          fromDateTime: start,
                          toDateTime: end,
                          id: id
                      }
                  })
                : []
        }

        await dispatch(
            sendVisitorEmail(
                visitorNotificationsEmailList,
                visitInfoFormatted,
                "remind"
            )
        )

        await dispatch(addImmReminderAudit(auditObject))
        console.log("email sending")

        // set email open to false
        handleEmailClose()
    }

    /* Handler for onClick action in "See Details" button */
    const handleSeeDetails = async () => {
        await dispatch(setSelectedVisitId(visitObj.VisitId))
        await dispatch(getVisitHosts(visitObj.VisitId, allActiveEmployees))
        await dispatch(getVisitConfRooms(visitObj.VisitId))
        await dispatch(getVisitVisitors(visitObj.VisitId))
        await dispatch(getRemindersDate(visitObj.VisitId))
        setOpen(true)
    }

    // all active employees {id, first, last} loaded from database
    const allActiveEmployees = useSelector(
        (state) => state.visitor.activeEmployees
    )

    /* Handler for onClick action in edit icon button */
    const onClickEditIcon = async () => {
        await dispatch(setSelectedVisitId(visitObj.VisitId))
        await dispatch(setOldVisitStart(visitObj.StartDateTime))
        await dispatch(getVisitById(visitObj.VisitId))
        await dispatch(getVisitHosts(visitObj.VisitId, allActiveEmployees))
        await dispatch(getVisitVisitors(visitObj.VisitId))
        await dispatch(getVisitConfRooms(visitObj.VisitId))

        // navigate to edit mode of visitor form
        navigate("/edit-visitor-form")
    }

    /* Handler for onClick action in reminder icon button */
    const onClickReminderIcon = async () => {
        // get relevant reminder data for this visit
        await dispatch(updateReminderList([]))
        await dispatch(setSelectedVisitId(visitObj.VisitId))
        await dispatch(
            getVisitReminders(visitObj.VisitId, visitObj?.StartDateTime)
        )

        // open reminder dialog
        setOpenReminder(true)
    }

    /* Handler for onClick action in send reminder now icon button */
    const onClickReminderNowIcon = async () => {
        // get visit data
        await dispatch(getVisitById(visitObj.VisitId))
        await dispatch(getVisitHosts(visitObj.VisitId, allActiveEmployees))
        await dispatch(getVisitVisitors(visitObj.VisitId))
        await dispatch(getVisitConfRooms(visitObj.VisitId))

        // open confirmation dialog
        setOpenSendReminderNow(true)
    }

    return (
        <Grid
            item
            container
            justifyContent="space-evenly"
            className={classes.VAListItem}
        >
            <Grid item xs={1} className={classes.CalendarCell}>
                <CalendarIcon dateObj={new Date(visitObj.StartDateTime)} />
            </Grid>
            <Grid item xs={9} md={10}>
                <Paper className={classes.PaperItem} elevation={3}>
                    <Grid container alignContent="center">
                        <Grid item xs={8} md={9} className={classes.VABoxGrid}>
                            <Typography
                                align="center"
                                variant="h5"
                                className={classes.PaperFirmName}
                            >
                                {isExtraSmallScreen
                                    ? `${visitObj.FirmName?.slice(0, 10)}...`
                                    : visitObj.FirmName}
                            </Typography>
                            <Typography align="center" color="primary">
                                {isExtraSmallScreen
                                    ? `${getFirmType(
                                          visitObj.FirmTypeId
                                      )?.slice(0, 10)}...`
                                    : getFirmType(visitObj.FirmTypeId)}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} md={3}>
                            <Grid
                                container
                                alignItem="center"
                                className={classes.PaperDetailsButtons}
                            >
                                {/* Dynamically render edit, reminder, delete buttons */}
                                {past || isSmallScreen ? (
                                    <Grid item xs={12}>
                                        <br />
                                    </Grid>
                                ) : permission.ManageVisitorAnnouncement ? (
                                    <>
                                        <Grid item xs={3}>
                                            <Tooltip
                                                title="Edit"
                                                placement="bottom"
                                            >
                                                {/* Edit icon button */}
                                                <IconButton
                                                    color="inherit"
                                                    onClick={onClickEditIcon}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <Tooltip title="Set Reminders">
                                                {/* Set reminder icon button */}
                                                <IconButton
                                                    color="inherit"
                                                    onClick={
                                                        onClickReminderIcon
                                                    }
                                                >
                                                    <NotificationsNoneIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Send Reminder Now">
                                                {/* Send reminder now icon button */}
                                                <IconButton
                                                    color="inherit"
                                                    onClick={
                                                        onClickReminderNowIcon
                                                    }
                                                >
                                                    <SendIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Delete">
                                                {/* Delete icon button */}
                                                <IconButton
                                                    color="inherit"
                                                    onClick={onClickDeleteIcon}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </>
                                ) : (
                                    <Grid item xs={12}>
                                        <br />
                                    </Grid>
                                )}
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.FunctionsDetailBox}
                                >
                                    {/* See details button */}
                                    {isExtraSmallScreen ? (
                                        <Button
                                            size="small"
                                            variant="contained"
                                            className={
                                                classes.DetailButtonSmall
                                            }
                                            onClick={handleSeeDetails}
                                        >
                                            DETAILS
                                        </Button>
                                    ) : (
                                        <Button
                                            size="small"
                                            variant="contained"
                                            className={classes.DetailButton}
                                            onClick={handleSeeDetails}
                                        >
                                            SEE DETAILS
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Divider />

            {/* Visit information details dialog popup window */}
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                fullWidth={true}
            >
                <VisitorListItemDialog
                    visitObj={visitObj}
                    visitType={getFirmType(visitObj.FirmTypeId)}
                    past={past}
                />
            </Dialog>

            {/* Reminder dialog popup window */}
            <Dialog
                open={openReminder}
                onClose={reminderOnClose}
                maxWidth="md"
                fullWidth={true}
            >
                <ReminderDialog
                    visitObj={visitObj}
                    visitType={getFirmType(visitObj.FirmTypeId)}
                    onCloseFxn={reminderOnClose}
                />
            </Dialog>

            {/* Confirm delete dialog popup window */}
            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                maxWidth="sm"
                fullWidth={true}
            >
                <ConfirmationDialog
                    messageConfirm={
                        "Are you sure you want to delete this visitor announcement?"
                    }
                    messageEmail={`Please be aware that all reminders set for this visit will also be deleted.`}
                    messageEmail3={`This action is irreversible, and will send a company-wide cancellation email.`}
                    messageEmail2={visitorNotificationsEmailList.join(", ")}
                    title={"DELETE ?"}
                    onConfirm={onClickConfirm}
                    onCancel={handleCloseDelete}
                    loading={loading}
                />
            </Dialog>

            {/* Confirm send reminder now dialog popup window */}
            <Dialog
                open={openSendReminderNow}
                onClose={sendReminderNowOnClose}
                maxWidth="sm"
                fullWidth={true}
            >
                <ConfirmationDialog
                    messageConfirm={
                        "Are you sure you want to send a reminder for this visit, right now?"
                    }
                    messageEmail3={`This action will send a company-wide reminder email immediately, with full details regarding the visit.`}
                    messageEmail2={visitorNotificationsEmailList.join(", ")}
                    title={"SEND REMINDER NOW ?"}
                    onConfirm={onClickConfirmReminder}
                    onCancel={sendReminderNowOnClose}
                />
            </Dialog>

            {/* UX/UI indication for reminder email sent */}
            <Snackbar
                open={emailOpen}
                autoHideDuration={2000}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                onClose={handleEmailClose}
            >
                <SnackbarContent
                    className={classes.SuccessReminderEmailIcon}
                    message="EMAIL SUCCESSFULLY SENT"
                />
            </Snackbar>
        </Grid>
    )
}

export default VisitorListItem
