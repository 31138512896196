import axios from "../auth/axiosAuth"
import { backendURL, appAdminBackendURL, unprotectedToken } from "../config"
import {
    GET_ALL_EMPLOYEES,
    GET_ALL_ACTIVE_EMPLOYEES,
    ADD_ISO_UPDATE,
    GET_ALL_ISO_UPDATES,
    SET_ISO_REFRESH_KEY,
    SEND_ISO_EMAIL,
    SET_SHOW_CONFIRMATION
} from "./types"

/* Action function to query database for array of all employees. */
export const getAllEmployees = () => async (dispatch) => {
    const res = await axios.get(`${appAdminBackendURL}/employee`)
    dispatch({ type: GET_ALL_EMPLOYEES, payload: res.data })
}

/* Action function to query database for array of all active employees. */
export const getAllActiveEmployees = () => async (dispatch) => {
    const res = await axios.get(`${appAdminBackendURL}/employee/active`)
    dispatch({ type: GET_ALL_ACTIVE_EMPLOYEES, payload: res.data })
}

/* Action function to set the "refreshKey" field stored in the Redux store.
    (to force re-renders when data changes) */
export const setISORefreshKey = (oldRefreshKey) => (dispatch) => {
    dispatch({ type: SET_ISO_REFRESH_KEY, payload: oldRefreshKey + 1 })
}

/* Action function to add the information about the document update to the database. */
export const addISOUpdate = (documentInfoObj) => async (dispatch) => {
    await axios.post(`${backendURL}/iso`, documentInfoObj)
    dispatch({ type: ADD_ISO_UPDATE })
}

/* Action function to obtain the total list of ISO Document Updates. */
export const getAllISOUpdates = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/iso`)
    dispatch({ type: GET_ALL_ISO_UPDATES, payload: res.data })
}

/* Action function to backend to send ISO update email. */
export const sendISOUpdateEmail = (documentInfo) => async (dispatch) => {
    const documentInfoObj = {}
    documentInfoObj.documentInfo = documentInfo.documentInfo
    documentInfoObj.recipientList = documentInfo.recipientList
    documentInfoObj.headers = { token: unprotectedToken }

    await axios.post(`${backendURL}/send-email/iso-update`, documentInfoObj)
    //dispatch({ type: SEND_ISO_EMAIL })
    console.log("... company-wide emails sent")
}
