import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    CreateNewButton: {
        marginTop: "15px"
    },
    ButtonGridWidth: {
        width: "90%"
    },
    PageTitle: {
        fontWeight: "800",
        marginBottom: "5px"
    },
    CreateVAPageTitle: {
        position: "absolute",
        fontSize: "0.875rem",
        fontWeight: "800",
        marginTop: "10px"
    },
    ReminderEntry: {
        fontSize: "0.875rem",
        color: "black !important",
        marginTop: "5px"
    },
    ReminderTitle: {
        position: "absolute",
        fontSize: "0.875rem",
        fontWeight: "800"
    },
    ReminderDivider: {
        position: "relative",
        paddingTop: "23px"
    },
    ReminderDateInput: {
        width: "80%",
        marginLeft: "30px",
        color: "black !important"
    },
    ReminderAddButton: {
        marginTop: "auto",
        marginBottom: "6px"
    },
    VisitorForm: {
        marginTop: "30px",
        maxWidth: "1275px"
    },
    VisitorFormB: {
        marginTop: "30px",
        maxWidth: "1275px"
    },
    VisitorFormBExtraSmall: {
        maxWidth: "1275px"
    },
    ReminderVisitDetails: {
        maxWidth: "940px"
    },
    FunctionDetailsSmall: {
        marginTop: "10px"
    },
    FunctionNameSmall: {
        fontSize: "15px"
    },
    OptionLabel: {
        fontSize: "12px"
    },
    Dates: {
        paddingLeft: "90px"
    },
    DatesSmall: {
        paddingLeft: "45px"
    },
    DatesExtraSmall: {
        marginTop: "10px"
    },
    DatesPhone: {
        marginTop: "30px",
        marginLeft: "15vw"
    },
    DatePhoneBetween: {
        marginTop: "5px"
    },
    VisitorBox: {
        marginTop: "20px",
        border: "solid 1px",
        borderRadius: "6px",
        minHeight: "180px"
    },
    VisitorBoxPhone: {
        marginTop: "20px",
        border: "solid 1px",
        borderRadius: "6px",
        minHeight: "180px"
    },
    VisitorBoxMed: {
        marginTop: "20px",
        marginLeft: "20px",
        border: "solid 1px",
        borderRadius: "6px",
        minHeight: "180px"
    },
    VisitorBoxSmaller: {
        marginTop: "20px",
        marginLeft: "15px",
        border: "solid 1px",
        borderRadius: "6px",
        minHeight: "180px"
    },
    VisitorBoxExtraSmall: {
        marginTop: "20px",
        border: "solid 1px",
        borderRadius: "6px",
        minHeight: "180px"
    },
    VisitorFormTopLandscape: {
        marginTop: "35px"
    },
    ConfirmationDialogLandscape: {
        marginTop: "10px"
    },
    VisitDetailsLandscape: {
        marginTop: "25px"
    },
    ReminderDialogLandscape: {
        marginTop: "30px"
    },
    ReminderBox: {
        marginTop: "20px",
        border: "solid 1px",
        borderRadius: "6px",
        minHeight: "240px",
        marginRight: "5px"
    },
    ReminderToggleBox: {
        marginTop: "20px",
        border: "solid 1px",
        borderRadius: "6px",
        minHeight: "120px",
        marginLeft: "5px",
        paddingBottom: "10px"
    },
    ReminderToggleBox600Small: {
        marginTop: "20px",
        border: "solid 1px",
        borderRadius: "6px",
        minHeight: "120px",
        marginLeft: "13vw",
        paddingBottom: "10px"
    },
    ReminderDatePicker: {
        marginTop: "auto",
        marginBottom: "5px",
        marginLeft: "7px",
        color: "black !important"
    },
    HostBox: {
        marginTop: "20px",
        border: "solid 1px",
        borderRadius: "6px",
        minHeight: "170px"
    },
    VisitorBoxInputs: {
        marginTop: "auto",
        marginBottom: "5px",
        marginLeft: "7px"
    },
    AddVisitorButton: {
        marginTop: "auto",
        marginBottom: "7px",
        marginLeft: "7px"
    },
    AddVisitorButtonMed: {
        marginTop: "auto",
        marginBottom: "7px",
        marginLeft: "10px"
    },
    AddVisitorButtonSmall: {
        marginTop: "auto",
        marginBottom: "7px",
        marginLeft: "5px"
    },
    FunctionBoxInputs: {
        marginTop: "auto",
        marginBottom: "5px",
        marginLeft: "18px"
    },
    FunctionBoxInputsMed: {
        marginTop: "auto",
        marginBottom: "5px",
        marginLeft: "10px"
    },
    FunctionBoxInputsPhone: {
        marginTop: "auto",
        marginBottom: "5px",
        marginLeft: "0px"
    },
    ReminderSwitchLabel: {
        fontSize: "0.875rem",
        fontFamily: "Century !important",
        marginTop: "7px",
        marginLeft: "20px",
        width: "80%",
        color: "black !important"
    },
    ReminderSwitchLabelMed: {
        fontSize: "0.875rem",
        fontFamily: "Century !important",
        marginTop: "7px",
        marginLeft: "15px",
        width: "80%",
        color: "black !important"
    },
    ReminderSwitchLabelSmall: {
        fontSize: "0.875rem",
        fontFamily: "Century !important",
        marginTop: "7px",
        marginLeft: "7px",
        width: "80%",
        color: "black !important"
    },
    ReminderSwitch: {
        paddingRight: "20px"
    },
    HostInner: {
        minHeight: "130px"
    },
    VisitorInner: {
        minHeight: "170px"
    },
    FunctionBox: {
        border: "solid 5px",
        borderRadius: "16px",
        maxWidth: "300px",
        maxHeight: "150px",
        marginLeft: "90px",
        marginRight: "20px",
        paddingTop: "9px",
        paddingBottom: "9px",
        marginBottom: "auto",
        marginTop: "55px"
    },
    FunctionBoxMed: {
        border: "solid 5px",
        borderRadius: "16px",
        maxWidth: "300px",
        maxHeight: "150px",
        marginLeft: "20px",
        marginRight: "20px",
        paddingTop: "9px",
        paddingBottom: "9px",
        marginBottom: "auto",
        marginTop: "55px"
    },
    FunctionBoxExtraSmall: {
        border: "solid 5px",
        borderRadius: "16px",
        maxWidth: "300px",
        maxHeight: "150px",
        marginLeft: "20px",
        paddingTop: "9px",
        paddingBottom: "9px",
        marginBottom: "auto",
        marginTop: "35px"
    },
    FunctionBoxPhone: {
        border: "solid 5px",
        borderRadius: "16px",
        maxWidth: "100%",
        maxHeight: "150px",
        marginLeft: "5px",
        paddingLeft: "15px",
        paddingTop: "9px",
        paddingBottom: "9px",
        marginBottom: "auto",
        marginTop: "10px"
    },
    NoVisitors: {
        paddingTop: "20px",
        fontSize: "15px",
        fontStyle: "italic"
    },
    NoReminders: {
        fontSize: "15px",
        fontStyle: "italic"
    },
    ConfRoomBox: {
        marginTop: "20px",
        marginLeft: "20px",
        border: "solid 1px",
        borderRadius: "6px",
        minHeight: "170px"
    },
    ConfRoomBoxSmall: {
        marginTop: "20px",
        border: "solid 1px",
        borderRadius: "6px",
        minHeight: "170px"
    },
    ConfRoomBoxExtraSmall: {
        marginTop: "20px",
        marginLeft: "20px",
        border: "solid 1px",
        borderRadius: "6px",
        minHeight: "170px"
    },
    SaveButton: {
        border: "3px solid",
        marginTop: "50px",
        marginRight: "5px",
        fontWeight: "900",
        fontSize: "16px",
        borderRadius: "12px"
    },
    SaveReminderButton: {
        border: "2px solid",
        marginRight: "10px",
        fontWeight: "900",
        fontSize: "16px",
        borderRadius: "12px"
    },
    ReminderInfo: {
        marginLeft: "10px",
        marginTop: "10px"
    },
    ConferenceRoomInfo: {
        marginLeft: "3px",
        paddingTop: "3px"
    },
    ReminderInfoSugg: {
        marginLeft: "3px",
        marginTop: "1px",
        paddingTop: "3px"
    },
    ReminderInfoCustom: {
        paddingTop: "3px",
        marginTop: "1px"
    },
    BackButton: {
        border: "1px solid",
        borderRadius: "6px",
        marginBottom: "7px",
        marginRight: "auto",
        marginLeft: "10vw"
    },
    BackButtonMain: {
        marginBottom: "7px",
        marginRight: "auto",
        marginLeft: "7vw"
    },
    VisitorFormTopPhone: {
        marginLeft: "10vw"
    },
    BackButtonSmaller: {
        border: "1px solid",
        borderRadius: "6px",
        marginBottom: "7px",
        marginRight: "auto",
        marginLeft: "5vw"
    },
    BackButtonPhone: {
        border: "1px solid",
        borderRadius: "6px",
        marginBottom: "7px",
        marginRight: "auto",
        marginLeft: "7px"
    },
    SaveInfoTip: {
        marginTop: "45px",
        marginLeft: "5px"
    },
    ReminderInfoTip: {
        marginTop: "38px",
        marginLeft: "5px"
    },
    DetailsRemindNowButton: {
        marginTop: "30px",
        marginLeft: "15px",
        marginRight: "5px",
        border: "2px solid",
        fontWeight: "900",
        borderRadius: "10px"
    },
    BackToVAFeed: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        marginTop: "30px"
    },
    VisitorEntry: {
        marginTop: "5px"
    },
    VisitorLabel: {
        marginTop: "3px"
    },
    ConfDateInputs: {
        marginBottom: "14px"
    },
    BoxLabel: {
        position: "relative",
        paddingLeft: "5px",
        fontWeight: "800",
        fontSize: "0.875rem",
        fontFamily: ["Century !important"].join(",")
    },
    ReminderLabel: {
        paddingLeft: "5px",
        fontWeight: "800",
        fontSize: "0.875rem",
        fontFamily: ["Century !important"].join(",")
    },
    AddHostButton: {
        marginTop: "auto",
        marginBottom: "6px",
        marginLeft: "13px"
    },
    AddHostButtonSmall: {
        marginTop: "auto",
        marginBottom: "6px",
        marginLeft: "2px"
    },
    AddHostButtonPhone: {
        marginTop: "auto",
        marginBottom: "6px",
        marginLeft: "10px"
    },
    AddHostButtonSmallPhone: {
        marginTop: "auto",
        marginBottom: "6px",
        marginLeft: "20px"
    },
    AddConfButtonSmall: {
        marginTop: "auto",
        marginBottom: "6px",
        marginLeft: "7px"
    },
    ConfRoomEntry: {
        marginTop: "8px"
    },
    ThankYou: {
        fontSize: "22px",
        fontWeight: "bold"
    },
    Success: {
        paddingTop: "10px",
        fontSize: "18px"
    },
    StartChanged: {
        fontSize: "15px"
    },
    SubmitPageContainer: {
        paddingTop: "10%"
    },
    notchedOutline: {
        borderWidth: "1px",
        borderColor: "grey !important"
    },
    floatingLabelFocusStyle: {
        color: "grey !important"
    },
    TextColor: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important"
    },
    CalendarIconColor: {
        color:
            theme.palette.type === "dark"
                ? "#717073 !important"
                : "black !important"
    },
    RemindTextColor: {
        color: "black !important"
    },
    CalendarBox: {
        display: "inline-block",
        width: "60px",
        height: "75px",
        border: "solid 3px",
        borderRadius: "16px",
        textAlign: "center",
        padding: "5px"
    },
    CalendarDate: {
        fontSize: "17px",
        fontWeight: "bold",
        color: "darkblack"
    },
    CalendarMonth: {
        fontSize: "15px",
        fontWeight: "bold",
        color: "darkblack"
    },
    CalendarYear: {
        fontSize: "12px",
        fontWeight: "bold",
        color: "darkblack"
    },
    CalendarTimeBox: {
        display: "inline-block",
        width: "70px",
        height: "60px",
        border: "solid 2px",
        borderRadius: "16px",
        textAlign: "center",
        paddingTop: "5px"
    },
    CalendarTimeDate: {
        fontSize: "18px",
        fontWeight: "bold",
        color: "darkblack"
    },
    CalendarTimeMonth: {
        fontSize: "15px",
        fontWeight: "bold",
        color: "darkblack"
    },
    CalendarTime: {
        fontSize: "12px",
        fontWeight: "bold",
        color: "darkblack",
        paddingTop: "3px"
    },
    CalendarCell: {
        marginRight: "30px",
        width: "80%"
    },
    ReminderTitleCenter: {
        marginTop: "15px"
    },
    ReminderCards: {
        marginRight: "5px",
        marginLeft: "5px",
        marginBottom: "5px",
        [theme.breakpoints.down("400")]: { marginTop: "5px" }
    },
    ReminderScroll: {
        display: "flex",
        flexWrap: "nowrap",
        overflowX: "auto",
        maxWidth: "400px",
        "&::-webkit-scrollbar": {
            height: "5px"
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 2px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 10px rgba(0,0,0,0.00)"
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#959399",
            height: "10px",
            borderRadius: "5px"
        }
    },
    VisitorAnnouncementList: {
        marginTop: "50px",
        maxWidth: "740px"
    },
    VAListItem: {
        marginBottom: "20px",
        height: "75px"
    },
    PaperItem: {
        height: "75px"
    },
    PaperFirmName: {
        fontWeight: "bold"
    },
    PaperDetailsButtons: {
        height: "100%"
    },
    DetailButton: {
        marginBottom: "auto",
        width: "90%"
    },
    DetailButtonSmall: {
        marginBottom: "auto",
        marginRight: "10px",
        width: "75%"
    },
    VisitDetailsTitleSmall: {
        paddingTop: "15px"
    },
    VisitDetailsTitle: {
        paddingTop: "0px"
    },
    VABoxGrid: {
        marginTop: "7px"
    },
    SearchBar: {
        marginTop: "15px"
    },
    VisitDetailsTopSmall: {
        width: "90%"
    },
    VisitDetailsTop: {
        marginLeft: "85px",
        marginRight: "85px",
        width: "90%"
    },
    VisitDetailsEnd: {
        width: "93%"
    },
    FunctionIconYesNo: {
        marginTop: "5px"
    },
    DetailsSpacingButton: {
        marginLeft: "10px",
        marginRight: "10px",
        borderRadius: "10px"
    },
    DetailsSpacingButtonSmall: {
        marginTop: "5px",
        marginBottom: "5px",
        borderRadius: "10px"
    },
    SuccessReminderEmail: {
        backgroundColor: "#717073",
        color: "#ffffff",
        fontSize: "18px",
        fontWeight: "600",
        textAlign: "center"
    },
    SuccessReminderEmailIcon: {
        fontSize: "18px",
        fontWeight: "600",
        textAlign: "center"
    },
    DeleteConfirmMsg: {
        fontSize: "16px"
    },
    DeleteEmailMsg: {
        fontSize: "15px"
    },
    DeleteEmailDisplay: {
        fontSize: "14px",
        color: "#717073"
    },
    DisabledDeleteConfirmButton: {
        backgroundColor: "#bfbec2"
    }
}))

export default useStyles
