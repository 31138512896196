import { React, useState, useEffect } from "react"
import {
    Grid,
    TextField,
    Button,
    Typography,
    useTheme,
    useMediaQuery
} from "@material-ui/core"
import { useSelector, useDispatch } from "react-redux"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import { IconButton } from "@mui/material"
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined"

import useStyles from "./styles/VisitorStyles"
import { updateVisitorList } from "../../actions/visitorFormActions"
import Alert from "../main/Alert"

/* Component that defines the form area to input visitor information, relating to the
    specific visit. */
function VisitorBox({ mode }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const theme = useTheme()

    // constants for conditional styling adjustments due to screen size (px)
    const isMediumScreen = useMediaQuery(theme.breakpoints.down("960"))
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"))
    const isSmallPhone = useMediaQuery(theme.breakpoints.down("400"))

    // state representation of visitor information (input boxes)
    const [visitorInputs, setVisitorInputs] = useState({
        firstName: "",
        lastName: "",
        title: ""
    })

    // EDIT mode: get original visitor list
    const currentVisitorList = useSelector(
        (state) => state.visitFeed.currentVisitVisitors
    )
    let formatVisitorList
    if (mode === "edit" && currentVisitorList?.length > 0) {
        formatVisitorList = currentVisitorList.map((visitor) => {
            const name = visitor.VisitorName
            const first = name.split(" ")[0]
            const last = name.split(" ")[1]
            return {
                firstName: first,
                lastName: last,
                title: visitor.VisitorTitle
            }
        })
    }

    // state representation of (dynamic) list of visitors on form
    const [visitorList, setVisitorList] = useState(
        mode === "create" ? [] : formatVisitorList
    )

    // list of all visitors from store
    const allVisitors = useSelector((state) => state.visitor.allVisitors)

    // error alert message
    const [alertFlag, setAlertFlag] = useState(false)
    const [alertText, setAlertText] = useState("")

    /* Function to return an array of visitor info entries, excluding the entry
        represented by the parameter object */
    const filteredVisitorList = (visitorObject) => {
        const filtered = visitorList.filter(
            (elem) =>
                visitorObject.firstName.toLowerCase() !==
                    elem.firstName.toLowerCase() ||
                visitorObject.lastName.toLowerCase() !==
                    elem.lastName.toLowerCase() ||
                visitorObject.title.toLowerCase() !== elem.title.toLowerCase()
        )
        return filtered
    }

    /* Function to check input fields are only alphabetical */
    const isAlphabetFields = (visitorObject) => {
        const regexCheck = /[a-zA-Z]/g
        return (
            regexCheck.test(visitorObject.firstName) &&
            regexCheck.test(visitorObject.lastName) &&
            regexCheck.test(visitorObject.title)
        )
    }

    /* Handler to clear state representation of visitor (input boxes) */
    const clearVisitorInput = () => {
        setVisitorInputs({ firstName: "", lastName: "", title: "" })
    }

    /* Handler (with error checking) for the "ADD" button to add visitor information 
        entry to state representation of list, then updates visitor list in store */
    const handleClick = async () => {
        // check for nonalphabetical inputs
        if (!isAlphabetFields(visitorInputs)) {
            setAlertFlag(true)
            setAlertText("Visitor names and titles should be alphabetical.")
            return
        } // check for duplicates
        else if (
            filteredVisitorList(visitorInputs).length !== visitorList.length
        ) {
            setAlertFlag(true)
            setAlertText("It looks like this visitor already exists.")
            return
        } // check for blank inputs
        else if (visitorInputs.firstName.length > 0) {
            trimEndWhitespace() // trim lingering whitespaces in saved fields
            visitorList.push(visitorInputs)
            setVisitorList(visitorList)
            await dispatch(updateVisitorList(visitorList))
            setAlertFlag(false)
            clearVisitorInput()
        }
    }

    /* Function to trim lingering whitespaces in saved states */
    const trimEndWhitespace = () => {
        setVisitorInputs({
            ...visitorInputs,
            firstName: visitorInputs.firstName?.trim()
        })
        setVisitorInputs({
            ...visitorInputs,
            lastName: visitorInputs.lastName?.trim()
        })
        setVisitorInputs({
            ...visitorInputs,
            title: visitorInputs.title?.trim()
        })
    }

    /* Handler for the "x" delete visitor entry button */
    const handleDeleteVisitor = async (visitor) => {
        //console.log(visitor)
        let removedVisitorList = filteredVisitorList(visitor)
        setVisitorList(removedVisitorList)
        await dispatch(updateVisitorList(removedVisitorList))
    }

    /* Handler for alert message */
    const handleAlertDialog = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setAlertFlag(false)
        clearVisitorInput()
    }

    /* Handler for all changes in input boxes */
    const handleOnChangeInputs = (event, name) => {
        setVisitorInputs({ ...visitorInputs, [name]: event.target.value })
    }

    /* React hook; updates state variables to load previously saved data (EDIT mode) */
    useEffect(() => {
        async function runUseEffectFunctions() {
            // on component render, set state variables to saved data
            if (mode === "edit" && formatVisitorList) {
                await dispatch(updateVisitorList(formatVisitorList))
            }
        }
        runUseEffectFunctions()
    }, [dispatch])

    return (
        <>
            <Grid item container className={classes.VisitorInner}>
                {/* Case on number of Visitor Information entries, to display */}
                {allVisitors.length !== 0 ? (
                    allVisitors?.map((visitor, index) => {
                        return (
                            <Grid item container key={index}>
                                <Grid
                                    item
                                    align="center"
                                    xs={3}
                                    sm={3}
                                    md={3}
                                    className={classes.VisitorEntry}
                                >
                                    {visitor.firstName}
                                </Grid>
                                <Grid
                                    item
                                    align="center"
                                    xs={3}
                                    sm={3}
                                    md={3}
                                    className={classes.VisitorEntry}
                                >
                                    {visitor.lastName}
                                </Grid>
                                <Grid
                                    item
                                    align="center"
                                    xs={3}
                                    sm={3}
                                    md={3}
                                    className={classes.VisitorEntry}
                                >
                                    {visitor.title}
                                </Grid>
                                <Grid item xs={3} sm={3} md={3} align="center">
                                    <IconButton
                                        color="inherit"
                                        onClick={(e) =>
                                            handleDeleteVisitor(visitor)
                                        }
                                    >
                                        <HighlightOffIcon fontSize="small" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        )
                    })
                ) : (
                    <Grid item xs={12}>
                        <Typography
                            align="center"
                            className={classes.NoVisitors}
                        >
                            No visitors added.
                        </Typography>
                    </Grid>
                )}

                {/* Alerts pop-up area */}
                <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    justify="flex-end"
                >
                    <Grid item>
                        {alertFlag && (
                            <Alert onClose={handleAlertDialog} severity="error">
                                {alertText}
                            </Alert>
                        )}
                    </Grid>
                </Grid>

                {/* Visitor First Name input text field */}
                <Grid
                    item
                    xs={3}
                    sm={3}
                    md={3}
                    className={classes.VisitorBoxInputs}
                >
                    <TextField
                        label={isSmallScreen ? "First.." : "First Name"}
                        value={visitorInputs.firstName}
                        variant="outlined"
                        size="small"
                        onChange={(e) => handleOnChangeInputs(e, "firstName")}
                    />
                </Grid>

                {/* Visitor Last Name input text field */}
                <Grid
                    item
                    xs={3}
                    sm={3}
                    md={3}
                    className={classes.VisitorBoxInputs}
                >
                    <TextField
                        label={isSmallScreen ? "Last.." : "Last Name"}
                        value={visitorInputs.lastName}
                        variant="outlined"
                        size="small"
                        onChange={(e) => handleOnChangeInputs(e, "lastName")}
                    />
                </Grid>

                {/* Visitor Title input text field */}
                <Grid
                    item
                    xs={3}
                    sm={3}
                    md={4}
                    className={classes.VisitorBoxInputs}
                >
                    <TextField
                        label="Title"
                        value={visitorInputs.title}
                        variant="outlined"
                        size="small"
                        onChange={(e) => handleOnChangeInputs(e, "title")}
                    />
                </Grid>

                {/* "ADD" button to add entered Visitor information to list in area */}
                <Grid
                    item
                    xs={2}
                    sm={2}
                    md={1}
                    align="center"
                    className={
                        isMediumScreen
                            ? isSmallScreen
                                ? classes.AddVisitorButtonSmall
                                : classes.AddVisitorButtonMed
                            : classes.AddVisitorButton
                    }
                >
                    {isSmallPhone ? (
                        <IconButton color="warning" onClick={handleClick}>
                            <AddBoxOutlinedIcon />
                        </IconButton>
                    ) : (
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleClick}
                        >
                            ADD
                        </Button>
                    )}
                </Grid>
            </Grid>
        </>
    )
}

export default VisitorBox
