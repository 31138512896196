import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    PageTitle: {
        fontWeight: "800",
        marginBottom: "5px"
    },
    CreateNewButton: {
        marginTop: "15px"
    },
    SearchBar: {
        marginTop: "15px"
    },
    FormTitle: {
        fontWeight: "800",
        fontSize: "15px"
    },
    FormFields: {
        padding: "30px"
    },
    SaveButton: {
        border: "3px solid",
        marginTop: "50px",
        marginRight: "5px",
        fontWeight: "900",
        fontSize: "16px",
        borderRadius: "12px"
    },
    DocumentListItem: {
        marginBottom: "20px",
        height: "75px"
    },
    DocumentUpdateList: {
        marginTop: "50px",
        maxWidth: "740px"
    },
    NoDocument: {
        fontWeight: "bold"
    },
    PaperItem: {
        height: "75px"
    },
    CalendarCell: {
        marginRight: "30px",
        width: "80%"
    },
    PaperFirmName: {
        fontWeight: "bold"
    },
    DocumentTextGrid: {
        marginTop: "7px"
    },
    DetailButton: {
        //marginBottom: "auto",
        width: "90%"
    },
    DetailButtonGrid: {
        marginTop: "20px"
    },
    DocumentItem: {
        marginBottom: "20px",
        height: "75px"
    },
    DocumentDetailsTextfield: {
        marginLeft: "10px",
        marginTop: "20px"
        //width: "260px"
    },
    DetailsTitle: {
        fontWeight: "bold"
    },
    DocumentDetailsTextfieldOwner: {
        marginLeft: "30px",
        marginTop: "40px"
    },
    ConfirmationGrid: {
        marginTop: "50px"
    },
    SwapSortIcon: {
        marginLeft: "640px"
    }
}))

export default useStyles
