import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Grid, Typography } from "@material-ui/core"
import { IconButton } from "@mui/material"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"

import useStyles from "./styles/AdminSettingStyles"
import { deleteRecipient, getAllRecipients } from "../../actions/adminActions"

/* Component that represents each row of the current recipients (email + delete button) */
function RecipientRow({ rec }) {
    const classes = useStyles()
    const dispatch = useDispatch()

    // currently selected function
    const selectedFunction = useSelector(
        (state) => state.admin.selectedFunction
    )

    // current user's full name
    const userFullName = useSelector((state) => state.auth.user.displayName)

    /* Handles the action when the "X" delete button is clicked. */
    const handleDeleteClick = async () => {
        const auditObject = {
            action: "Delete Email",
            visitName: selectedFunction,
            actionBy: userFullName,
            newValue: rec.RecipientEmail
        }

        await dispatch(
            deleteRecipient(
                rec.CommunicationFunctionId,
                rec.RecipientEmail,
                auditObject
            )
        )

        await dispatch(getAllRecipients())
    }

    return (
        <div>
            <Grid container direction="row" item>
                <Grid item zeroMinWidth xs={7}>
                    {/* email recipient to display */}
                    <Typography align="center" className={classes.EmailVal}>
                        {rec.RecipientEmail}
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    {/* "X" delete button */}
                    <IconButton onClick={handleDeleteClick}>
                        <HighlightOffIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </div>
    )
}

export default RecipientRow
